import { makeStyles } from 'tss-react/mui';

import { colors } from '../../../static';

const isLandscapeQuery = '@media (orientation: landscape)';

export default makeStyles()((theme, { isWidget, isSmallWidget }: any) => ({
  root: {
    '& .MuiDialog-paper': {
      paddingTop: 64,
      paddingBottom: isWidget ? 64 : 78,
      maxWidth: isWidget ? 'auto' : 554,
      maxHeight: isWidget ? 'auto' : 554,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'auto',
      },
    },
  },
  container: {
    margin: '0 auto',
    textAlign: 'center',
    display: isWidget ? 'flex' : 'block',
    flexDirection: 'column',
    height: '100%',
    justifyContent: isWidget && 'space-between',

    [isWidget && isLandscapeQuery]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  avatarWrapper: {
    position: 'relative',
    width: 96,
    height: 96,
    borderRadius: 1000,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#fff',
    backgroundColor: '#D8B4FF',
    display: 'grid',
    placeItems: 'center',
    margin: '0 auto 16px',
  },
  shopperNameWrapper: {
    fontSize: 20,
    width: 362,
    marginBottom: isSmallWidget ? 56 : 99,
    [theme.breakpoints.down('sm')]: {
      width: 264,
    },
    [isWidget && isLandscapeQuery]: {
      marginBottom: 0,
    },
  },
  shopperName: {
    fontSize: isWidget ? 18 : 20,
    lineHeight: isWidget ? 1.333 : 1.4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: isWidget ? 400 : 500,
    color: 'white',
  },
  shopperNameClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: 'white',
    fontWeight: 500,
    fontSize: isWidget ? 18 : 20,
    lineHeight: isWidget ? 1.333 : 1.4,
    overflow: 'hidden',
  },
  expertHandle: {
    color: colors.grey50,
    lineHeight: 1.375,
    fontSize: 16,
  },
  actionButton: {
    width: 264,
    height: 72,
    borderRadius: 125,
    backgroundColor: '#56C087',
    marginBottom: 38,
    padding: '0 24px',
    '&:hover': {
      backgroundColor: '#56C087',
      filter: 'brightness(0.8)',
    },
  },
  actionButtonText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    color: 'white',
    fontSize: 20,
  },
  headerWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 362,
    marginBottom: isSmallWidget ? 56 : 72,
    [theme.breakpoints.down('sm')]: {
      width: 264,
    },
    [isWidget && isLandscapeQuery]: {
      marginBottom: 16,
    },
  },
  headerTextTruncate: {
    display: 'inline-block',
    fontSize: isWidget ? 18 : 20,
    lineHeight: isWidget ? 1.333 : 1.4,
    fontWeight: 500,
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 362,
    [theme.breakpoints.down('sm')]: {
      width: 264,
    },
  },
  headerText: {
    display: 'inline',
    fontSize: isWidget ? 18 : 20,
    lineHeight: isWidget ? 1.333 : 1.4,
    fontWeight: 500,
    color: 'white',
    maxWidth: '10ch',
    wordBreak: 'break-all',
    margin: 'auto',
    [isWidget && isLandscapeQuery]: {
      maxWidth: 'none',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 16,
    width: 40,
    height: 40,
    top: 16,
    color: 'white',
  },
  declineButton: {
    display: 'flex',
    margin: '0 auto',
    width: 148,
    color: 'white',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.33,
    '&:hover': {
      backgroundColor: 'transparent',
      filter: 'brightness(0.5)',
    },
  },
  bottomFlexWrapper: {
    position: isWidget ? 'absolute' : 'static',
    bottom: isWidget ? 'calc(37% - 8px)' : 'calc(35% - 8px)',
    [isWidget && isLandscapeQuery]: {
      position: 'relative',
    },
  },
}));
