import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PreloaderIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon
      {...props}
      style={{
        width: '100px',
        height: '100px',
        ...(props.style ?? {}),
      }}
      version="1.1"
      id="L4"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-25 0 100 100"
      enableBackground="new 0 0 0 0"
    >
      <circle fill={props.color || '#FF0066'} stroke="none" cx="6" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
      </circle>
      <circle fill={props.color || '#FFB13F'} stroke="none" cx="26" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
      </circle>
      <circle fill={props.color || '#7E05FE'} stroke="none" cx="46" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
      </circle>
    </SvgIcon>
  );
};

export default PreloaderIcon;
