import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  INIT_NOTIFICATIONS_SUBSCRIPTION,
  ON_NOTIFICATION_RECEIVED,
  UNSUBSCRIBE_FROM_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS_SUCCESS,
} from '../../actions';
import { Action } from '../../types';
import { Notification, NotificationsState, NotificationStatus } from '../../types/notification.types';

export const initialState: NotificationsState = {
  allNotifications: {
    data: [],
    page: 1,
    errorMessage: '',
    loading: false,
  },
  newNotificationsCount: 0,
  errorMessage: '',
  notificationsChannel: null,
};

export const notificationsReducer = (state = initialState, action: Action): NotificationsState => {
  switch (action.type) {
    case INIT_NOTIFICATIONS_SUBSCRIPTION:
      return { ...state, notificationsChannel: action.payload };
    case ON_NOTIFICATION_RECEIVED:
      return {
        ...state,
        newNotificationsCount: state.newNotificationsCount + 1,
        allNotifications: {
          ...state.allNotifications,
          data: [{ ...action.payload }, ...state.allNotifications.data],
        },
      };
    case UNSUBSCRIBE_FROM_NOTIFICATIONS:
      if (state.notificationsChannel) {
        state.notificationsChannel.close();
      }
      return {
        ...state,
        notificationsChannel: null,
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          loading: true,
          page: action.payload.page,
        },
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          loading: false,
          errorMessage: action.payload,
        },
      };
    case GET_NOTIFICATIONS_SUCCESS:
      const unreadNotificationsCount = (action.payload as Notification[]).filter(
        (x) => x.status === NotificationStatus.NEW,
      ).length;

      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          loading: false,
          page: action.payload.length === 10 ? state.allNotifications.page : -1,
          data:
            state.allNotifications.page === 1 ? action.payload : [...state.allNotifications.data, ...action.payload],
        },
        newNotificationsCount: state.newNotificationsCount + unreadNotificationsCount,
      };
    case UPDATE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          loading: false,
          errorMessage: action.payload,
        },
      };
    case UPDATE_NOTIFICATIONS_SUCCESS:
      const diff = state.newNotificationsCount - action.payload.length;
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          data: updateNotificationStatus(
            state.allNotifications.data,
            action.payload.map((notification: Notification) => notification.id),
          ),
        },
        newNotificationsCount: diff < 0 ? 0 : diff,
      };
    default:
      return state;
  }
};

function updateNotificationStatus(notifications: Notification[], ids: string[]) {
  return notifications.map((notification) => {
    if (ids.includes(notification.id)) {
      return { ...notification, status: NotificationStatus.READ };
    }
    return notification;
  });
}
