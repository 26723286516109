import React from 'react';

import { i18n as I18nType } from 'i18next';

export type I18Settings = {
  hideIfNotReady?: boolean;
  hideKeysIfNotReady?: boolean;
  usesInformalLanguage?: boolean;
  autoLoadResources?: boolean;
};

export const I18nContext = React.createContext<{
  i18n: I18nType;
  i18nReady: boolean;
  settings?: I18Settings;
  language?: {
    browserLanguage?: string;
    i18nLanguage?: string;
  } | null;
}>({} as any);
