import { GET_AVAILABLE_SLOTS, GET_AVAILABLE_SLOTS_FAIL, GET_AVAILABLE_SLOTS_SUCCESS } from '../../actions';
import { Action, AmazdDetailsState, SlotsWithDurationsByDates } from '../../types';

export const initialState: AmazdDetailsState = {
  loading: false,
  errorMessage: '',
  amazd: null,
  availableSlotsLoading: false,
  bookSlotLoading: false,
  availableDurations: [],
  availableSlotsByDate: [],
};

export const amazdDetailsReducer = (state = initialState, action: Action): AmazdDetailsState => {
  const { payload } = action;

  switch (action.type) {
    case GET_AVAILABLE_SLOTS: {
      return {
        ...state,
        availableSlotsLoading: true,
        availableSlotsByDate: [],
        availableDurations: [],
      };
    }
    case GET_AVAILABLE_SLOTS_SUCCESS:
      const { durations, slotsWithDurationsByDates } = payload.slotsWithDurationsByDates as SlotsWithDurationsByDates;
      return {
        ...state,
        availableDurations: durations.slice().sort((a, b) => a - b),
        availableSlotsByDate: slotsWithDurationsByDates
          .slice()
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
        availableSlotsLoading: false,
      };
    case GET_AVAILABLE_SLOTS_FAIL:
      return {
        ...state,
        availableSlotsLoading: false,
      };
    default:
      return state;
  }
};
