import { all, put, spawn, takeLatest } from 'redux-saga/effects';

import { getClient } from '../../../apollo';
import { getGraphQlError, reportError, hasAccessTo, Permission } from '../../../utils';
import { getAuthStoreState } from '../../../utils/auth';
import { GET_MY_CALLS } from '../../actions';
import { getMyCallsFail, getMyCallsSuccess } from '../../actions/callsActions';
import { getMyCallsQuery, getMyCompanyCallsQuery } from '../../graphql/queries/callsQuery';

function* getCalls(): any {
  try {
    const ownUser = getAuthStoreState().user;

    const res = yield getClient().query({
      query: hasAccessTo(ownUser?.role, Permission.COMPANY_CALLS) ? getMyCompanyCallsQuery : getMyCallsQuery,
      variables: {},
      fetchPolicy: 'no-cache',
    });
    const { data } = res;

    if (data && (data.myAppointmentsV2 || data.companyAppointmentsV2)) {
      yield put(getMyCallsSuccess(data.myAppointmentsV2 || data.companyAppointmentsV2));
    }
  } catch (err) {
    yield put(getMyCallsFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* getCallsSaga() {
  yield takeLatest(GET_MY_CALLS, getCalls);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([getCallsSaga()]);
}
