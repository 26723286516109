import React from 'react';

import BackgroundGradient from './components/BackgroundGradient';
import LocalParticipantThumbnail from './components/LocalParticipantThumbnail';
import PresentationBanner from './components/PresentationBanner';
import PresentationContainer from './components/PresentationContainer';
import RemoteParticipant from './components/RemoteParticipant';
import RemoteParticipantThumbnail from './components/RemoteParticipantThumbnail';
import StatusMessage from './components/StatusMessage';
import { useVideoCallContext } from './contexts/videoCallContext';
import ControlsLayout from './layouts/ControlsLayout';
import DesktopLayout from './layouts/DesktopLayout';
import MainLayout from './layouts/MainLayout';
import ParticipantsLayout from './layouts/ParticipantsLayout';
import SecondaryLayout from './layouts/SecondaryLayout';
import WidgetLayout from './layouts/WidgetLayout';

const VideoCallView = () => {
  const { isWidgetScreen, visible } = useVideoCallContext();

  if (!visible) {
    return <></>;
  }

  const Layout = isWidgetScreen ? WidgetLayout : DesktopLayout;

  return (
    <Layout>
      <BackgroundGradient />
      <StatusMessage />
      <PresentationBanner />

      <ParticipantsLayout>
        <MainLayout>
          <PresentationContainer />
          <RemoteParticipant />
        </MainLayout>

        <SecondaryLayout>
          <RemoteParticipantThumbnail />
          <LocalParticipantThumbnail />
        </SecondaryLayout>
      </ParticipantsLayout>

      <ControlsLayout />
    </Layout>
  );
};

export default VideoCallView;
