import { isServer } from './helpers';

export const isFirefox = (): boolean => {
  let isFirefox = false;
  if (typeof window !== 'undefined') {
    isFirefox = window.navigator?.userAgent?.toLowerCase()?.indexOf('firefox') > -1;
  }

  return isFirefox;
};

export const getBrowserLanguage = (): string => {
  try {
    return window.navigator?.language?.toLowerCase().substring(0, 2) || 'en';
  } catch (error) {
    console.error(error);
  }

  return 'en';
};

export const iOSversion = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isSafari = (): boolean => {
  if (typeof window === 'undefined') return false;
  // Excludes Chrome and Android browsers that includes Safari name
  return (
    window.navigator.userAgent.indexOf('Safari') !== -1 &&
    window.navigator.userAgent.indexOf('Chrome') === -1 &&
    window.navigator.userAgent.indexOf('CriOS') === -1
  );
};

export const isMobile = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const hasLocalStorageAccess = () => {
  try {
    return localStorage ? true : false;
  } catch (error) {
    return false;
  }
};

export class IFrameInteractions {
  static postMessage(event: string, data?: Record<string, unknown>): void {
    if (isServer()) return;
    (window.parent || window).postMessage(
      {
        event,
        data,
      },
      '*',
    );
  }
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getWidgetBackendV2Url() {
  const pullRequestId = process.env.VITE_NETLIFY_REVIEW_ID;
  if (pullRequestId) {
    return `https://amazd-pr-${pullRequestId}-api-v2-2424bulv2a-ew.a.run.app/graphql`;
  }
  return process.env.VITE_BACKEND_V2_URL as string;
}
