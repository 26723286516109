import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SettingsIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="7.8122 7.4797 15.81 15.81">
      <path
        d="M14.969 23.71h1.578c.601 0 1.062-.366 1.203-.944l.336-1.461.25-.086 1.273.781c.508.32 1.094.242 1.524-.188l1.094-1.085c.43-.43.507-1.024.187-1.524l-.797-1.265.094-.235 1.46-.344c.571-.14.946-.609.946-1.203V14.61a1.2 1.2 0 00-.945-1.203l-1.445-.351-.102-.25.797-1.266c.32-.5.242-1.086-.188-1.523l-1.093-1.094c-.422-.422-1.008-.5-1.516-.188l-1.273.782-.266-.102-.336-1.46a1.194 1.194 0 00-1.203-.946h-1.578c-.602 0-1.063.367-1.203.945l-.344 1.461-.266.102-1.265-.782c-.508-.312-1.102-.234-1.524.188l-1.086 1.094c-.43.437-.515 1.023-.187 1.523l.789 1.266-.094.25-1.445.351a1.2 1.2 0 00-.946 1.203v1.547c0 .594.375 1.063.946 1.203l1.46.344.087.235-.79 1.265c-.328.5-.242 1.094.188 1.524l1.086 1.085c.43.43 1.023.508 1.531.188l1.266-.781.25.086.344 1.46c.14.579.601.946 1.203.946zm.125-1.218c-.133 0-.203-.055-.227-.18l-.469-1.937a4.415 4.415 0 01-1.257-.516l-1.704 1.047c-.093.07-.195.055-.289-.039l-.921-.922c-.086-.086-.094-.18-.032-.289l1.047-1.687a5.694 5.694 0 01-.508-1.25l-1.937-.461c-.125-.024-.18-.094-.18-.227v-1.304c0-.141.047-.204.18-.227l1.93-.469c.125-.508.359-.969.5-1.258l-1.04-1.687c-.07-.117-.062-.211.024-.305l.93-.906c.093-.094.18-.11.296-.04l1.688 1.024a6.336 6.336 0 011.281-.515l.461-1.938c.024-.125.094-.18.227-.18h1.328c.133 0 .203.055.219.18l.476 1.953a5.7 5.7 0 011.258.508l1.695-1.031c.125-.07.203-.055.305.039l.922.906c.094.094.094.188.023.305l-1.039 1.687c.149.29.375.75.5 1.258l1.938.469c.125.023.18.086.18.227v1.304c0 .133-.063.203-.18.227l-1.946.46c-.125.477-.32.923-.507 1.25l1.047 1.688c.062.11.062.203-.032.29l-.914.921c-.101.094-.195.11-.297.04l-1.703-1.048a4.36 4.36 0 01-1.25.516l-.476 1.938c-.016.125-.086.18-.22.18h-1.327zm.664-4.148a2.993 2.993 0 002.976-2.985c0-1.625-1.343-2.968-2.976-2.968s-2.985 1.343-2.985 2.968a3 3 0 002.985 2.985zm0-1.211a1.776 1.776 0 01-1.766-1.774c0-.96.797-1.757 1.766-1.757.953 0 1.75.796 1.75 1.757 0 .97-.797 1.774-1.75 1.774z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default SettingsIcon;
