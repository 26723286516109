import { gql } from '@apollo/client';

import { NOTIFICATION_FIELDS } from '../../graphql/fragments/notificationFields';

export const getNotificationsQuery = gql`
  ${NOTIFICATION_FIELDS}
  query ($pagination: PaginationDto, $data: NotificationsDto!) {
    notifications(pagination: $pagination, data: $data) {
      data {
        ...NotificationFields
      }
    }
  }
`;
