import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CameraIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-1.54 -4.891 32.63 32.63">
      <path
        d="M3.96582 22.3682H25.0229C27.4521 22.3682 28.7095 21.1353 28.7095 18.7183V6.62109C28.7095 4.21631 27.4521 2.9834 25.0229 2.9834H21.7637C20.8604 2.9834 20.5796 2.8125 20.0425 2.22656L19.127 1.21338C18.541 0.578613 17.9429 0.249023 16.7588 0.249023H12.1567C10.9727 0.249023 10.3745 0.578613 9.78857 1.21338L8.87305 2.22656C8.33594 2.8125 8.04297 2.9834 7.15186 2.9834H3.96582C1.52441 2.9834 0.279297 4.21631 0.279297 6.62109V18.7183C0.279297 21.1353 1.52441 22.3682 3.96582 22.3682ZM4.03906 20.354C2.92822 20.354 2.29346 19.7559 2.29346 18.584V6.75537C2.29346 5.5835 2.92822 4.99756 4.03906 4.99756H7.67676C8.71436 4.99756 9.27588 4.80225 9.84961 4.15527L10.7407 3.1665C11.3999 2.43408 11.7417 2.25098 12.7671 2.25098H16.1484C17.1738 2.25098 17.5156 2.43408 18.1626 3.1543L19.0659 4.15527C19.6396 4.80225 20.189 4.99756 21.2266 4.99756H24.9497C26.0605 4.99756 26.6953 5.5835 26.6953 6.75537V18.584C26.6953 19.7559 26.0605 20.354 24.9497 20.354H4.03906ZM14.4883 18.7427C17.9795 18.7427 20.7627 15.9473 20.7627 12.4561C20.7627 8.95264 17.9795 6.15723 14.4883 6.15723C11.0093 6.15723 8.21387 8.95264 8.21387 12.4561C8.21387 15.9473 11.0093 18.7427 14.4883 18.7427ZM21.2144 8.23242C21.2144 9.0625 21.8857 9.69727 22.6914 9.69727C23.4727 9.68506 24.144 9.05029 24.144 8.23242C24.144 7.45117 23.4727 6.77979 22.6914 6.77979C21.8857 6.77979 21.2144 7.45117 21.2144 8.23242ZM14.4883 16.875C12.0591 16.875 10.0815 14.9097 10.0815 12.4561C10.0815 9.99023 12.0469 8.0249 14.4883 8.0249C16.9297 8.0249 18.9072 9.99023 18.9072 12.4561C18.9072 14.9097 16.9297 16.875 14.4883 16.875Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default CameraIcon;
