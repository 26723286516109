import { useTranslation } from '@amazd/common/utils/i18n';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 36,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '24px',
    '&.minimized': {
      top: 16,
    },
    '&.isWidgetScreen': {
      top: 16,
    },
  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    height: '46px',
    background: 'rgba(20, 20, 26, 0.3)',
    backdropFilter: 'blur(60px)',
    borderRadius: '100px',
    '&.minimized': {
      height: '36px',
    },
  },
  text: {
    fontSize: '16px',
    color: 'white',
    '&.isWidgetScreen': {
      fontSize: '14px',
    },
    '&.minimized': {
      fontSize: '12px',
    },
  },
}));

const PresentationBanner = () => {
  const { t } = useTranslation();
  const { minimized, presentingParticipant, isWidgetScreen } = useVideoCallContext();
  const { participantUser, isRemote } = useParticipant(presentingParticipant);

  const classes = useStyles({});
  const containerClasses = clsx(classes.container, { isWidgetScreen, minimized });
  const bannerClasses = clsx(classes.banner, { minimized });
  const textClasses = clsx(classes.text, { isWidgetScreen, minimized });

  if (!presentingParticipant || !isRemote) {
    return <></>;
  }

  return (
    <div className={containerClasses}>
      {participantUser && (
        <div className={bannerClasses}>
          <span className={textClasses}>
            {`${participantUser?.firstName} ${participantUser?.lastName} ${t('video-call-is-presenting-to-you')}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default PresentationBanner;
