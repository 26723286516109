import { I18nData } from '@amazd/common/redux/types/widget-config';
import { DEFAULT_NAMESPACE, VALID_NAMESPACES, I18N_API_URL, ALL_LANGUAGES } from '@amazd/common/utils/i18n';
import type { i18n as I18nType, InitOptions } from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const initI18nInstance = (
  i18nInstance: I18nType,
  { resources, browserLanguage, i18nLanguage }: I18nData,
  useAutoLoading?: boolean,
) => {
  const options: InitOptions = {
    lng: i18nLanguage,
    fallbackLng: browserLanguage,
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  };

  if (useAutoLoading) {
    i18nInstance.use(HttpApi);
    options.backend = {
      loadPath: `${I18N_API_URL}/{{ns}}/{{lng}}.json`,
      crossDomain: true,
      requestOptions: {
        cache: 'default',
      },
    };
    options.load = 'languageOnly';
    options.ns = VALID_NAMESPACES;
    options.defaultNS = DEFAULT_NAMESPACE;
    (options as any).whitelist = ALL_LANGUAGES;
  } else {
    options.resources = resources;
  }

  return i18nInstance.use(initReactI18next).init({ ...options });
};
