import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ImageIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon viewBox="0 0 24 20" fill="none" {...props}>
      <path
        d="M3.60588 15.0417H5.26387V16.3698C5.26387 18.158 6.16665 19.0608 7.98956 19.0608H20.6545C22.4687 19.0608 23.3715 18.158 23.3715 16.3698V8.40104C23.3715 6.61285 22.4687 5.71875 20.6545 5.71875H19.5347C19.309 5.71875 19.1354 5.70139 18.9878 5.67535V3.56597C18.9878 1.77778 18.0764 0.875 16.2708 0.875H3.60588C1.78297 0.875 0.880188 1.77778 0.880188 3.56597V12.3507C0.880188 14.1389 1.78297 15.0417 3.60588 15.0417ZM3.62324 13.6441C2.75519 13.6441 2.27776 13.1753 2.27776 12.2726V3.6441C2.27776 2.74132 2.75519 2.27257 3.62324 2.27257H16.2448C17.1041 2.27257 17.5903 2.74132 17.5903 3.6441V4.45139C17.1823 4.03472 16.7743 3.84375 16.1406 3.84375H12.5035C11.8177 3.84375 11.401 4.06944 10.9496 4.55556L10.3333 5.23264C9.95137 5.64062 9.75172 5.71875 9.10067 5.71875H7.98956C6.16665 5.71875 5.26387 6.61285 5.26387 8.40104V13.6441H3.62324ZM8.00692 17.6632C7.13887 17.6632 6.66144 17.1944 6.66144 16.2917V8.47917C6.66144 7.57639 7.13887 7.11632 8.00692 7.11632H9.43922C10.1857 7.11632 10.6458 6.96007 11.0885 6.48264L11.835 5.65799C12.1128 5.37153 12.3732 5.24132 12.842 5.24132H15.7934C16.2708 5.24132 16.5225 5.37153 16.8003 5.65799L17.5555 6.48264C17.9982 6.96007 18.4496 7.11632 19.1962 7.11632H20.6285C21.4965 7.11632 21.9739 7.57639 21.9739 8.47917V16.2917C21.9739 17.1944 21.4965 17.6632 20.6285 17.6632H8.00692ZM14.3264 16.4913C16.6441 16.4913 18.5278 14.6163 18.5278 12.2812C18.5278 9.96354 16.6441 8.07986 14.3264 8.07986C12 8.07986 10.125 9.96354 10.125 12.2812C10.125 14.6163 12 16.4913 14.3264 16.4913ZM19.6562 10.2847C20.1944 10.2847 20.6371 9.85069 20.6371 9.3125C20.6371 8.77431 20.1944 8.3316 19.6562 8.3316C19.118 8.3316 18.684 8.77431 18.684 9.3125C18.684 9.85069 19.118 10.2847 19.6562 10.2847ZM14.3264 15.1632C12.7465 15.1632 11.4531 13.8698 11.4531 12.2812C11.4531 10.7014 12.7465 9.41667 14.3264 9.41667C15.9062 9.41667 17.191 10.7014 17.191 12.2812C17.191 13.8698 15.9062 15.1632 14.3264 15.1632Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
        {...props}
      />
    </SvgIcon>
  );
};

export default ImageIcon;
