import { useCallback, useEffect, useRef, useState } from 'react';

import {
  connect as twilioConnect,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';

import { CallActionTypeEnum } from '../../RingingPopup/types';
import { MediaSourceEnum } from '../contexts/videoCallContext';
import { useRoomToken } from '../hooks/useRoomToken';

const useRoom = () => {
  const interruptConnecting = useRef(false);
  const [devicePermissionDenied, setDevicePermissionDenied] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [noCamera, setNoCamera] = useState(true);
  const [room, setRoom] = useState<Room>();
  const [getRoomToken] = useRoomToken();

  useEffect(() => {
    window.addEventListener('beforeunload', disconnect);
    return () => {
      window.removeEventListener('beforeunload', disconnect);
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewVideoCamTrack = async () => {
    try {
      const videoTrack = await createLocalVideoTrack({
        name: MediaSourceEnum.CAMERA,
      });
      return videoTrack;
    } catch (error) {
      console.error(error);
      setNoCamera(true);
    }
  };

  const isCameraAvailable = async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    mediaDevices.forEach((mediaDevice: any) => {
      if (mediaDevice.kind === 'videoinput') {
        setNoCamera(false);
      }
    });
  };

  const connect = useCallback(
    async (
      roomId: string,
      callEventType?: CallActionTypeEnum,
      sendCallEvent?: (amazdId: string, callEventType: CallActionTypeEnum, updateQueue?: VoidFunction) => void,
    ) => {
      if (room) disconnect();
      if (connecting) return;

      setDevicePermissionDenied(false);
      setConnecting(true);
      setConnectionError(false);

      interruptConnecting.current = false;

      let newRoom = null;
      const tracks: LocalTrack[] = [];

      try {
        const roomData = await getRoomToken(roomId);

        const audioTrack = await createLocalAudioTrack({ name: MediaSourceEnum.MIC });
        tracks.push(audioTrack);

        await isCameraAvailable();

        newRoom = await twilioConnect(roomData.data.roomToken?.roomToken, {
          name: roomData.data.roomToken?.roomId,
          tracks,
        });

        // if interrupted by disconnect
        if (interruptConnecting.current) {
          throw new Error('connection process interrupted');
        }

        setRoom(newRoom);
      } catch (error: any) {
        console.error(error);
        tracks.forEach((t) => (t as LocalAudioTrack | LocalVideoTrack).stop());
        newRoom?.disconnect();
        interruptConnecting.current = false;

        // user denied permission to use mic or just do not have a microphone available
        if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
          setDevicePermissionDenied(true);
        } else {
          setConnectionError(true);
        }
      }

      if (callEventType && sendCallEvent) {
        void sendCallEvent(roomId, callEventType);
      }

      setConnecting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [room, connecting],
  );

  const disconnect = () => {
    if (room) {
      room.localParticipant.tracks.forEach((p) => {
        const track = p.track as LocalAudioTrack | LocalVideoTrack;
        track.stop();
      });

      room.disconnect();
    }

    setRoom(undefined);
    interruptConnecting.current = true;
    setConnecting(false);
  };

  return {
    connect,
    disconnect,
    createNewVideoCamTrack,
    connecting,
    connectionError,
    devicePermissionDenied,
    noCamera,
    room,
  };
};

export default useRoom;
