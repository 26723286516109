import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ChatIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36 36"
      style={{ fill: 'none', width: '36px', height: '36px' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 25.206L12.599 27.013V23.716C10.42 22.227 9 19.865 9 17.182C9 12.643 13.048 9 18 9C22.952 9 27 12.643 27 17.182C27 21.721 22.952 25.364 18 25.364C17.401 25.364 16.817 25.308 16.25 25.206Z"
        stroke={props ? props.color || 'inherit' : 'inherit'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 17.75C18.5 18.716 17.7167 19.5 16.75 19.5C15.784 19.5 15 18.716 15 17.75C15 16.7832 15.784 16 16.75 16C17.7168 16 18.5 16.7833 18.5 17.75Z"
        fill="#9837FE"
        strokeWidth="0"
      />
      <path
        d="M22.2901 19.4138C21.3707 19.7125 20.3847 19.21 20.0861 18.2906C19.7875 17.372 20.2907 16.3844 21.2086 16.0858C22.128 15.7879 23.1148 16.2904 23.4134 17.2091C23.7113 18.1284 23.2087 19.1153 22.2901 19.4138H22.2901Z"
        fill="#FFB13F"
        strokeWidth="0"
      />
      <path
        d="M28.5 17.7504C28.5 18.7169 27.7161 19.5 26.7496 19.5C25.7831 19.5 25 18.7169 25 17.7504C25 16.7839 25.7831 16 26.7496 16C27.7161 16 28.5 16.7839 28.5 17.7504Z"
        fill="#FF0066"
        strokeWidth="0"
      />
    </SvgIcon>
  );
};

export default ChatIcon;
