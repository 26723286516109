import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ChevronDown: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
      <path
        d="M8 10L12 14L16 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ChevronDown;
