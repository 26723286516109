import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ScreenShareIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M8.75 22.3332C8.19772 22.3332 7.75 22.7809 7.75 23.3332C7.75 23.8855 8.19772 24.3332 8.75 24.3332V22.3332ZM19.25 24.3332C19.8023 24.3332 20.25 23.8855 20.25 23.3332C20.25 22.7809 19.8023 22.3332 19.25 22.3332V24.3332ZM10.9586 10.9592C10.568 11.3496 10.5678 11.9828 10.9582 12.3734C11.3487 12.7641 11.9818 12.7642 12.3725 12.3738L10.9586 10.9592ZM14 9.33317L14.707 8.62589C14.3165 8.2356 13.6836 8.2356 13.2931 8.62589L14 9.33317ZM15.6276 12.3738C16.0182 12.7642 16.6514 12.7641 17.0418 12.3734C17.4322 11.9828 17.4321 11.3496 17.0415 10.9592L15.6276 12.3738ZM13 15.1665C13 15.7188 13.4477 16.1665 14 16.1665C14.5523 16.1665 15 15.7188 15 15.1665L13 15.1665ZM4.78917 5.6665H23.2108V3.6665H4.78917V5.6665ZM23.2108 5.6665C23.3705 5.6665 23.5 5.79597 23.5 5.95567H25.5C25.5 4.6914 24.4751 3.6665 23.2108 3.6665V5.6665ZM23.5 5.95567V18.544H25.5V5.95567H23.5ZM23.5 18.544C23.5 18.7037 23.3705 18.8332 23.2108 18.8332V20.8332C24.4751 20.8332 25.5 19.8083 25.5 18.544H23.5ZM23.2108 18.8332H4.78917V20.8332H23.2108V18.8332ZM4.78917 18.8332C4.62946 18.8332 4.5 18.7037 4.5 18.544H2.5C2.5 19.8083 3.5249 20.8332 4.78917 20.8332V18.8332ZM4.5 18.544V5.95567H2.5V18.544H4.5ZM4.5 5.95567C4.5 5.79597 4.62946 5.6665 4.78917 5.6665V3.6665C3.52489 3.6665 2.5 4.6914 2.5 5.95567H4.5ZM8.75 24.3332H19.25V22.3332H8.75V24.3332ZM12.3725 12.3738L14.707 10.0405L13.2931 8.62589L10.9586 10.9592L12.3725 12.3738ZM13.2931 10.0405L15.6276 12.3738L17.0415 10.9592L14.707 8.62589L13.2931 10.0405ZM15 15.1665L15 9.33317L13 9.33317L13 15.1665L15 15.1665Z"
        fill={props.color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default ScreenShareIcon;
