export const createFileFromBase64 = async (
  base64String: string,
  fileName: string = new Date().getUTCMilliseconds() + '',
): Promise<File> => {
  const res = await fetch(base64String);
  const file: any = (await res.blob()) as any;
  file.lastModifiedDate = new Date();
  file.name = fileName;

  return <File>file;
};

export const downloadBase64 = (base64: string, fileName: string, mimeType: string) => {
  const blob = Buffer.from(base64, 'base64').toString('binary');
  const url = URL.createObjectURL(new Blob([blob], { type: mimeType }));

  if (blob) {
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', fileName);
    a.click();
    a.remove();
  }
};
