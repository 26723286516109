import { FetchResult, Observable } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { EventChannel, eventChannel } from 'redux-saga';

export function getGraphQlError(graphQLError: ErrorResponse | any): Error {
  const graphQLErrors = graphQLError.graphQLErrors || [];
  const error: GraphQLError = graphQLErrors[0] || { message: 'Something bad happened' };
  return new Error(error.message);
}

export function createChannel(
  graphQlSubscription: Observable<FetchResult<any, Record<string, any>, Record<string, any>>>,
): EventChannel<any> {
  return eventChannel((emit) => {
    const subscription = graphQlSubscription.subscribe(
      (data) => {
        // puts event payload into the channel
        // this allows a Saga to take this payload from the returned channel
        emit(data);
      },
      (error) => {
        emit(error);
      },
    );

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      subscription.unsubscribe();
    };

    return unsubscribe;
  });
}

export function createCombinedChannel(
  graphQlSubscription1: Observable<FetchResult<any, Record<string, any>, Record<string, any>>>,
  graphQlSubscription2: Observable<FetchResult<any, Record<string, any>, Record<string, any>>>,
): EventChannel<any> {
  return eventChannel((emit) => {
    const subscription1 = graphQlSubscription1.subscribe(
      (data) => {
        // puts event payload into the channel
        // this allows a Saga to take this payload from the returned channel
        emit(data);
      },
      (error) => {
        emit(error);
      },
    );

    const subscription2 = graphQlSubscription2.subscribe(
      (data) => {
        // puts event payload into the channel
        // this allows a Saga to take this payload from the returned channel
        emit(data);
      },
      (error) => {
        emit(error);
      },
    );

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };

    return unsubscribe;
  });
}
