import { User, UserType } from '@amazd/common/types';

import { SupportedProviders } from './helpers';

export type SignUpArgs = { email: string; firstName: string; lastName: string; locale?: string };
export type SignUpDto = { user: User; userTypes: UserType[]; accessToken: string; refreshToken: string };

export type RegisterOrSignUpArgs = SignUpArgs & { password?: string };

export enum LoginProviderEnum {
  AMAZD = 'AMAZD',
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  appId: string;
  authEmulatorHost?: string;
}

export type LoginWithFirebaseArgs = {
  provider: SupportedProviders;
  locale: 'en' | 'de';
  config: FirebaseConfig;
  fcmRegistrationToken?: string | undefined;
};
