import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CallEndIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-1.5858 -8.4196 36.07 36.07">
      <path
        d="M0 10.9577C0 12.6197 0.968182 14 2.67273 14C3 14 3.50455 13.9718 4.21364 13.8451L9.04091 13.0986C11.0318 12.8028 11.5773 11.9577 11.5773 10.1972C11.5773 9.66197 11.5773 9.15493 11.5909 8.61972C11.5909 8.1831 11.7818 8 12.0955 7.91549C14.1 7.4507 18.9 7.4507 20.9045 7.91549C21.2182 8 21.4091 8.1831 21.4091 8.61972C21.4227 9.15493 21.4227 9.66197 21.4227 10.1972C21.4227 11.9577 21.9682 12.8028 23.9591 13.0986L28.7864 13.8451C29.4955 13.9718 30 14 30.3273 14C32.0318 14 33 12.6197 33 10.9577V9.80282C33 3.25352 25.9091 0 16.5 0C7.10455 0 0 3.25352 0 9.80282V10.9577ZM2.20909 10.6197V9.91549C2.20909 4.25352 9.25909 2.12676 16.5 2.12676C23.7409 2.12676 30.7909 4.25352 30.7909 9.91549V10.6197C30.7909 11.3099 30.4773 11.9155 29.7818 11.9155C29.5909 11.9155 29.3864 11.8873 29.0045 11.8169L24.4227 10.9296C23.9727 10.831 23.6318 10.5915 23.6318 9.90141V8.14084C23.6318 6.74648 23.1409 6.1831 22.4318 5.97183C20.2364 5.32394 12.7636 5.32394 10.5682 5.97183C9.85909 6.1831 9.36818 6.74648 9.36818 8.14084V9.90141C9.36818 10.5915 9.02727 10.831 8.57727 10.9296L3.99545 11.8169C3.61364 11.8873 3.42273 11.9155 3.21818 11.9155C2.52273 11.9155 2.20909 11.3099 2.20909 10.6197Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default CallEndIcon;
