import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import type { RemoteParticipant, LocalParticipant } from 'twilio-video';

import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';
import { useAttachedTrack } from '../hooks/useParticipantTracks';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    borderRadius: 'inherit',
    zIndex: 0,
    '&.isLocal': {
      transform: 'scale(-1, 1)', // miror video for local participant only. Remote video should not be mirrored, otherwsie, the text shown to camera won't be readable
    },
    '&.zoomToFit': {
      objectFit: 'cover',
    },

    '&.isWidgetScreen.zoomToFit': {
      paddingBottom: 'unset',
    },
  },
}));

export interface PropsType {
  participant?: RemoteParticipant | LocalParticipant;
  alwaysZoomToFit?: boolean;
}

const ParticipantVideo = ({ participant, alwaysZoomToFit }: PropsType) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isWidgetScreen } = useVideoCallContext();
  const { videoTrack, videoAcceptRatio, isLocal } = useParticipant(participant);
  useAttachedTrack(videoRef, videoTrack);
  const [containerAcceptRatio, setContainerAcceptRatio] = useState(0);
  const [zoomToFit, setZoomToFit] = useState(false);

  const calcContainerAcceptRatio = () => {
    if (videoRef?.current) {
      const container = videoRef.current as unknown as HTMLElement;
      const [width, height] = [container.offsetWidth, container.offsetHeight];
      const acceptRatio = width / height;
      setContainerAcceptRatio(acceptRatio);
    }
  };

  const calcZoomToFit = () => {
    if (containerAcceptRatio && videoAcceptRatio) {
      const difference = Math.abs(1 - videoAcceptRatio / containerAcceptRatio);
      const zoomToFit = difference < 0.31; // 0.31 is the threshold, when video gets zoomed on my 15inch MacBook Pro with Chrome
      setZoomToFit(zoomToFit);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', calcContainerAcceptRatio);
    return () => window.removeEventListener('resize', calcContainerAcceptRatio);
  }, []);

  useLayoutEffect(() => {
    calcContainerAcceptRatio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef?.current]);

  useEffect(() => {
    calcZoomToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerAcceptRatio, videoAcceptRatio]);

  const classes = useStyles({});
  const videoClasses = clsx(classes.root, {
    isWidgetScreen,
    zoomToFit: alwaysZoomToFit || zoomToFit,
    isLocal,
  });

  return <video className={videoClasses} ref={videoRef} autoPlay={true} />;
};

export default ParticipantVideo;
