import { SlotsByDate, SuggestedSlot } from '../redux/types';

export const getFirstAvailableSlotsDateIndex = (availableSlots: SlotsByDate[] = []): number | undefined => {
  let index;
  for (let i = 0; i < availableSlots.length; i++) {
    const availableSlot = availableSlots[i];
    if (availableSlot.slotsByDurations?.[0]?.slots?.length) {
      index = i;
      break;
    }
  }
  return index;
};

/**
 *
 * Get the first slots from the first three dates. If there are less than 3 days,
 * then use more slots from the most earlier dates, e.g. dates Nov 25 (has 3 slots), Nov 29 (has 10 slots),
 * then the result will be 2 slots from Nov 25 and 1 slot from Nov 29.
 */
export const getFirstThreeSlots = (availableSlots: SlotsByDate[]): SuggestedSlot[] => {
  const suggestedSlots: SuggestedSlot[] = [];

  let firstIndex = 0;
  const findSlot = () => {
    availableSlots.map((availableSlot, index) => {
      const SUGGESTED_SLOTS_COMPLETE = suggestedSlots.length === 3;
      const slot = availableSlot.slotsByDurations[0].slots[firstIndex];
      if (slot && !SUGGESTED_SLOTS_COMPLETE) {
        suggestedSlots.push({ slot, date: availableSlot.date as unknown as string });
      }
      if (index === availableSlots.length - 1 && !SUGGESTED_SLOTS_COMPLETE && firstIndex < 3) {
        firstIndex++;
        findSlot();
      }
    });
  };
  findSlot();

  return suggestedSlots.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
};

export function capitalize(string: string) {
  if (!string || string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const unCamelCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
};

export const sleep = (seconds: number) => new Promise<void>((done) => setTimeout(done, seconds * 1000));

export * from './analytics';
export * from './browser';
export * from './color';
export * from './date';
export * from './exception-reporter';
export * from './file';
export * from './graphql';
export * from './image-utils';
export * from './jwt-helper';
export * from './permissions';
export * from './role';
export * from './text';
export * from './wait-until';
export * from './helpers';
