import { gql } from '@apollo/client';

import { NOTIFICATION_FIELDS } from '../../graphql/fragments/notificationFields';

export const updateNotificationsMutation = gql`
  ${NOTIFICATION_FIELDS}
  mutation ($data: UpdateNotificationsDto!) {
    updateNotifications(data: $data) {
      data {
        ...NotificationFields
      }
    }
  }
`;
