export type MyShopDetails = {
  shopDomain?: string;
  shopPlatform?: ShopPlatform;
  defaultCurrency?: string;
  shopPlatformLabel?: string;
  features?: {
    checkout?: boolean;
  };
};

export type AdminShopDetails = MyShopDetails & {
  credentials?: string;
};

export enum ShopPlatform {
  MAGENTO = 'MAGENTO',
  SHOPIFY = 'SHOPIFY',
  SHOPWARE_V6 = 'SHOPWARE_V6',
}

export type WishbagLineItem = {
  quantity?: number;
  product?: any;
  variant?: any;
};

export type WishBagData = {
  lineItems?: WishbagLineItem[] | null;
  discountTotal?: number | null;
  discounts?: WishbagDiscount[] | null;
  subtotal?: number | null;
  itemsCount?: number | null;
};

export type WishbagDiscount = {
  code: string;
  isFreeShipping?: boolean;
};

export type ShopCatalogPageInfo = {
  pageNumber?: number;
  totalPages?: number;
  totalItems?: number;
  nextPage?: string;
};

export type ShopCategoryShort = {
  id: string;
  productsCount?: number;
  childrenCount?: number;
  title?: string;
};

export type ShopCategory = ShopCategoryShort & {
  description?: string;
  products?: PaginatedShopProduct;
  children?: ShopCategory[];
};

export type PagedShopCategory = {
  items?: ShopCategory[];
  pageInfo?: ShopCatalogPageInfo;
};

export type ShopProduct = {
  id: string;
  title?: string;
  sku?: string;
  stockStatus?: ProductStockStatus;
  totalInventory?: number;
  tracksInventory?: boolean;
  priceRangeV2?: {
    minVariantPrice?: {
      currencyCode?: string;
      amount?: number;
    };
    maxVariantPrice?: {
      currencyCode?: string;
      amount?: number;
    };
  };
  featuredImage?: {
    id?: string;
    url?: string;
  };
  thumbnailImage?: {
    id?: string;
    url?: string;
  };
  availableForSale?: boolean;
  openUrl?: string;
  description?: string;
  variants?: ShopProductVariant[];
  options?: ShopProductOption[];
  categories?: PagedShopCategory;
  publishedOnCurrentPublication?: boolean;
  hasOnlyDefaultVariant?: boolean;
  createdAt?: string;
};

export enum ProductStockStatus {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export type ShopProductOption = {
  id?: string;
  name?: string;
  position?: number;
  values?: string[];
};

export type PaginatedShopProduct = {
  items?: ShopProduct[];
  pageInfo?: ShopCatalogPageInfo;
};

export type ShopProductVariant = {
  id: string;
  title?: string;
  sku?: string;
  stockStatus?: ProductStockStatus;
  tracksInventory?: boolean;
  image?: {
    id?: string;
    url?: string;
  };
  availableForSale?: boolean;
  openUrl?: string;
  description?: string;
  price?: number;
  inventoryQuantity?: number;
  selectedOptions?: ShopProductVariantSelectedOption[];
};

export type ShopProductVariantSelectedOption = {
  id?: string;
  name?: string;
  value?: string;
  optionId?: string;
};
