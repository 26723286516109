import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import store from '@amazd/common/redux/store';
import { I18nData } from '@amazd/common/redux/types/widget-config';
import { getAuthStoreState } from '@amazd/common/utils/auth';
import {
  DEFAULT_NAMESPACE,
  getBrowserLanguage,
  initI18nInstance,
  isLanguageBundlesLoaded,
} from '@amazd/common/utils/i18n';
import { createInstance } from 'i18next';

import { I18Settings } from './context';

export function useI18nProvider(options: { data?: I18nData; settings?: I18Settings }) {
  const i18nReadyRef = useRef(false);
  const i18nRef = useRef(createInstance());
  const [language, setLanguage] = useState<{
    browserLanguage?: string;
    i18nLanguage?: string;
  } | null>(null);

  const { settings } = options;

  if (!i18nReadyRef.current && options.data?.resources) {
    i18nReadyRef.current = true;
    initI18nInstance(i18nRef.current, options.data);
    setTimeout(() => {
      setLanguage(options.data || null);
    }, 10);
  }

  const checkReady = useCallback(() => {
    if (!i18nReadyRef.current && isLanguageBundlesLoaded(i18nRef.current, DEFAULT_NAMESPACE)) {
      i18nReadyRef.current = true;
      const { browserLanguage } = getBrowserLanguage(settings?.usesInformalLanguage ?? true);
      setLanguage({ browserLanguage, i18nLanguage: i18nRef.current.language });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const i18n = i18nRef.current;

    if (settings?.autoLoadResources) {
      (async () => {
        const { browserLanguage, i18nLanguage } = getBrowserLanguage(settings?.usesInformalLanguage ?? true);
        await initI18nInstance(i18n, { resources: {}, browserLanguage, i18nLanguage }, true);
      })();

      i18n.on('loaded', checkReady);
      i18n.on('initialized', checkReady);
    }

    return () => {
      if (settings?.autoLoadResources) {
        i18n.off('loaded', checkReady);
        i18n.off('initialized', checkReady);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // On change language
    store.subscribe(() => {
      const userType = getAuthStoreState().getUserType();
      if (
        userType &&
        i18nReadyRef.current &&
        i18nRef.current.language !== userType.locale &&
        settings?.autoLoadResources
      ) {
        i18nReadyRef.current = false;
        setLanguage(null);
        i18nRef.current.options.fallbackLng = userType.locale;
        i18nRef.current.changeLanguage(userType.locale);
        checkReady();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const settingsMemo = useMemo(() => settings, [JSON.stringify(settings || {})]);

  return {
    settings: settingsMemo,
    language,
    i18nReady: i18nReadyRef.current,
    i18n: i18nRef.current,
  };
}
