import { CREATE_CALL_ROOM, CREATE_CALL_ROOM_FAIL } from '../../actions/constants';
import { Action } from '../../types';
import { Message } from '../../types/chat.types';

export function createCallRoom(conversationId: string): Action {
  return { type: CREATE_CALL_ROOM, payload: { conversationId } };
}

export function createCallRoomFail(errorMessage: string): Action {
  return { type: CREATE_CALL_ROOM_FAIL, payload: errorMessage };
}

export function createCallRoomSuccess(message: Message): Action {
  return { type: CREATE_CALL_ROOM_FAIL, payload: message };
}
