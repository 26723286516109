import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AnalyticsIcon: React.FC<any> = (): React.ReactElement => (
  <SvgIcon>
    <svg width="36" height="36" viewBox="8 7 32 34" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.709 27.9102C23.1582 27.9102 27.6699 23.3887 27.6699 17.9492C27.6699 12.5 23.1484 7.98828 17.6992 7.98828C12.2598 7.98828 7.74805 12.5 7.74805 17.9492C7.74805 23.3887 12.2695 27.9102 17.709 27.9102ZM17.709 26.25C13.0996 26.25 9.41797 22.5586 9.41797 17.9492C9.41797 13.5254 12.8066 9.94141 17.1621 9.66797V18.0859C17.1621 18.3008 17.2109 18.4766 17.3965 18.6523L23.1191 24.2578C21.6738 25.498 19.7793 26.25 17.709 26.25ZM18.4707 17.0996V9.67773C21.1172 9.92188 23.3926 11.3867 24.7305 13.5059L18.4707 17.0996ZM19.0176 18.3008L25.3359 14.668C25.7656 15.6738 26.0098 16.7773 26.0098 17.9492C26.0195 19.9902 25.2871 21.8555 24.0762 23.3008L19.0176 18.3008Z" />
      <path
        d="M24.9268 27.8523C23.3504 28.3642 21.6596 27.5028 21.1476 25.9267C20.6356 24.3519 21.4985 22.6589 23.0724 22.1471C24.6488 21.6364 26.3409 22.4979 26.8529 24.0727C27.3636 25.6488 26.502 27.3405 24.9268 27.8523H24.9268Z"
        fill="#EE9443"
      />
    </svg>
  </SvgIcon>
);

export default AnalyticsIcon;
