import { useAuthInfo } from '@amazd/common/hooks/auth';
import { gql, useSubscription } from '@apollo/client';

const useRingingSubscription = (calledInitialEvents: boolean) => {
  const { ownUser } = useAuthInfo();

  const RINGING_SUBSCRIPTION = gql`
    subscription watchCallEvents($topic: String!) {
      watchCallEvents(topic: $topic) {
        amazdId
        type
        user {
          id
          firstName
          lastName
          avatar
          company {
            handle
          }
        }
      }
    }
  `;

  const { data, loading, error } = useSubscription(RINGING_SUBSCRIPTION, {
    variables: {
      topic: ownUser && ownUser.id,
    },
    skip: !calledInitialEvents,
    shouldResubscribe: true,
  });

  return { data: data?.watchCallEvents, loading, error, ownUser };
};

export default useRingingSubscription;
