import { getClient } from '@amazd/common/apollo';
import { User, UserUpdateDto } from '@amazd/common/types';
import { trackIdentify } from '@amazd/common/utils/analytics';
import { getGraphQlError } from '@amazd/common/utils/graphql';

import { getMyUserQuery, registerMutation, signUpMutation, userUpdateMutation } from '../graphql';
import { getAuthStoreState } from '../store';
import { RegisterOrSignUpArgs } from '../types';
import { logoutUser, onLoginSuccess } from './auth';

export function onGetMyUserSuccess(user: User) {
  const state = getAuthStoreState();
  state.updateStore({
    user: {
      ...(state.user || {}),
      ...user,
    },
  });
  trackIdentify(user.id, {
    role: user.role,
  });
}

export async function registerUser(args: RegisterOrSignUpArgs) {
  const { email, password, firstName, lastName, locale } = args;
  const { updateStore } = getAuthStoreState();
  updateStore({
    errorMessage: null,
  });

  try {
    const res = password
      ? await getClient().mutate({
          mutation: registerMutation,
          variables: {
            input: {
              email,
              password,
              firstName,
              lastName,
              timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
              locale,
            },
          },
        })
      : await getClient().mutate({
          mutation: signUpMutation,
          variables: {
            args: {
              email,
              firstName,
              lastName,
              locale,
              timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        });

    const data = password ? res?.data?.register : res?.data?.signUp;
    if (data) {
      onGetMyUserSuccess({
        ...data.user,
        userTypes: [...data.userTypes],
      });
      onLoginSuccess(data);
    }
  } catch (err) {
    console.error('Error on register user', err, getGraphQlError(err));
    reportError(getGraphQlError(err));
    updateStore({
      errorMessage: getGraphQlError(err).message,
    });
  }
}

export async function getMyUser() {
  try {
    const res = await getClient().query({
      query: getMyUserQuery,
      variables: {
        timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      fetchPolicy: 'no-cache',
    });

    const { data } = res;
    if (data && data.whoAmI) {
      onGetMyUserSuccess(data.whoAmI);
    }
  } catch (err) {
    logoutUser();
    reportError(err);
  }
}

export async function updateUser(userId: string, data: UserUpdateDto) {
  const { updateStore } = getAuthStoreState();

  try {
    const res = await getClient().mutate({
      mutation: userUpdateMutation,
      variables: {
        id: userId,
        data,
      },
    });
    if (res?.data?.userUpdate) {
      await getMyUser();
    }
  } catch (err) {
    updateStore({
      errorMessage: getGraphQlError(err).message,
    });
    reportError(err);
  }
}

const userDataQueue: {
  next?: {
    userId: string;
    data: UserUpdateDto;
  };
  loading?: boolean;
} = {};

export async function enqueueUpdateUser(userId: string, data: UserUpdateDto) {
  if (userDataQueue.loading) {
    userDataQueue.next = { userId, data };
    return;
  }

  userDataQueue.loading = true;

  try {
    await updateUser(userId, data);
  } catch (err) {
    reportError(err);
  }

  const next = userDataQueue.next;
  userDataQueue.loading = false;
  userDataQueue.next = undefined;

  if (next) {
    await enqueueUpdateUser(next.userId, next.data);
  }
}
