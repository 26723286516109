import React from 'react';

import { I18nData } from '@amazd/common/redux/types/widget-config';
import { Trans as I18nTrans } from 'react-i18next';

import { I18nContext, I18Settings } from './context';
import { useI18nProvider } from './useI18nProvider';
import { useTranslation } from './useTranslation';

export const Trans: typeof I18nTrans = (props) => {
  const { i18n } = useTranslation();
  return <I18nTrans i18n={i18n} {...props} />;
};

export function I18nProvider(props: { children: React.ReactNode; data?: I18nData; settings?: I18Settings }) {
  const { i18n, i18nReady, settings, language } = useI18nProvider(props);
  return (
    <I18nContext.Provider value={{ i18n, i18nReady, settings, language }}>
      {settings?.hideIfNotReady && !i18nReady ? undefined : props.children}
    </I18nContext.Provider>
  );
}
