import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: CustomTheme) => ({
  backdrop: {
    background: 'white',
    zIndex: theme.zIndex.drawer + 3,
    color: '#fff',
    alignItems: 'unset',
    overflow: 'hidden',
  },
  body: {
    overflowY: 'auto',
  },
}));
