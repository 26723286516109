import { gql, useLazyQuery } from '@apollo/client';

const useGetActiveCallEvents = () => {
  const GET_ACTIVE_CALL_EVENTS = gql`
    query getActiveCallEvents {
      getActiveCallEvents {
        id
        amazdId
        type
        user {
          id
          firstName
          lastName
          avatar
          company {
            handle
          }
        }
      }
    }
  `;

  const [getActiveCallEvents, { data, loading, error, refetch }] = useLazyQuery(GET_ACTIVE_CALL_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  return { data: data?.getActiveCallEvents, loading, error, getActiveCallEvents, refetch };
};

export default useGetActiveCallEvents;
