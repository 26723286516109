import React from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';
import ParticipantAudio from './ParticipantAudio';
import ParticipantAvatar from './ParticipantAvatar';
import ParticipantVideo from './ParticipantVideo';

const minimizedThumbnail = {
  width: '150px',
  height: '84px',
};

const thumbnail = {
  width: '250px',
  height: '140px',
};

const squareThumbnail = {
  width: '164px',
  height: '164px',
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderRadius: '16px',
    minHeight: 'inherit',
    height: thumbnail.height,
    width: thumbnail.width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.4)',
    '&.minimized': {
      height: minimizedThumbnail.height,
      width: minimizedThumbnail.width,
    },
    '&.isWidgetScreen': {
      alignItems: 'center',
      height: minimizedThumbnail.height,
      width: minimizedThumbnail.width,
    },
    '&.isWidgetScreen.presenting': {
      height: squareThumbnail.height,
      width: squareThumbnail.width,
    },
  },
}));

const LocalParticipantThumbnail = (): JSX.Element => {
  const { minimized, presenting, localParticipant, isWidgetScreen } = useVideoCallContext();
  const { hasVideo } = useParticipant(localParticipant);

  const classes = useStyles();
  const rootClasses = clsx(classes.root, { minimized, presenting, isWidgetScreen });

  return (
    <div data-testid="local-participant-thumbnail" className={rootClasses}>
      {hasVideo ? (
        <ParticipantVideo participant={localParticipant} alwaysZoomToFit={true} />
      ) : (
        <ParticipantAvatar participant={localParticipant} />
      )}
      <ParticipantAudio participant={localParticipant} />
    </div>
  );
};

export default LocalParticipantThumbnail;
