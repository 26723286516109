import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  INIT_NOTIFICATIONS_SUBSCRIPTION,
  ON_NOTIFICATION_RECEIVED,
  SUBSCRIBE_TO_NOTIFICATIONS,
  UNSUBSCRIBE_FROM_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS_SUCCESS,
} from '../../actions/constants';
import { Action } from '../../types';
import { Notification } from '../../types/notification.types';

export function initNotificationsSubscription(channel: unknown): Action {
  return { type: INIT_NOTIFICATIONS_SUBSCRIPTION, payload: channel };
}

export function subscribeToNotifications(): Action {
  return { type: SUBSCRIBE_TO_NOTIFICATIONS };
}

export function unsubscribeFromNotifications(): Action {
  return { type: UNSUBSCRIBE_FROM_NOTIFICATIONS };
}

export function onNotificationsReceived(notification: Notification): Action {
  return { type: ON_NOTIFICATION_RECEIVED, payload: notification };
}

export function getNotifications(page: number): Action {
  return { type: GET_NOTIFICATIONS, payload: { page } };
}

export function getNotificationsSuccess(notifications: Notification[]): Action {
  return { type: GET_NOTIFICATIONS_SUCCESS, payload: notifications };
}

export function getNotificationsFail(errorMessage: string): Action {
  return { type: GET_NOTIFICATIONS_FAIL, payload: errorMessage };
}

export function updateNotifications(ids: string[]): Action {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: {
      ids,
    },
  };
}
export function updateNotificationsSuccess(notifications: Notification[]): Action {
  return { type: UPDATE_NOTIFICATIONS_SUCCESS, payload: notifications };
}

export function updateNotificationsFail(errorMessage: string): Action {
  return { type: UPDATE_NOTIFICATIONS_FAIL, payload: errorMessage };
}
