import React from 'react';

import { Typography } from '@mui/material';

import useStyles from './styles';

interface Props {
  src: string | null;
  initials: string;
  isWidget?: boolean;
  isMinimized?: boolean;
}

const Avatar = ({ src, initials, isWidget, isMinimized }: Props) => {
  const { classes } = useStyles({ isWidget, isMinimized } as any);

  if (src) {
    return <img src={src} alt="avatar" className={classes.avatar} />;
  }

  return <Typography className={classes.avatarName}>{initials}</Typography>;
};

export default Avatar;
