import React from 'react';

import ArrowLeftIcon from '@amazd/common/icons/ArrowLeftIcon';
import CloseIcon from '@amazd/common/icons/CloseIcon';
import { AppBar, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material';

import useStyles from './styles';
import { ModalLayoutProps } from './type';

const ModalLayout: React.FC<ModalLayoutProps> = ({ children, title, onClose }: ModalLayoutProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <AppBar color="transparent" className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={onClose} edge="start" color="primary" aria-label="menu">
            <ArrowLeftIcon />
          </IconButton>
          <Typography className={classes.title} variant="subtitle1">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {children && <Container className={classes.body}>{children}</Container>}
      <IconButton onClick={onClose} className={classes.closeButton} color="primary">
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

export default ModalLayout;
