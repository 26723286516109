import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AlertIcon: React.FC<SvgIconProps> = (props): React.ReactElement => (
  <SvgIcon {...props} style={{ width: '24px', height: '24px', fill: 'none', ...props.style }} viewBox="0 0 24 24">
    <path
      d="M9.51 18V18.512C9.51 19.1719 9.77213 19.8047 10.2387 20.2713C10.7053 20.7379 11.3381 21 11.998 21H12C12.3269 21.0001 12.6505 20.9359 12.9526 20.8109C13.2546 20.686 13.5291 20.5027 13.7603 20.2717C13.9915 20.0407 14.1749 19.7664 14.3002 19.4644C14.4254 19.1625 14.4899 18.8389 14.49 18.512V18M6 14.5V10C6 8.4087 6.63214 6.88258 7.75736 5.75736C8.88258 4.63214 10.4087 4 12 4C13.5913 4 15.1174 4.63214 16.2426 5.75736C17.3679 6.88258 18 8.4087 18 10V14.5H18.25C18.7141 14.5 19.1592 14.6844 19.4874 15.0126C19.8156 15.3408 20 15.7859 20 16.25C20 16.7141 19.8156 17.1592 19.4874 17.4874C19.1592 17.8156 18.7141 18 18.25 18H5.75C5.28587 18 4.84075 17.8156 4.51256 17.4874C4.18437 17.1592 4 16.7141 4 16.25C4 15.7859 4.18437 15.3408 4.51256 15.0126C4.84075 14.6844 5.28587 14.5 5.75 14.5H6Z"
      stroke={props.stroke || 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default AlertIcon;
