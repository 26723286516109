export const colors = {
  purple05: '#F9F3FF',
  purple10: '#F2E6FF',
  purple20: '#E9D4FF',
  purple40: '#D8B4FF',
  purple100: '#7E05FE',
  purple120: '#6504CB',
  amazdPurple: '#7E05FE',
  amazdGrey: '#c0b7af',
  amazdGrey0: '#727888',
  amazdGrey1: '#F3F4FC',
  amazdGrey2: '#D7D9E7',
  amazdGrey3: '#727888',
  amazdGrey4: '#BCBECF',
  amazdGrey5: '#D5DAE5',
  amazdGrey6: '#F3F4FC',
  amazdAccent1: '#FFB13F',
  grey10: '#FBFBFE',
  grey20: '#F3F4FC',
  grey30: '#E7E9F9',
  grey40: '#D7D9E7',
  grey50: '#BABDCE',
  grey60: '#A6A9BF',
  grey70: '#8A8DA3',
  grey80: '#727488',
  grey90: '#474854',
  black: '#1C1C21',
  red05: '#FFF2F7',
  red10: '#FFE6F0',
  red20: '#FFCCE0',
  red40: '#FFB3D1',
  red100: '#FF0066',
  red120: '#CC0052',
  orange05: '#FFFBF5',
  orange10: '#FFF7EC',
  orange40: '#FFE8C5',
  orange100: '#FFB13F',
  orange120: '#CC8E32',
  orange130: '#CC8E32',
  green05: '#F6FCF9',
  green10: '#EEF9F3',
  green20: '#DDF2E7',
  green40: '#CCECDB',
  green100: '#56C087',
  green120: '#459A6C',
  white: '#FFFFFF',
  blue05: '#F2FBFF',
  blue10: '#D3E5FF',
  blue40: '#99E0FF',
  blue100: '#01B2FE',
  blue120: '#004D94',
};
