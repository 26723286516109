import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ChatIconWidget: React.FC<any> = ({ color, ...rest }: any): React.ReactElement => (
  <SvgIcon viewBox="0 0 19 18" fill="none" {...rest}>
    <path
      d="M9.6669 15.7432H4.03987C3.90909 15.7433 3.7801 15.7129 3.66308 15.6545C3.54606 15.5961 3.44421 15.5113 3.36558 15.4068C3.28694 15.3023 3.23367 15.181 3.20997 15.0524C3.18627 14.9238 3.19279 14.7914 3.22902 14.6658L3.86943 12.4534L3.86605 12.4551C1.73472 8.87421 3.30411 4.11794 7.44865 2.63208C9.97149 1.72757 12.8774 2.46502 14.6619 4.46304C15.5416 5.43047 16.1213 6.63265 16.3304 7.9234C16.5395 9.21414 16.369 10.5378 15.8398 11.7335C15.3106 12.9292 14.4453 13.9454 13.3493 14.6584C12.2532 15.3715 10.9736 15.7507 9.66605 15.75"
      stroke={color}
      fill={'none'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChatIconWidget;
