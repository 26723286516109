import React, { createContext, useContext, useRef } from 'react';

import { ApolloProvider } from '../apollo';
import { isServer } from './helpers';

export type AppEnv = {
  BACKEND_V2_URL?: string;
  STREAM_API_KEY?: string;
  NEXT_PUBLIC_STREAM_API_KEY?: string;
  APP_ENV?: string;
  APP_VERSION?: string;
  DEEP_LINK_URL?: string;
  FIREBASE_API_KEY?: string;
  FIREBASE_ANDROID_APP_PACKAGE?: string;
  FIREBASE_IOS_APP_PACKAGE?: string;
  IOS_APP_STORE_ID?: string;
  FIREBASE_AUTH_DOMAIN?: string;
  FIREBASE_PROJECT_ID?: string;
  FIREBASE_WEB_PUSH_CERT_KEY?: string;
  FIREBASE_MESSAGING_SENDER_ID?: string;
  FIREBASE_APP_ID?: string;
  FIREBASE_MEASUREMENT_ID?: string;
  FIREBASE_AUTH_EMULATOR_HOST?: string;
  AMPLITUDE_API_KEY?: string;
};

export const AppConfigContext = createContext<{
  env: AppEnv;
}>({ env: {} });

export function AppConfigProvider({ children, env }: { children: React.ReactNode; env: AppEnv }) {
  const envRef = useRef<AppEnv>({});
  if (env) {
    // In next.js `getInitialProps` will run on client as well, in that case `env` will be undefined
    // save only env from server side.
    envRef.current = env;

    if (!isServer()) (window as any).AMAZD_APP_VERSION = env.APP_VERSION;
  }

  return (
    <AppConfigContext.Provider value={{ env: envRef.current }}>
      <ApolloProvider>{children}</ApolloProvider>
    </AppConfigContext.Provider>
  );
}

export function useAppConfig() {
  return useContext(AppConfigContext);
}
