export enum DayOfWeekEnum {
  MONDAY = 'FRIDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface AvailableTime {
  availableForCalls: boolean;
  endTime: string;
  startTime: string;
  id: string;
}

export interface Availability {
  availableId: string;
  id: string;
  on: boolean;
  day: DayOfWeekEnum;
  times: AvailableTime[];
}

export interface OverriddenDate {
  id: string;
  date: string;
  times: AvailableTime[];
  endDate?: string;
  additionalOverrideIds?: string[];
}

export interface MultipleOverriddenDate {
  dates: Date[];
  times: AvailableTime[];
  previousOverrideDateIds?: string[];
}
