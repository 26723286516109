import { UserRoleEnum } from '@amazd/common/types';

export enum Permission {
  CHAT = 'CHAT',
  CALLS = 'CALLS',
  REASSIGN = 'REASSIGN',
  COMPANY_CALLS = 'COMPANY_CALLS',
  COMPANY_AMAZDS = 'COMPANY_AMAZDS',
  SEE_TEAM = 'SEE_TEAM',
  INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
  CHANGE_TEAM_ROLE = 'CHANGE_TEAM_ROLE',
  CHANGE_VISIBILITY = 'CHANGE_VISIBILITY',
  MANAGE_INVITES = 'MANAGE_INVITES',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  MANAGE_ALL_USERS = 'ALL_USERS',
  MANAGE_ALL_COMPANIES = 'MANAGE_ALL_COMPANIES',
  /**
   * Order overview / success tab
   */
  MY_ORDERS = 'MY_ORDERS',
  COMPANY_ORDERS = 'COMPANY_ORDERS',
}

const permissionsByRole: Record<UserRoleEnum, Permission[]> = {
  [UserRoleEnum.USER]: [Permission.CHAT, Permission.CALLS, Permission.MY_ORDERS],
  [UserRoleEnum.MANAGER]: [
    Permission.CHAT,
    Permission.CALLS,
    Permission.REASSIGN,
    Permission.COMPANY_CALLS,
    Permission.COMPANY_AMAZDS,
    Permission.SEE_TEAM,
    Permission.MY_ORDERS,
    Permission.COMPANY_ORDERS,
  ],
  [UserRoleEnum.OWNER]: [
    Permission.CHAT,
    Permission.CALLS,
    Permission.REASSIGN,
    Permission.COMPANY_CALLS,
    Permission.COMPANY_AMAZDS,
    Permission.SEE_TEAM,
    Permission.CHANGE_TEAM_ROLE,
    Permission.MANAGE_INVITES,
    Permission.INVITE_TEAM_MEMBER,
    Permission.COMPANY_SETTINGS,
    Permission.MY_ORDERS,
    Permission.COMPANY_ORDERS,
    Permission.CHANGE_VISIBILITY,
  ],
  [UserRoleEnum.ADMIN]: [
    Permission.CHAT,
    Permission.CALLS,
    Permission.MANAGE_ALL_USERS,
    Permission.MANAGE_ALL_COMPANIES,
    Permission.MY_ORDERS,
    Permission.COMPANY_ORDERS,
  ],
  [UserRoleEnum.ROOT]: [
    Permission.CHAT,
    Permission.CALLS,
    Permission.MANAGE_ALL_USERS,
    Permission.MANAGE_ALL_COMPANIES,
    Permission.MY_ORDERS,
    Permission.COMPANY_ORDERS,
  ],
};

/**
 * Checks if a user with role has permission
 *
 * Add permissions if new priveleged functionality appears
 */
export const hasAccessTo = (role: UserRoleEnum | undefined, permission: Permission): boolean => {
  if (!role) {
    return false;
  }
  return permissionsByRole[role].includes(permission);
};
