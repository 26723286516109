import { colors } from '../../../../static';

const BackgroundGradient = ({ color }: { color?: string }) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '352px',
        height: '232px',
        left: 'calc(50% - 352px/2)',
        top: 0,
        background: '#321245',
        zIndex: -1,
        borderRadius: 18,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 'calc(50% - 352px/2)',
          top: 'calc(50% - 232px/2)',
          background: color || colors.amazdPurple,
          filter: 'blur(120px)', // This blur causes significant performance degradation on safari browses only. Below transform fixes it.
          transform: 'translate3d(0, 0, 0)', // https://graffino.com/til/raw/CjT2jrcLHP-how-to-fix-filter-blur-performance-issue-in-safari
        }}
      ></div>
    </div>
  );
};

export default BackgroundGradient;
