import React from 'react';

import Lottie from '@amazd/common/components/Lottie';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Box, Dialog, IconButton, Typography, Button, useMediaQuery } from '@mui/material';

import { CloseIcon, DeclineCallIcon, JoinCallIcon } from '../../../icons';
import { truncateText } from '../../../utils';
import ThreeRings from '../animations/ThreeRings.json';
import TwoRings from '../animations/TwoRings.json';
import Avatar from '../components/Avatar';
import { getNameInitials } from '../helpers/';
import { CallActionTypeEnum, CallEvent } from '../types';
import BackgroundGradient from './BackgroundGradient';
import useStyles from './styles';

interface Props {
  isOpen: boolean;
  handleClose: (event?: any, reason?: any) => void;
  isWidget?: boolean;
  call: CallEvent | null;
  handleAccept: () => void;
  handleDecline: () => void;
}

export default function RingingModal({ isOpen, handleClose, isWidget, call, handleAccept, handleDecline }: Props) {
  const smallWidgetQuery = useMediaQuery('(max-width:376px');

  const isSmallWidget = isWidget && smallWidgetQuery;

  const { t } = useTranslation();

  const { classes } = useStyles({ isWidget: !!isWidget, isSmallWidget: !!isSmallWidget } as any);

  if (!call || !isOpen) return null;

  const { user, type } = call;

  const fullName = `${user.firstName} ${user.lastName}`;

  const otherPartiesInitials = getNameInitials(user.firstName, user.lastName);

  const getLottieOptions = (animationData: any) => {
    return {
      loop: true,
      autoplay: true,
      animationData,
    };
  };

  return (
    <Dialog
      data-testid="ringing-modal"
      disableEscapeKeyDown
      className={classes.root}
      fullScreen={isWidget}
      open={isOpen}
      onClose={handleClose}
    >
      <audio src="https://storage.googleapis.com/amazd-public/assets/sound/video-call-sound.mp3" loop autoPlay />
      <BackgroundGradient isWidget={isWidget} />
      <IconButton
        onClick={() => handleClose()}
        className={classes.closeIcon}
        style={{
          display: isWidget ? 'none' : 'flex',
        }}
      >
        <CloseIcon style={{ fontSize: 23 }} />
      </IconButton>
      <Box className={classes.container}>
        <Box>
          <Box className={classes.headerWrapper}>
            {type === CallActionTypeEnum.APPOINTMENT ? (
              <Typography className={classes.headerTextTruncate}>
                {t('rm-scheduled-call-1')} <br /> {t('rm-scheduled-call-2')}
              </Typography>
            ) : (
              <Typography component="span" className={classes.headerText}>
                {truncateText(user.firstName, 24)} {user.firstName.length >= 24 && <br />}
                {t('rm-instant-call-1')} <br /> {t('rm-instant-call-2')}
              </Typography>
            )}
          </Box>
          <Box className={classes.bottomFlexWrapper}>
            <Box className={classes.avatarWrapper}>
              <Avatar initials={otherPartiesInitials} isWidget={isWidget} src={user.avatar as string} />
              <Lottie
                isClickToPauseDisabled
                options={getLottieOptions(user.avatar ? ThreeRings : TwoRings)}
                height={250}
                width={250}
                style={{ position: 'absolute', margin: '0' }}
              />
            </Box>
            <Box className={classes.shopperNameWrapper}>
              <Typography className={isWidget ? classes.shopperNameClamp : classes.shopperName}>{fullName}</Typography>
              {isWidget && user?.company && (
                <Typography className={classes.expertHandle}>@{user?.company?.handle}</Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box mt={isWidget ? '0' : '-32px'}>
          <Button
            data-testid="ringing-modal-accept-call-button"
            onClick={handleAccept}
            startIcon={<JoinCallIcon style={{ fontSize: 24, marginRight: 5.96, marginLeft: 4 }} />}
            className={classes.actionButton}
          >
            <Typography className={classes.actionButtonText}>{t('rm-join-action')}</Typography>
          </Button>
          {isWidget && (
            <Button
              data-testid="ringing-modal-decline-call-button"
              onClick={handleDecline}
              startIcon={<DeclineCallIcon style={{ fontSize: 24, marginRight: 2.46 }} />}
              className={classes.declineButton}
            >
              {t('rm-decline-action')}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
