import React from 'react';

const ColoredTeamIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7925 9.52319C21.5825 10.3132 21.5825 11.5941 20.7925 12.3841C20.0025 13.1742 18.7216 13.1742 17.9315 12.3841C17.1415 11.5941 17.1415 10.3132 17.9315 9.52319C18.7216 8.73316 20.0025 8.73316 20.7925 9.52319"
        stroke="#FFB13F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2026 5.91236C15.4191 7.12884 15.4191 9.10115 14.2026 10.3176C12.9861 11.5341 11.0138 11.5341 9.79731 10.3176C8.58083 9.10116 8.58083 7.12885 9.79731 5.91236C11.0138 4.69588 12.9861 4.69588 14.2026 5.91236"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06849 9.52319C6.85853 10.3132 6.85853 11.5941 6.06849 12.3841C5.27846 13.1742 3.99757 13.1742 3.20754 12.3841C2.41751 11.5941 2.41751 10.3132 3.20754 9.52319C3.99758 8.73316 5.27846 8.73316 6.06849 9.52319"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19.0003V17.9043C23 16.5233 21.881 15.4043 20.5 15.4043H19.699"
        stroke="#FF0066"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.0003V17.9043C1 16.5233 2.119 15.4043 3.5 15.4043H4.301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.339 19.0004V17.3994C17.339 15.4664 15.772 13.8994 13.839 13.8994H10.16C8.227 13.8994 6.66 15.4664 6.66 17.3994V19.0004"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ColoredTeamIcon;
