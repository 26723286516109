/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

// Amazd
export const REASSIGN_AMAZD = 'REASSIGN_AMAZD';
export const REASSIGN_AMAZD_SUCCESS = 'REASSIGN_AMAZD_SUCCESS';
export const REASSIGN_AMAZD_FAIL = 'REASSIGN_AMAZD_FAIL';

// Booking
export const GET_AVAILABLE_SLOTS = 'GET_AVAILABLE_SLOTS';
export const GET_AVAILABLE_SLOTS_FAIL = 'GET_AVAILABLE_SLOTS_FAIL';
export const GET_AVAILABLE_SLOTS_SUCCESS = 'GET_AVAILABLE_SLOTS_SUCCESS';

// Call
export const CREATE_CALL_ROOM = 'CREATE_CALL_ROOM';
export const CREATE_CALL_ROOM_SUCCESS = 'CREATE_CALL_ROOM_SUCCESS';
export const CREATE_CALL_ROOM_FAIL = 'CREATE_CALL_ROOM_FAIL';

// Notifications
export const SUBSCRIBE_TO_NOTIFICATIONS = 'SUBSCRIBE_TO_NOTIFICATIONS';
export const INIT_NOTIFICATIONS_SUBSCRIPTION = 'INIT_NOTIFICATIONS_SUBSCRIPTION';
export const UNSUBSCRIBE_FROM_NOTIFICATIONS = 'UNSUBSCRIBE_FROM_NOTIFICATIONS';
export const ON_NOTIFICATION_RECEIVED = 'ON_NOTIFICATION_RECEIVED';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

// Appointments
export const GET_MY_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_MY_APPOINTMENTS_FAIL = 'GET_APPOINTMENTS_FAIL';
export const GET_MY_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';

// Realtime events
export const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';
export const ON_NETWORK_RECONNECTED = 'ON_NETWORK_RECONNECTED';
export const ON_WSS_RECONNECTED = 'ON_WSS_RECONNECTED';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAIL = 'UPDATE_NOTIFICATIONS_FAIL';

// Profile/user
export const GET_MY_USER = 'GET_MY_USER';
export const GET_MY_USER_SUCCESS = 'GET_MY_USER_SUCCESS';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS';
export const UPDATE_USER_AVATAR_FAIL = 'UPDATE_USER_AVATAR_FAIL';
export const UPDATE_USER_TYPE_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_TYPE_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const UPDATE_USER_TYPE_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const ENQUEUE_UPDATE_USER = 'ENQUEUE_UPDATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

// Calls
export const GET_MY_CALLS = 'GET_MY_CALLS';
export const GET_MY_CALLS_FAIL = 'GET_MY_CALLS_FAIL';
export const GET_MY_CALLS_SUCCESS = 'GET_MY_CALLS_SUCCESS';
