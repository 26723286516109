import React from 'react';

import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    transition: 'height 0.5s',
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '112px',
    '&.presenting': {
      display: 'flex',
      paddingBottom: '120px',
      paddingTop: '96px',
    },
    '&.minimized': {
      maxHeight: 'calc(100% - 0px)',
      paddingBottom: '0px',
      paddingTop: '68px',
    },
    '&.isWidgetScreen': {
      flexDirection: 'column',
    },
    '&.isWidgetScreen.presenting': {
      paddingBottom: '80px',
      paddingTop: '80px',
    },
    '&.fullscreen': {
      paddingTop: '80px',
    },
  },
}));

const ParticipantsLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { root } = useStyles();
  const { minimized, presenting, isWidgetScreen, fullscreen } = useVideoCallContext();

  const classes = clsx(root, { minimized, isWidgetScreen, presenting, fullscreen });

  return <div className={classes}>{children}</div>;
};

export default ParticipantsLayout;
