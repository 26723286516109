import 'firebase/auth';

import { getClient } from '@amazd/common/apollo';
import { trackLogin } from '@amazd/common/utils/analytics';
import { getGraphQlError } from '@amazd/common/utils/graphql';
import firebase from 'firebase/app';

import { loginByFirebaseMutation, loginBySocialNetworksMutation } from '../graphql';
import { onGetMyUserSuccess, onLoginFail, onLoginSuccess } from '../helpers';
import { FirebaseConfig, LoginWithFirebaseArgs } from '../types';

const initFirebase = (firebaseConfig: FirebaseConfig) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().useDeviceLanguage();

    const useEmulator = !!firebaseConfig.authEmulatorHost;
    if (useEmulator) {
      firebase.auth().useEmulator('http://localhost:9099');
    }
  }
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

const providers: { [key: string]: any } = {
  GOOGLE: googleProvider,
  APPLE: appleProvider,
  FACEBOOK: facebookProvider,
};

export type SupportedProviders = keyof typeof providers;

const getIdToken = async () => {
  return (await firebase.auth().currentUser?.getIdToken()) || '';
};

export const authenticateWithFirebase = async (
  providerKey: SupportedProviders,
  config: FirebaseConfig,
): Promise<{ token: string }> => {
  initFirebase(config);

  const provider = providers[providerKey];
  if (!provider) {
    throw new Error(`Login provider ${providerKey} is not supported.`);
  }
  await firebase.auth().signInWithPopup(provider);

  const idToken = await getIdToken();

  if (!idToken) {
    throw new Error('An unexpected authorization error occurred!');
  }

  return { token: idToken };
};

export async function loginUserWithFirebase(args: LoginWithFirebaseArgs) {
  const { provider, locale, config, fcmRegistrationToken } = args;

  try {
    let loginResult;

    try {
      const authResponse = await authenticateWithFirebase(provider, config);

      const res = await getClient().mutate({
        mutation: loginByFirebaseMutation,
        variables: {
          data: {
            token: authResponse?.token,
            locale,
            fcmRegistrationToken,
          },
        },
      });

      loginResult = res.data.loginByFirebase;
    } catch (error) {
      const { code, message, credential } = error as any;
      if (code === 'auth/account-exists-with-different-credential') {
        const res = await getClient().mutate({
          mutation: loginBySocialNetworksMutation,
          variables: {
            data: {
              provider,
              token: credential.accessToken,
            },
          },
        });

        loginResult = res.data.loginBySocialNetworks;
      } else {
        throw new Error(message);
      }
    }

    if (!loginResult) {
      throw new Error('Unable to authorize with social login provider. Something unexpected happened.');
    }

    const { user, userTypes } = loginResult;

    onGetMyUserSuccess({
      ...user,
      userTypes: [...userTypes],
    });
    onLoginSuccess(loginResult);

    trackLogin(provider as 'APPLE' | 'FACEBOOK' | 'GOOGLE');
  } catch (error: any) {
    const message = error.graphQLErrors ? getGraphQlError(error).message : error.message;
    onLoginFail(message);
    reportError(error);
  }
}
