import { all, put, spawn, takeEvery } from 'redux-saga/effects';

import { getClient } from '../../../apollo';
import { getGraphQlError, reportError } from '../../../utils';
import { REASSIGN_AMAZD } from '../../actions';
import { reassignAmazdFail, reassignAmazdSuccess } from '../../actions/amazdDetailsActions';
import { reassignExpertMutation } from '../../graphql/mutations/amazdMutations';
import { Action } from '../../types';
import bookingSaga from './bookingSaga';

function* reassignAmazd(action: Action): any {
  try {
    const { amazdId, expertUserTypeId, description } = action.payload;
    const res = yield getClient().mutate({
      mutation: reassignExpertMutation,
      variables: {
        args: {
          amazdId,
          expertId: expertUserTypeId,
          description,
        },
      },
    });
    if (res?.data?.reassignExpertV2) {
      yield put(reassignAmazdSuccess(res?.data?.reassignExpertV2));
    }
  } catch (err: any) {
    yield put(reassignAmazdFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* reassignAmazdSaga() {
  yield takeEvery(REASSIGN_AMAZD, reassignAmazd);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([reassignAmazdSaga(), ...bookingSaga]);
}
