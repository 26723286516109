import { useEffect, useState } from 'react';

import type { LocalParticipant } from 'twilio-video';

const useMicToggle = (localParticipant?: LocalParticipant) => {
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (!localParticipant) {
      setMuted(false);
    }
  }, [localParticipant]);

  const toggleMic = () => {
    localParticipant?.audioTracks.forEach((publication) => {
      const track = publication?.track;
      if (track) {
        if (track.isEnabled) {
          track.disable();
          setMuted(true);
        } else {
          track.enable();
          setMuted(false);
        }
      }
    });
  };

  return {
    muted,
    toggleMic,
  };
};

export default useMicToggle;
