import React from 'react';

const CelebrateIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <svg {...props} width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8698 20.3548C15.7523 21.0048 16.1648 21.7123 16.8148 21.8298H17.1098C17.6998 21.8298 18.2298 21.4173 18.3497 20.8273C18.7046 19.1173 17.7597 17.6998 16.9922 16.6973C16.4622 15.9898 15.8722 15.2823 15.1623 14.7498C14.5723 14.2774 13.9224 13.9248 13.3324 13.6298C12.9774 13.4523 12.6824 13.2749 12.3874 13.0997C11.8573 12.7448 11.1475 12.1548 10.9724 11.2122C10.9124 10.9172 10.9724 10.6821 10.9724 10.6222C11.3273 10.0322 11.1498 9.26471 10.6174 8.91213C10.0274 8.55721 9.25996 8.67705 8.90738 9.26705C8.3773 10.0921 8.43492 11.1546 8.55246 11.687C8.84746 13.102 9.73246 14.4019 11.0899 15.227C11.5025 15.4621 11.8574 15.6995 12.2699 15.8769C12.8 16.112 13.2725 16.3494 13.6274 16.702C14.0999 17.1145 14.5723 17.7045 15.0425 18.2369C15.4573 18.8799 16.0473 19.705 15.8698 20.3549L15.8698 20.3548Z"
        fill="#7E05FE"
      />
      <path
        d="M29.7348 23.4219H29.9123C30.5622 23.3044 31.3273 23.2444 32.0948 22.8918C33.0973 22.4194 33.8048 21.6519 34.0423 20.7093C34.3373 19.5293 33.8648 18.5268 33.5122 17.6994C33.3946 17.4643 33.3347 17.2868 33.2172 17.0494C32.6272 15.5145 33.3946 13.5694 34.8696 12.8019C35.4597 12.5069 35.7547 11.7394 35.3997 11.0918C35.1047 10.5018 34.3373 10.2068 33.6897 10.5617C31.0946 11.8593 29.7947 15.1642 30.8572 17.9367C30.9747 18.2317 31.0923 18.4668 31.2121 18.7618C31.4472 19.2919 31.6846 19.7643 31.6247 20.0593C31.5647 20.2368 31.3297 20.4719 31.0946 20.5894C30.682 20.7669 30.1496 20.8245 29.6196 20.9443C28.9121 21.0619 28.4396 21.6519 28.5571 22.3594C28.5548 23.0093 29.0872 23.4219 29.7348 23.4219L29.7348 23.4219Z"
        fill="#7E05FE"
      />
      <path
        d="M47.5552 19.5299C46.9652 19.175 46.1977 19.3524 45.8451 19.9424C45.4326 20.5924 44.4876 20.8874 43.4851 21.24L43.0703 21.3575C40.2978 22.3024 37.7603 24.0125 35.7555 26.1374C33.9255 25.5474 31.6255 25.0174 28.6755 24.4274C27.3779 24.1923 26.1979 23.9549 25.0755 23.8374C24.308 21.0049 23.128 18.2923 21.5955 15.8724L21.5355 15.7548C20.9455 14.8099 20.2956 13.8074 20.4731 13.0399C20.6505 12.39 20.238 11.6824 19.5281 11.505C18.8782 11.3275 18.1707 11.74 17.9932 12.4499C17.5207 14.2798 18.5832 15.8724 19.3507 17.11L19.4106 17.2275C20.6505 19.175 21.5931 21.2976 22.3006 23.5399C16.223 22.9499 14.5132 24.07 14.2755 25.25V25.485V25.6625V25.7224C14.2755 25.9575 14.3354 26.135 14.4529 26.3724L22.5979 56.05L41.0655 31.4475C41.3005 31.2701 41.478 30.9751 41.5955 30.6801C41.5955 30.6202 41.6555 30.5026 41.6555 30.445C41.8329 29.5001 41.183 28.3201 38.3505 27.0825C39.943 25.6075 41.8306 24.4275 43.8956 23.7199L44.3081 23.6024C45.548 23.1899 47.0806 22.6575 47.9656 21.2424C48.3206 20.6501 48.1454 19.8826 47.5554 19.53L47.5552 19.5299ZM28.2026 26.9049C30.6225 27.3773 32.6875 27.9074 34.3374 28.3799C34.3973 28.7348 34.5149 29.0298 34.8099 29.2649C35.0449 29.4424 35.2823 29.5 35.5773 29.5C35.9323 29.5 36.2849 29.3225 36.5223 29.0874C37.1722 29.3225 37.7023 29.5599 38.1148 29.7374C36.2849 29.8549 32.8048 29.6775 27.85 28.7348C27.2001 28.6173 26.5525 28.4998 25.9625 28.3223C25.9025 27.6724 25.785 27.0248 25.7274 26.3748C26.4903 26.6099 27.3176 26.7275 28.2026 26.9049L28.2026 26.9049ZM23.0701 26.1374C23.1876 26.6675 23.2475 27.2575 23.3651 27.7899C20.8276 27.1399 19.0576 26.4324 17.9951 25.9024C19.0576 25.8424 20.7676 25.8424 23.0701 26.1374V26.1374ZM23.6601 50.4448L17.7601 28.5546C17.9951 28.6722 18.2325 28.7321 18.5275 28.8496C20.8276 29.7346 24.015 30.5597 27.3775 31.2096C30.7401 31.8596 33.9849 32.2122 36.4624 32.2721H36.9348H37.2298L23.6601 50.4448Z"
        fill="#7E05FE"
      />
      <path
        d="M35.7552 19.3525C35.9326 19.8249 36.4051 20.1199 36.9352 20.1199C37.1126 20.1199 37.2302 20.1199 37.4076 20.06C39.2952 19.3525 40.8877 17.995 41.8926 16.225C42.6001 15.045 42.9551 13.8051 43.3676 12.5675C43.6027 11.8 43.8401 11.0925 44.0751 10.4426C44.6052 9.08508 45.7852 7.55249 47.2602 7.49255C47.9677 7.49255 48.5001 6.90255 48.5001 6.19502C48.5001 5.48749 47.9101 4.95508 47.2026 4.95508C44.5476 5.015 42.6601 7.31508 41.7751 9.44002C41.4201 10.2075 41.1851 11.0326 40.95 11.8C40.5951 12.9201 40.3001 13.9826 39.7101 14.9275C39.0025 16.1674 37.8225 17.17 36.4651 17.7C35.8128 17.995 35.4602 18.7025 35.7552 19.3525L35.7552 19.3525Z"
        fill="#7E05FE"
      />
      <path
        d="M26.0202 8.96739C26.7877 8.96739 27.3777 8.37739 27.3777 7.60991C27.3777 6.84244 26.7877 6.25244 26.0202 6.25244C25.2527 6.25244 24.6627 6.84244 24.6627 7.60991C24.6627 8.37739 25.3104 8.96739 26.0202 8.96739Z"
        fill="#7E05FE"
      />
      <path
        d="M35.3398 5.3698C35.9298 5.84226 36.7549 5.72472 37.2873 5.19234C37.7598 4.60234 37.6422 3.77728 37.1099 3.24487C36.5199 2.77241 35.6948 2.88995 35.1624 3.42233C34.6323 4.06997 34.7498 4.89733 35.3398 5.3698Z"
        fill="#7E05FE"
      />
      <path
        d="M15.6952 7.02C16.4627 6.96008 17.0527 6.37006 16.9927 5.60495C16.9328 4.83747 16.3428 4.24747 15.5777 4.30742C14.8102 4.36734 14.2202 4.95736 14.2801 5.72247C14.2778 6.48994 14.9278 7.07994 15.6952 7.02Z"
        fill="#7E05FE"
      />
      <path
        d="M49.1476 12.6851C48.3801 12.745 47.7901 13.3926 47.8501 14.1601C47.91 14.9275 48.5 15.5175 49.2651 15.4576C50.0326 15.3977 50.6226 14.8076 50.5627 14.0425C50.505 13.2751 49.8551 12.6851 49.1476 12.6851Z"
        fill="#7E05FE"
      />
    </svg>
  );
};

export default CelebrateIcon;
