import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ArrowBackIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <SvgIcon viewBox="-1.0548 -2.2024 20.13 20.13">
      <path d="M0 8.08984C0 8.33398 0.107422 8.55859 0.302734 8.74414L6.78711 15.2188C6.98242 15.4043 7.1875 15.4922 7.42188 15.4922C7.90039 15.4922 8.28125 15.1406 8.28125 14.6523C8.28125 14.418 8.19336 14.1836 8.03711 14.0371L5.84961 11.8105L2.55859 8.8125L4.92188 8.95898H17.2754C17.7832 8.95898 18.1348 8.59766 18.1348 8.08984C18.1348 7.58203 17.7832 7.2207 17.2754 7.2207H4.92188L2.56836 7.36719L5.84961 4.36914L8.03711 2.14258C8.19336 1.98633 8.28125 1.76172 8.28125 1.52734C8.28125 1.03906 7.90039 0.6875 7.42188 0.6875C7.1875 0.6875 6.98242 0.765625 6.76758 0.980469L0.302734 7.43555C0.107422 7.62109 0 7.8457 0 8.08984Z" />
    </SvgIcon>
  );
};

export default ArrowBackIcon;
