import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ArrowLeftIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-3.5184 -0.6836 18.95 18.95">
      <path d="M0.25 8.79297C0.25 9.03711 0.337891 9.25195 0.523438 9.4375L8.26758 17.0059C8.43359 17.1816 8.64844 17.2695 8.90234 17.2695C9.41016 17.2695 9.80078 16.8887 9.80078 16.3809C9.80078 16.127 9.69336 15.9121 9.53711 15.7461L2.42773 8.79297L9.53711 1.83984C9.69336 1.67383 9.80078 1.44922 9.80078 1.20508C9.80078 0.697266 9.41016 0.316406 8.90234 0.316406C8.64844 0.316406 8.43359 0.404297 8.26758 0.570312L0.523438 8.14844C0.337891 8.32422 0.25 8.54883 0.25 8.79297Z" />
    </SvgIcon>
  );
};

export default ArrowLeftIcon;
