import { colors } from '../../../static';

const BackgroundGradient = ({ color }: { color?: string }) => {
  return (
    <div
      data-testid="video-call-background-gradient"
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '1118px',
          height: '1118px',
          left: 'calc(50% - 1118px/2 - 3px)',
          top: '-443px',
          background: color || colors.amazdPurple,
          opacity: '0.5',
          filter: 'blur(600px)', // This blur causes significant performance degradation on safari browses only. Below transform fixes it.
          transform: 'translate3d(0, 0, 0)', // https://graffino.com/til/raw/CjT2jrcLHP-how-to-fix-filter-blur-performance-issue-in-safari
        }}
      ></div>
    </div>
  );
};

export default BackgroundGradient;
