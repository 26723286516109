import { gql } from '@apollo/client';

import { AMAZD_FIELDS } from '../../graphql/fragments/amazdFields';

export const reassignExpertMutation = gql`
  ${AMAZD_FIELDS}
  mutation ($args: ReassinExpertV2Dto!) {
    reassignExpertV2(args: $args) {
      ...AmazdFields
    }
  }
`;
