import { useAuthInfo } from '@amazd/common/hooks/auth';
import { gql, useMutation } from '@apollo/client';

import { CallActionTypeEnum } from '../types';

const useSendNewCallEvent = () => {
  const { ownUser } = useAuthInfo();

  const SEND_NEW_CALL_ACTION = gql`
    mutation sendNewCallEvent($event: CallEventInput!) {
      sendNewCallEvent(event: $event)
    }
  `;

  const [sendNewCallEvent] = useMutation(SEND_NEW_CALL_ACTION);

  const sendCallEvent = async (amazdId: string, type: CallActionTypeEnum, updateQueue?: VoidFunction) => {
    await sendNewCallEvent({
      variables: {
        event: {
          type,
          triggeredByUserId: ownUser?.id,
          amazdId,
        },
      },
    })
      // events might throw errors but those are not really app's errors so used console.warn
      .catch((error) => console.warn('Call Event error: ', error));

    if (updateQueue) {
      updateQueue();
    }
  };

  return {
    sendCallEvent,
  };
};

export default useSendNewCallEvent;
