import { User, UserType } from '@amazd/common/types';

export enum CallActionTypeEnum {
  CALLING = 'CALLING',
  STOPPED_CALLING = 'STOPPED_CALLING',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  APPOINTMENT = 'APPOINTMENT',
  DID_NOT_PICKUP = 'DID_NOT_PICKUP',
}

export interface CallEvent {
  id?: string;
  amazdId: string;
  type: CallActionTypeEnum;
  user: User & UserType;
}

export interface CallEventInput {
  amazdId: string;
  type: CallActionTypeEnum;
  triggeredByUserId: string;
}
