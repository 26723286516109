import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MailIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 4.5H3A1.5 1.5 0 001.5 6v12A1.5 1.5 0 003 19.5h18a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0021 4.5zM19.35 6L12 11.085 4.65 6h14.7zM3 18V6.683l8.572 5.932a.75.75 0 00.856 0L21 6.682V18H3z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default MailIcon;
