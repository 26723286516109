import { colors } from '@amazd/common/static';
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: CustomTheme) => ({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '100vh',
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
  },
  toolbar: {
    borderBottom: '0.5px solid #dedbdb',
  },
  title: {
    flexGrow: 1,
    marginLeft: -24,
  },
  closeButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: colors.amazdGrey6,
    margin: '0 auto',
    height: 'fit-content',
    padding: 0,
  },
  body: {
    overflowY: 'auto',
    height: 'calc(100% - 150px)',
  },
}));
