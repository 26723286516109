import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { isWidget, isMinimized }: any) => ({
  avatar: {
    width: '100%',
    height: '100%',
    aspectRatio: '16 / 9',
    borderRadius: 1000,
  },
  avatarName: {
    fontSize: isMinimized ? 20 : 32,
    fontWeight: isWidget ? 400 : 500,
    lineHeight: 1.2,
  },
}));
