import { useEffect, useState } from 'react';

import type { LocalParticipant } from 'twilio-video';

import { MediaSourceEnum } from '../contexts/videoCallContext';

const useCameraToggle = (createNewVideoCamTrack: any, localParticipant?: LocalParticipant) => {
  const [videoOff, setVideoOff] = useState(true);

  useEffect(() => {
    if (!localParticipant) {
      setVideoOff(true);
    }
  }, [localParticipant]);

  const checkIfVideoTrackIsCreated = () => {
    let found = false;

    if (localParticipant) {
      const videoTracks = localParticipant.videoTracks;
      videoTracks.forEach((videoTrack) => {
        if (videoTrack.trackName === MediaSourceEnum.CAMERA) {
          found = true;
        }
      });
    }
    return found;
  };

  const toggleCamera = async () => {
    const isVideoTrackCreated = checkIfVideoTrackIsCreated();

    if (!isVideoTrackCreated) {
      const newTrack = await createNewVideoCamTrack();
      if (localParticipant) {
        await localParticipant.publishTrack(newTrack);
        setVideoOff(false);
      }
      return;
    }

    localParticipant?.videoTracks.forEach((publication) => {
      if (publication.trackName !== MediaSourceEnum.CAMERA) return;

      const track = publication?.track;

      if (track) {
        if (track.isEnabled) {
          track.disable();
          track.stop();
          setVideoOff(true);
        } else {
          track.enable();
          track.restart();
          setVideoOff(false);
        }
      }
    });
  };

  return {
    toggleCamera,
    videoOff,
  };
};

export default useCameraToggle;
