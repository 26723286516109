import React from 'react';

const CancelIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="43" cy="43" r="8" fill="#FF0066" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.6034 21.3966L21.3967 34.6033L34.6034 21.3966Z" fill="white" />
      <path
        d="M34.6034 21.3966L21.3967 34.6033"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6034 34.6033L21.3967 21.3966"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 49V49C16.401 49 7 39.599 7 28V28C7 16.401 16.401 7 28 7V7C39.599 7 49 16.401 49 28V28C49 39.599 39.599 49 28 49Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelIcon;
