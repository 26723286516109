import { gql } from '@apollo/client';

export const refreshTokenMutation = gql`
  mutation {
    tokenRefresh {
      accessToken
    }
  }
`;

export const registerMutation = gql`
  mutation ($input: RegisterDto!) {
    register(input: $input) {
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
        timezoneName
        role
      }
      userTypes {
        id
        avatar
        isExpert
        locale
      }
    }
  }
`;

export const signUpMutation = gql`
  mutation ($args: SignUpDto!) {
    signUp(args: $args) {
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
        timezoneName
      }
      userTypes {
        id
        avatar
        isExpert
        locale
      }
    }
  }
`;

export const loginByFirebaseMutation = gql`
  mutation ($data: LoginByFirebaseDto!) {
    loginByFirebase(data: $data) {
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
        timezoneName
        role
        phone
      }
      userTypes {
        id
        isExpert
        avatar
        handle
        locale
        company {
          id
          handle
          uses_informal_language
        }
      }
    }
  }
`;

export const loginBySocialNetworksMutation = gql`
  mutation ($data: LoginBySocialNetworksDto!) {
    loginBySocialNetworks(data: $data) {
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
        timezoneName
        role
      }
      userTypes {
        id
        isExpert
        avatar
        handle
        locale
        company {
          id
          handle
          uses_informal_language
        }
      }
    }
  }
`;

export const getMyUserQuery = gql`
  query whoAmI($timezoneName: String) {
    whoAmI(timezoneName: $timezoneName) {
      id
      email
      firstName
      lastName
      timezoneName
      spokenLanguages
      role
      internal
      phone
      userTypes {
        id
        isExpert
        description
        company {
          id
          name
          handle
          description
          uses_informal_language
        }
        avatar
        handle
        locale
      }
    }
  }
`;

export const userUpdateMutation = gql`
  mutation ($id: String!, $data: UserUpdateArgs!) {
    userUpdate(id: $id, data: $data)
  }
`;
