import { useTranslation } from '@amazd/common/utils/i18n';
import { Button, IconButton, styled } from '@mui/material';

import { MaximizeIcon } from '../../../icons';
import { colors } from '../../../static';
import { useVideoCallContext } from '../contexts/videoCallContext';

const Wrapper = styled('div')({
  '& > MuiIconButton-root': {
    height: 48,
    width: 48,
  },
  '& .MuiIconButton-root': {
    color: 'white',
    backgroundColor: '#ababab57',
    '&:hover': {
      opacity: '0.7',
    },
    transition: 'opacity .5s ease',
  },
  '& .MuiIconButton-colorPrimary': {
    backgroundColor: colors.red100,
  },
  '& .MuiIconButton-colorSecondary': {
    color: 'black',
    backgroundColor: 'white',
  },
  '& .MuiButtonBase-root.Mui-disabled': {
    opacity: '0.4',
  },
});

const TextButton = styled(Button)({
  color: 'white',
  fontSize: '18px',
  filter: 'drop-shadow(0px 0px 24px black)',
});

const TopLeftControls = () => {
  const { t } = useTranslation();
  const { isWidgetScreen, minimized, minimizeToggle } = useVideoCallContext();

  return (
    <Wrapper>
      {!isWidgetScreen &&
        (minimized ? (
          <IconButton onClick={minimizeToggle}>
            <MaximizeIcon />
          </IconButton>
        ) : (
          <TextButton variant="text" onClick={minimizeToggle}>
            <ArrowVector />
            {t('video-call-return-to-chat-button')}
          </TextButton>
        ))}
    </Wrapper>
  );
};

export default TopLeftControls;

const ArrowVector = () => {
  return (
    <svg
      style={{ marginRight: 8 }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12.75C19.9142 12.75 20.25 12.4142 20.25 12C20.25 11.5858 19.9142 11.25 19.5 11.25L19.5 12.75ZM4.5 12L3.96967 11.4697C3.67678 11.7626 3.67678 12.2374 3.96967 12.5303L4.5 12ZM8.03033 9.53033C8.32322 9.23744 8.32322 8.76256 8.03033 8.46967C7.73744 8.17678 7.26256 8.17678 6.96967 8.46967L8.03033 9.53033ZM6.96967 15.5303C7.26256 15.8232 7.73744 15.8232 8.03033 15.5303C8.32322 15.2374 8.32322 14.7626 8.03033 14.4697L6.96967 15.5303ZM19.5 11.25L4.5 11.25L4.5 12.75L19.5 12.75L19.5 11.25ZM6.96967 8.46967L3.96967 11.4697L5.03033 12.5303L8.03033 9.53033L6.96967 8.46967ZM3.96967 12.5303L6.96967 15.5303L8.03033 14.4697L5.03033 11.4697L3.96967 12.5303Z"
        fill="white"
      />
    </svg>
  );
};
