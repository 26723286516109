const BackgroundGradient = ({ isWidget }: { isWidget?: boolean }) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: isWidget ? '100%' : '554px',
        height: isWidget ? '100%' : '554px',
        left: `calc(50% - ${isWidget ? '100%/2' : '554px/2'})`,
        top: `calc(50% - ${isWidget ? '100%/2' : '554px/2'})`,
        background: isWidget ? '#1b1b1c' : '#110817',
        zIndex: -1,
      }}
    >
      <div
        style={{
          position: 'absolute',
          background: isWidget
            ? 'radial-gradient(circle, rgba(114,116,136,1) 6%, rgba(30,30,31,1) 100%)'
            : 'radial-gradient(circle, rgba(126,5,254,1) 6%, rgba(38,2,51,1) 100%)',
          width: '100%',
          height: '100%',
          left: `calc(50% - ${isWidget ? '100%/2' : '554px/2'})`,
          opacity: '1',
          filter: isWidget ? 'blur(130px)' : 'blur(150px)',
          transform: 'translate3d(0, 0, 0)', // https://graffino.com/til/raw/CjT2jrcLHP-how-to-fix-filter-blur-performance-issue-in-safari
        }}
      ></div>
    </div>
  );
};

export default BackgroundGradient;
