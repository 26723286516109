import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import MiddleControls from '../components/MiddleControls';
import TopLeftControls from '../components/TopLeftControls';
import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.drawer + 2,
    padding: '24px',
    '&.minimized': {
      padding: '16px',
    },
    '&.isWidgetScreen': {
      padding: '16px',
      paddingBottom: '24px',
    },
  },
}));

const ControlsLayout = () => {
  const { root } = useStyles();
  const { minimized, isWidgetScreen } = useVideoCallContext();

  const classes = clsx(root, { minimized, isWidgetScreen });

  return (
    <div className={classes}>
      <TopLeftControls />
      <MiddleControls />
    </div>
  );
};

export default ControlsLayout;
