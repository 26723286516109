let canvas: any;
export const getTextWidth = (text: string, font: string): number => {
  if (!canvas) {
    canvas = document.createElement('canvas');
  }
  const context: any = canvas.getContext('2d');
  context.font = font;
  const metrics = context?.measureText(text);
  return metrics.width;
};

export const truncateText = (str: string | undefined, maxChars = 100): string => {
  if (str && str.length > maxChars) {
    return `${str.slice(0, maxChars)}...`;
  }
  return str || '';
};
