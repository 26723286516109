import { useEffect, useState } from 'react';

import screenfull from 'screenfull';

const useFullscreenToggle = () => {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullscreen = async () => {
    if (!screenfull.isEnabled) {
      console.log('fullscreen is not supported');
      return;
    }

    await screenfull.toggle();
    setFullscreen(screenfull.isFullscreen);
  };

  useEffect(() => {
    const onResize = () => {
      const fullscreen = 1 >= window.outerHeight - window.innerHeight;
      setFullscreen(fullscreen);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    fullscreen,
    toggleFullscreen,
  };
};

export default useFullscreenToggle;
