import { useTranslation } from '@amazd/common/utils/i18n';
import { IconButton, Tooltip as DefaultTooltip } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { VideoMuteIcon, VideoIcon, MicMuteIcon, MicIcon, FullScreenIcon, CallEndIcon } from '../../../icons';
import ScreenShareIcon from '../../../icons/ShareScreenIcon';
import { colors } from '../../../static';
import { isMobile } from '../../../utils';
import { CallActionTypeEnum } from '../../RingingPopup/types';
import { useVideoCallContext } from '../contexts/videoCallContext';

const Tooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
  },
  arrow: {
    color: 'white',
  },
})(DefaultTooltip);

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
    '&.minimized': {
      padding: 0,
    },
    '&.isWidgetScreen': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      margin: '0px 6px',
      height: 48,
      width: 48,
      color: 'white',
      backgroundColor: '#ababab57',
      '&:hover': {
        opacity: '0.7',
      },
      transition: 'opacity .5s ease',
    },
    '& .MuiIconButton-colorPrimary': {
      backgroundColor: colors.red100,
    },
    '& .MuiIconButton-colorSecondary': {
      color: 'black',
      backgroundColor: 'white',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      opacity: '0.4',
    },
  },
});

const MiddleControls = () => {
  const { t } = useTranslation();
  const {
    connecting,
    devicePermissionDenied,
    minimized,
    localParticipant,
    presentingParticipant,
    muted,
    videoOff,
    noCamera,
    isWidgetScreen,
    fullscreen,
    endCall,
    toggleCamera,
    toggleMic,
    toggleScreenSharing,
    toggleFullscreen,
    updateCallQueue,
  } = useVideoCallContext();

  const handleEndCall = () => {
    endCall(CallActionTypeEnum.STOPPED_CALLING);
    updateCallQueue();
  };

  const classes = useStyles();
  const rootClasses = clsx(classes.root, { isWidgetScreen, minimized });

  const localPresenting = presentingParticipant && localParticipant === presentingParticipant;
  const screenShareButtonTooltip = !presentingParticipant
    ? t('video-call-start-screen-share-tooltip')
    : localPresenting
    ? t('video-call-stop-screen-share-tooltip')
    : t('video-call-cant-share-while-other-tooltip');

  const cameraButtonTooltip = noCamera
    ? t('video-call-no-camera-tooltip')
    : videoOff
    ? t('video-call-turn-on-camera-tooltip')
    : t('video-call-turn-off-camera-tooltip');
  const micButtonTooltip = muted ? t('video-call-turn-on-mic-tooltip') : t('video-call-turn-off-mic-tooltip');

  return (
    <div className={rootClasses}>
      <Tooltip arrow={true} title={cameraButtonTooltip} placement="top">
        <span>
          <IconButton
            data-testid="video-call-toggle-camera-button"
            color={videoOff ? 'secondary' : 'default'}
            onClick={toggleCamera}
            disabled={connecting || devicePermissionDenied || noCamera}
          >
            {videoOff ? <VideoMuteIcon /> : <VideoIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip arrow={true} title={micButtonTooltip}>
        <span>
          <IconButton
            data-testid="video-call-toggle-mic-button"
            color={muted ? 'secondary' : 'default'}
            onClick={toggleMic}
            disabled={connecting || devicePermissionDenied}
          >
            {muted ? <MicMuteIcon /> : <MicIcon />}
          </IconButton>
        </span>
      </Tooltip>
      {!isMobile() && (
        <Tooltip arrow={true} placement="top" title={screenShareButtonTooltip}>
          <span>
            <IconButton
              data-testid="video-call-toggle-screen-sharing-button"
              color={localPresenting ? 'secondary' : 'default'}
              onClick={toggleScreenSharing}
              disabled={(presentingParticipant && !localPresenting) || connecting || devicePermissionDenied}
            >
              <ScreenShareIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!minimized && !isMobile() && (
        <Tooltip arrow={true} placement="top" title="Full screen">
          <IconButton
            data-testid="video-call-toggle-fullscreen-button"
            color={fullscreen ? 'secondary' : 'default'}
            onClick={toggleFullscreen}
          >
            <FullScreenIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip arrow={true} placement="top" title="End call">
        <span>
          <IconButton data-testid="video-call-end-call-button" color="primary" onClick={handleEndCall}>
            <CallEndIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default MiddleControls;
