import { AppEnv } from '@amazd/common/utils/env';

// Since Next.js is inlining env vars into the code, there's a custom mapper to load env at run-time.
// It will be passed then from backend to frontend via `getInitialProps`
export const backendMapEnvToFrontend = (): AppEnv => {
  const env = eval('process.env');
  const envMapped = {
    APP_ENV: env.APP_ENV || process.env.APP_ENV,
    BACKEND_V2_URL: env.NEXT_PUBLIC_BACKEND_V2_URL || env.BACKEND_V2_URL || '-',
    STREAM_API_KEY: env.NEXT_PUBLIC_STREAM_API_KEY || env.STREAM_API_KEY,
    NEXT_PUBLIC_STREAM_API_KEY: env.NEXT_PUBLIC_STREAM_API_KEY || env.STREAM_API_KEY,
    APP_VERSION:
      process.env.APP_VERSION || process.env.NEXT_PUBLIC_APP_VERSION || env.APP_VERSION || env.NEXT_PUBLIC_APP_VERSION,
    DEEP_LINK_URL: env.NEXT_PUBLIC_DEEP_LINK_URL || env.DEEP_LINK_URL,
    IOS_APP_STORE_ID: env.NEXT_PUBLIC_IOS_APP_STORE_ID || env.IOS_APP_STORE_ID,
    FIREBASE_API_KEY: env.NEXT_PUBLIC_FIREBASE_API_KEY || env.FIREBASE_API_KEY,
    FIREBASE_ANDROID_APP_PACKAGE: env.NEXT_PUBLIC_FIREBASE_ANDROID_APP_PACKAGE || env.FIREBASE_ANDROID_APP_PACKAGE,
    FIREBASE_IOS_APP_PACKAGE: env.NEXT_PUBLIC_FIREBASE_IOS_APP_PACKAGE || env.FIREBASE_IOS_APP_PACKAGE,
    FIREBASE_AUTH_DOMAIN: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || env.FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || env.FIREBASE_PROJECT_ID,
    FIREBASE_MESSAGING_SENDER_ID: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || env.FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: env.NEXT_PUBLIC_FIREBASE_APP_ID || env.FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID: env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || env.FIREBASE_MEASUREMENT_ID,
    FIREBASE_AUTH_EMULATOR_HOST: env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST || env.FIREBASE_AUTH_EMULATOR_HOST,
    FIREBASE_WEB_PUSH_CERT_KEY: env.NEXT_PUBLIC_FIREBASE_WEB_PUSH_CERT_KEY || env.FIREBASE_WEB_PUSH_CERT_KEY,
    AMPLITUDE_API_KEY: env.NEXT_PUBLIC_AMPLITUDE_API_KEY || env.AMPLITUDE_API_KEY,
  };
  return envMapped;
};
