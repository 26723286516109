import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const LockIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon viewBox="-1.6005 -2.8516 24.25 24.25" {...props} style={{ fill: 'none', ...props.style }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21H7C5.895 21 5 20.105 5 19V12C5 10.895 5.895 10 7 10H17C18.105 10 19 10.895 19 12V19C19 20.105 18.105 21 17 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LockIcon;
