import { useCallback, useContext } from 'react';

import { I18nContext } from '@amazd/common/utils/i18n/context';
import { format as formatBase, Locale } from 'date-fns';
import { de, enUS, es, fr, nl } from 'date-fns/locale';


type TFunction = (key: string, options?: Record<string, string | number | undefined | null> | string | null) => string;

interface DateFormattingOption {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

function getDateFormattingOptions(language: string) {
  let locale = enUS;
  switch (language) {
    case 'de':
    case 'de_formal':
      locale = de;
      break;
    case 'fr':
    case 'fr_formal':
      locale = fr;
      break;
    case 'es':
    case 'es_formal':
      locale = es;
      break;
    case 'nl':
    case 'nl_formal':
      locale = nl;
      break;
  }
  const config: DateFormattingOption = { locale };
  return config;
}

export type FormatDateFunction = (
  date: number | Date | string,
  format: string,
  options?: DateFormattingOption,
) => string;

export const useTranslation = () => {
  const { i18nReady, i18n, settings } = useContext(I18nContext);

  const formatDate: FormatDateFunction = useCallback(
    (date: number | Date | string, format: string, options?: DateFormattingOption) => {
      return formatBase(new Date(date), format, {
        ...getDateFormattingOptions(i18n?.language),
        ...options,
      });
    },
    [i18n?.language],
  );

  return {
    t: (!i18nReady && settings?.hideKeysIfNotReady ? ((() => '') as any) : i18n.t) as TFunction,
    i18n: i18n,
    ready: i18nReady,
    formatDate,
  };
};
