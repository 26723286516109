import { FallbackProps } from 'react-error-boundary';

import useStyles from './styles';

export default function ErrorFallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Reload</button>
      </div>
    </div>
  );
}
