import { UserType } from '@amazd/common/types';
import { EventChannel } from 'redux-saga';
import type { Channel } from 'stream-chat';

import { Appointment } from './appointment.types';
import { SlotsByDate } from './booking.types';

export interface Company {
  id: string;
  name: string;
  handle: string;
  description: string;
  expertsCount: number;
  image: string;
  uses_informal_language: boolean;
  allowsShopperInstantCalls: boolean;
}

export interface AmazdUser {
  id: string;
  isRole: AmazdRole;
  userType: UserType;
  amazd?: Amazd;
}

export enum AmazdRole {
  EXPERT = 'EXPERT',
  FRIEND = 'FRIEND',
  OWNER = 'OWNER',
  OTHER = 'OTHER',
}

export interface Amazd {
  readonly id: string;
  readonly owner?: AmazdUser;
  readonly amazdUsers: AmazdUser[];
  readonly company: Company;
  readonly expert: AmazdUser;
  readonly appointments: Appointment[];
  readonly createdAt: string;
}

export interface AmazdWithChannel extends Amazd {
  streamChannel?: Channel;
}

export interface AmazdDetailsState {
  readonly amazd: Amazd | null;
  readonly loading: boolean;
  readonly errorMessage: string;
  readonly messagesChannel?: EventChannel<any> | null;
  readonly typingActivityChannel?: EventChannel<any> | null;
  readonly availableDurations?: number[];
  readonly availableSlotsByDate?: SlotsByDate[];
  readonly availableSlotsLoading: boolean;
  readonly bookSlotLoading: boolean;
}

export interface AmazdsState {
  readonly errorMessage: string;
  readonly currentAmazd: {
    amazd: Amazd | null;
    loading: boolean;
  };
}
