import React from 'react';

const NoActiveAmazdsIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8229 125.23L62.72 134.19V117.842C51.9158 110.459 44.875 98.7475 44.875 85.4443C44.875 62.9385 64.9463 44.8752 89.5 44.8752C114.054 44.8752 134.125 62.9385 134.125 85.4443C134.125 107.95 114.054 126.013 89.5 126.013C86.53 126.013 83.6343 125.736 80.8229 125.23Z"
        stroke="#D4D6E8"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76 87C76 90.312 73.3145 93 70 93C66.688 93 64 90.3119 64 87C64 83.6854 66.6881 81 70 81C73.3146 81 76 83.6855 76 87Z"
        fill="#D4D6E8"
      />
      <path
        d="M97 87C97 90.312 94.3145 93 91 93C87.688 93 85 90.3119 85 87C85 83.6854 87.6881 81 91 81C94.3146 81 97 83.6855 97 87Z"
        fill="#D4D6E8"
      />
      <path
        d="M118 87C118 90.312 115.315 93 112 93C108.688 93 106 90.3119 106 87C106 83.6854 108.688 81 112 81C115.315 81 118 83.6855 118 87Z"
        fill="#D4D6E8"
      />
      <path
        d="M33.7256 50.6329C29.9986 54.7006 23.6756 54.9799 19.6047 51.2501C15.537 47.5232 15.2606 41.1969 18.9875 37.1293C22.7174 33.0584 29.0407 32.7851 33.1083 36.512C37.1792 40.2419 37.4554 46.562 33.7256 50.6329Z"
        fill="#7E05FE"
      />
      <path
        d="M80.5354 167.201C77.5251 165.829 76.1987 162.281 77.5709 159.271C78.9412 156.263 82.4944 154.934 85.5008 156.303C88.5093 157.677 89.8376 161.226 88.4672 164.235C87.0933 167.243 83.5437 168.572 80.5354 167.201L80.5354 167.201Z"
        fill="#FFB13F"
      />
      <path
        d="M165.967 83.935C163.729 86.3784 159.933 86.5424 157.49 84.3037C155.047 82.0651 154.881 78.2717 157.12 75.8284C159.358 73.3851 163.153 73.2174 165.597 75.456C168.04 77.6946 168.206 81.4917 165.967 83.935Z"
        fill="#FF0066"
      />
    </svg>
  );
};

export default NoActiveAmazdsIcon;
