import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CallsMenuIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon viewBox="0 0 36 36" style={{ fill: 'none', height: '36px', width: '36px' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.048 19.869C14.878 18.699 13.996 17.389 13.41 16.06C13.286 15.779 13.359 15.45 13.576 15.233L14.395 14.414C15.066 13.743 15.066 12.794 14.48 12.208L13.306 11.035C12.525 10.254 11.259 10.254 10.478 11.035L9.82599 11.686C9.08499 12.427 8.77599 13.496 8.97599 14.556C9.46999 17.169 10.988 20.03 13.437 22.479C15.886 24.928 18.747 26.446 21.36 26.94C22.42 27.14 23.489 26.831 24.23 26.09L24.881 25.439C25.662 24.658 25.662 23.392 24.881 22.611L23.708 21.438C23.122 20.852 22.172 20.852 21.587 21.438L20.684 22.342C20.467 22.559 20.138 22.632 19.857 22.508C18.528 21.921 17.218 21.038 16.048 19.869Z"
        stroke={props ? props.color || 'inherit' : 'inherit'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.92 8.07996C19.3342 8.07996 19.67 8.41574 19.67 8.82996V11.17C19.67 11.5842 19.3342 11.92 18.92 11.92C18.5058 11.92 18.17 11.5842 18.17 11.17V8.82996C18.17 8.41574 18.5058 8.07996 18.92 8.07996Z"
        fill="#A6A9BF"
        strokeWidth="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2719 10.6512C25.5639 10.945 25.5625 11.4199 25.2687 11.7119L23.6187 13.3519C23.3249 13.6439 22.8501 13.6424 22.5581 13.3486C22.2661 13.0549 22.2675 12.58 22.5613 12.288L24.2113 10.648C24.5051 10.356 24.9799 10.3574 25.2719 10.6512Z"
        fill="#FF0066"
        strokeWidth="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 17C24 16.5858 24.3358 16.25 24.75 16.25H27.08C27.4942 16.25 27.83 16.5858 27.83 17C27.83 17.4142 27.4942 17.75 27.08 17.75H24.75C24.3358 17.75 24 17.4142 24 17Z"
        fill="#FFB13F"
        strokeWidth="0"
      />
    </SvgIcon>
  );
};

export default CallsMenuIcon;
