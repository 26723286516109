import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { reportError } from '../utils';
import { errorReporterMiddleware } from './middlewares/error-reporter.middleware';
import rootReducer from './reducers';
import sagas from './sagas';

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    reportError(error, { extraInfo: errorInfo });
  },
});

const emptyEnhancer = (next: any) => next;

// This is for Chrome dev tools extension
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(errorReporterMiddleware),
    applyMiddleware(sagaMiddleware),
    // If Chrome Redux devtools extension is installed then enable it for development
    process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : emptyEnhancer,
  ),
);

sagaMiddleware.run(sagas);

export default store;
