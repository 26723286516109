import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const LogoutIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path d="M13 9H1" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.99902 4L13 9.001L7.99902 14.001"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1H15C16.105 1 17 1.895 17 3V15C17 16.105 16.105 17 15 17H13"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LogoutIcon;
