import React from 'react';

const OnlineIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="5.75" fill="#56C087" stroke="white" />
    </svg>
  );
};

export default OnlineIcon;
