import { all, put, spawn, takeEvery } from 'redux-saga/effects';

import { getClient } from '../../../apollo';
import { reportError, getGraphQlError } from '../../../utils';
import { getAuthStoreState } from '../../../utils/auth';
import { CREATE_CALL_ROOM } from '../../actions';
import * as callRoomActions from '../../actions/callRoomActions';
import { sendMessageMutation } from '../../graphql/mutations/chatMutations';
import { Action } from '../../types';

function* createCallRoom(action: Action): any {
  try {
    const { conversationId } = action.payload;
    const currentUserType = getAuthStoreState().getUserType();

    const res = yield getClient().mutate({
      mutation: sendMessageMutation,
      variables: {
        args: {
          conversationId,
          content: '',
          type: 'call',
          userTypeId: currentUserType?.id,
        },
      },
    });
    if (res && res.data) {
      yield put(callRoomActions.createCallRoomSuccess(res.data.message));
    }
  } catch (err) {
    yield put(callRoomActions.createCallRoomFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* createCallRoomSaga() {
  yield takeEvery(CREATE_CALL_ROOM, createCallRoom);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([createCallRoomSaga()]);
}
