import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PlayIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 13L0.500001 25.9904L0.500002 0.00961778L23 13Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default PlayIcon;
