import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AppleIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width={19} height={24} viewBox="0 0 19 24" fill="none" {...props}>
      <path
        d="M9.755 5.538c1.05 0 2.368-.732 3.153-1.708.71-.885 1.229-2.121 1.229-3.357A2.33 2.33 0 0014.091 0c-1.17.046-2.576.809-3.42 1.83-.665.779-1.272 2-1.272 3.25 0 .184.03.367.044.428.074.015.193.03.31.03zM6.055 24c1.435 0 2.072-.992 3.863-.992 1.82 0 2.22.962 3.819.962 1.569 0 2.62-1.496 3.612-2.96 1.11-1.679 1.569-3.327 1.598-3.403-.103-.03-3.108-1.297-3.108-4.852 0-3.082 2.368-4.47 2.501-4.577-1.569-2.32-3.952-2.38-4.603-2.38-1.762 0-3.197 1.098-4.1 1.098-.977 0-2.265-1.037-3.79-1.037C2.946 5.859 0 8.33 0 12.999c0 2.9 1.095 5.966 2.442 7.95C3.597 22.627 4.604 24 6.054 24z"
        fill="#000"
      />
    </SvgIcon>
  );
};

export default AppleIcon;
