import { hasLocalStorageAccess } from '../utils/browser';

const tokenKey = 'impersonatedToken';

const isClient = typeof window !== 'undefined';

export const impersonatedToken = isClient && hasLocalStorageAccess() ? localStorage.getItem(tokenKey) : null;

export const useImpersonation = () => {
  const isImpersonating = !!impersonatedToken;

  const whitelist = [tokenKey, 'auth-storage'];

  const localStorageCleanup = () => {
    const keys = Object.keys(localStorage);

    for (const key of keys) {
      if (!whitelist.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  };

  const redirectHome = () => {
    if (isClient) {
      localStorageCleanup();
      window.location.href = '/';
    }
  };

  const handleSetImpersonation = (token: string) => {
    localStorage.setItem(tokenKey, token);
    redirectHome();
  };

  const handleResetImpersonation = () => {
    localStorage.removeItem(tokenKey);
    redirectHome();
  };

  return {
    isImpersonating,
    handleSetImpersonation,
    handleResetImpersonation,
  };
};
