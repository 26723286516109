import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SearchIcon: React.FC<any> = ({ color, ...rest }): React.ReactElement => {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        d="M15.8349 15.8333L13.0932 13.0916M13.0965 5.69828C14.0774 6.67914 14.6284 8.00947 14.6284 9.39662C14.6284 10.7838 14.0774 12.1141 13.0965 13.0949C12.1157 14.0758 10.7853 14.6268 9.3982 14.6268C8.01106 14.6268 6.68073 14.0758 5.69987 13.0949C4.71901 12.1141 4.16797 10.7838 4.16797 9.39662C4.16797 8.00947 4.71901 6.67914 5.69987 5.69828C6.68073 4.71742 8.01106 4.16638 9.3982 4.16638C10.7853 4.16638 12.1157 4.71742 13.0965 5.69828Z"
        stroke={color}
        fill={'none'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
