import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SendIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="21" height="21" viewBox="0 0 21 21" {...props}>
      <path
        fill={props ? props.color || 'inherit' : 'inherit'}
        d="M12.7207 20.75C13.4238 20.75 13.9219 20.1445 14.2832 19.207L20.6797 2.49805C20.8555 2.04883 20.9531 1.64844 20.9531 1.31641C20.9531 0.681641 20.5625 0.291016 19.9277 0.291016C19.5957 0.291016 19.1953 0.388672 18.7461 0.564453L1.94922 7C1.12891 7.3125 0.494141 7.81055 0.494141 8.52344C0.494141 9.42188 1.17773 9.72461 2.11523 10.0078L9.16602 12.0781L11.2168 19.041C11.5098 20.0273 11.8125 20.75 12.7207 20.75ZM9.60547 10.5938L2.86719 8.5332C2.71094 8.48438 2.66211 8.44531 2.66211 8.37695C2.66211 8.30859 2.70117 8.25977 2.84766 8.20117L16.0508 3.20117C16.832 2.9082 17.584 2.51758 18.3066 2.18555C17.6621 2.71289 16.8613 3.33789 16.3242 3.875L9.60547 10.5938ZM12.877 18.6016C12.7988 18.6016 12.7598 18.5332 12.7109 18.377L10.6504 11.6387L17.3691 4.91992C17.8965 4.39258 18.5508 3.57227 19.0684 2.9082C18.7363 3.65039 18.3359 4.40234 18.043 5.19336L13.043 18.3965C12.9844 18.543 12.9453 18.6016 12.877 18.6016Z"
      />
    </SvgIcon>
  );
};

export default SendIcon;
