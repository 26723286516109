import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MoreIconVertical: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#E7E9F9" fillOpacity="0.7" />
      <path
        d="M21 16C21 16.0989 20.9707 16.1956 20.9157 16.2778C20.8608 16.36 20.7827 16.4241 20.6913 16.4619C20.6 16.4998 20.4994 16.5097 20.4025 16.4904C20.3055 16.4711 20.2164 16.4235 20.1464 16.3536C20.0765 16.2836 20.0289 16.1945 20.0096 16.0975C19.9903 16.0006 20.0002 15.9 20.0381 15.8087C20.0759 15.7173 20.14 15.6392 20.2222 15.5843C20.3044 15.5293 20.4011 15.5 20.5 15.5C20.6326 15.5 20.7598 15.5527 20.8536 15.6464C20.9473 15.7402 21 15.8674 21 16Z"
        fill="#1C1C21"
      />
      <path
        d="M17 16C17 16.0989 16.9707 16.1956 16.9157 16.2778C16.8608 16.36 16.7827 16.4241 16.6913 16.4619C16.6 16.4998 16.4994 16.5097 16.4025 16.4904C16.3055 16.4711 16.2164 16.4235 16.1464 16.3536C16.0765 16.2836 16.0289 16.1945 16.0096 16.0975C15.9903 16.0006 16.0002 15.9 16.0381 15.8087C16.0759 15.7173 16.14 15.6392 16.2222 15.5843C16.3044 15.5293 16.4011 15.5 16.5 15.5C16.6326 15.5 16.7598 15.5527 16.8536 15.6464C16.9473 15.7402 17 15.8674 17 16Z"
        fill="#1C1C21"
      />
      <path
        d="M13 16C13 16.0989 12.9707 16.1956 12.9157 16.2778C12.8608 16.36 12.7827 16.4241 12.6913 16.4619C12.6 16.4998 12.4994 16.5097 12.4025 16.4904C12.3055 16.4711 12.2164 16.4235 12.1464 16.3536C12.0765 16.2836 12.0289 16.1945 12.0096 16.0975C11.9903 16.0006 12.0002 15.9 12.0381 15.8087C12.0759 15.7173 12.14 15.6392 12.2222 15.5843C12.3044 15.5293 12.4011 15.5 12.5 15.5C12.6326 15.5 12.7598 15.5527 12.8536 15.6464C12.9473 15.7402 13 15.8674 13 16Z"
        fill="#1C1C21"
      />
      <path
        d="M21 16C21 16.0989 20.9707 16.1956 20.9157 16.2778C20.8608 16.36 20.7827 16.4241 20.6913 16.4619C20.6 16.4998 20.4994 16.5097 20.4025 16.4904C20.3055 16.4711 20.2164 16.4235 20.1464 16.3536C20.0765 16.2836 20.0289 16.1945 20.0096 16.0975C19.9903 16.0006 20.0002 15.9 20.0381 15.8087C20.0759 15.7173 20.14 15.6392 20.2222 15.5843C20.3044 15.5293 20.4011 15.5 20.5 15.5C20.6326 15.5 20.7598 15.5527 20.8536 15.6464C20.9473 15.7402 21 15.8674 21 16Z"
        stroke="#1C1C21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 16C17 16.0989 16.9707 16.1956 16.9157 16.2778C16.8608 16.36 16.7827 16.4241 16.6913 16.4619C16.6 16.4998 16.4994 16.5097 16.4025 16.4904C16.3055 16.4711 16.2164 16.4235 16.1464 16.3536C16.0765 16.2836 16.0289 16.1945 16.0096 16.0975C15.9903 16.0006 16.0002 15.9 16.0381 15.8087C16.0759 15.7173 16.14 15.6392 16.2222 15.5843C16.3044 15.5293 16.4011 15.5 16.5 15.5C16.6326 15.5 16.7598 15.5527 16.8536 15.6464C16.9473 15.7402 17 15.8674 17 16Z"
        stroke="#1C1C21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 16C13 16.0989 12.9707 16.1956 12.9157 16.2778C12.8608 16.36 12.7827 16.4241 12.6913 16.4619C12.6 16.4998 12.4994 16.5097 12.4025 16.4904C12.3055 16.4711 12.2164 16.4235 12.1464 16.3536C12.0765 16.2836 12.0289 16.1945 12.0096 16.0975C11.9903 16.0006 12.0002 15.9 12.0381 15.8087C12.0759 15.7173 12.14 15.6392 12.2222 15.5843C12.3044 15.5293 12.4011 15.5 12.5 15.5C12.6326 15.5 12.7598 15.5527 12.8536 15.6464C12.9473 15.7402 13 15.8674 13 16Z"
        stroke="#1C1C21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default MoreIconVertical;
