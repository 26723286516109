import { gql, useMutation } from '@apollo/client';

const roomTokenMutation = gql`
  mutation roomToken($amazdId: String!) {
    roomToken(amazdId: $amazdId) {
      roomToken
      roomId
    }
  }
`;

export const useRoomToken = () => {
  const [mutate, { loading, error }] = useMutation(roomTokenMutation);

  const fetch = (amazdId: string) => {
    return mutate({
      variables: {
        amazdId,
      },
    });
  };

  return [fetch, loading, error] as const;
};
