export const waitUntil = (condition: () => boolean): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!condition()) {
      resolve();
    }

    const interval = setInterval(() => {
      if (!condition()) {
        clearInterval(interval);
        resolve();
      }
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      clearTimeout(timeout);
      reject('waitUntil helper timeouts.');
    }, 30000);
  });
