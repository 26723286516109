import React from 'react';

const CallUnvailableIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/SvgIcon" {...props}>
      <path
        d="M15.8333 3.93332L3.33332 16.4417M8.63416 14.47C10.2758 15.7283 12.0525 16.5283 13.7008 16.845C14.5842 17.0117 15.4675 16.7533 16.0925 16.1367L16.6342 15.5867C17.2842 14.9283 17.2842 13.8783 16.6342 13.2283L15.6508 12.2508C15.1592 11.7592 14.3675 11.7592 13.8758 12.2508L13.1175 13.0008C12.9342 13.1758 12.6592 13.2425 12.4283 13.1342C11.8867 12.8925 11.345 12.5925 10.8258 12.2342M9.05666 10.7083C8.18999 9.79499 7.52332 8.79166 7.07749 7.76666C6.96916 7.53332 7.02749 7.25916 7.21082 7.07499L7.88582 6.39249C8.44416 5.82582 8.44416 5.04249 7.95249 4.55082L6.97499 3.56666C6.31666 2.90832 5.26666 2.90832 4.61665 3.56666L4.06666 4.10832C3.44166 4.72499 3.19166 5.61666 3.35832 6.49999C3.74999 8.59166 4.94749 10.8775 6.85416 12.8583"
        stroke={props?.color || '#727488'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallUnvailableIcon;
