import React from 'react';

import PreloaderIcon from '@amazd/common/icons/PreloaderIcon';
import { useTheme } from '@mui/material';

interface LoaderProps {
  size?: number;
  height?: number;
  width?: number;
  style?: Record<any, any>;
  color?: string;
}

export default function Loader({ size, style, height, width, color }: LoaderProps): JSX.Element {
  const theme = useTheme();
  return (
    <PreloaderIcon
      style={{
        display: 'flex',
        margin: 'auto',
        ...(style ?? {}),
        height: size || height || 250 || style?.height,
        width: size || width || 250 || style?.width,
      }}
      color={color || (theme.palette as any).loaderColor}
    />
  );
}
