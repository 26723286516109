import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MediaIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <SvgIcon viewBox="-1.147 -3.0627 29.55 27.43">
      <path d="M3.55664 16.6719H5.42188V18.166C5.42188 20.1777 6.4375 21.1934 8.48828 21.1934H22.7363C24.7773 21.1934 25.793 20.1777 25.793 18.166V9.20117C25.793 7.18945 24.7773 6.18359 22.7363 6.18359H21.4766C21.2227 6.18359 21.0273 6.16406 20.8613 6.13477V3.76172C20.8613 1.75 19.8359 0.734375 17.8047 0.734375H3.55664C1.50586 0.734375 0.490234 1.75 0.490234 3.76172V13.6445C0.490234 15.6562 1.50586 16.6719 3.55664 16.6719ZM3.57617 15.0996C2.59961 15.0996 2.0625 14.5723 2.0625 13.5566V3.84961C2.0625 2.83398 2.59961 2.30664 3.57617 2.30664H17.7754C18.7422 2.30664 19.2891 2.83398 19.2891 3.84961V4.75781C18.8301 4.28906 18.3711 4.07422 17.6582 4.07422H13.5664C12.7949 4.07422 12.3262 4.32812 11.8184 4.875L11.125 5.63672C10.6953 6.0957 10.4707 6.18359 9.73828 6.18359H8.48828C6.4375 6.18359 5.42188 7.18945 5.42188 9.20117V15.0996H3.57617ZM8.50781 19.6211C7.53125 19.6211 6.99414 19.0938 6.99414 18.0781V9.28906C6.99414 8.27344 7.53125 7.75586 8.50781 7.75586H10.1191C10.959 7.75586 11.4766 7.58008 11.9746 7.04297L12.8145 6.11523C13.127 5.79297 13.4199 5.64648 13.9473 5.64648H17.2676C17.8047 5.64648 18.0879 5.79297 18.4004 6.11523L19.25 7.04297C19.748 7.58008 20.2559 7.75586 21.0957 7.75586H22.707C23.6836 7.75586 24.2207 8.27344 24.2207 9.28906V18.0781C24.2207 19.0938 23.6836 19.6211 22.707 19.6211H8.50781ZM15.6172 18.3027C18.2246 18.3027 20.3438 16.1934 20.3438 13.5664C20.3438 10.959 18.2246 8.83984 15.6172 8.83984C13 8.83984 10.8906 10.959 10.8906 13.5664C10.8906 16.1934 13 18.3027 15.6172 18.3027ZM21.6133 11.3203C22.2188 11.3203 22.7168 10.832 22.7168 10.2266C22.7168 9.62109 22.2188 9.12305 21.6133 9.12305C21.0078 9.12305 20.5195 9.62109 20.5195 10.2266C20.5195 10.832 21.0078 11.3203 21.6133 11.3203ZM15.6172 16.8086C13.8398 16.8086 12.3848 15.3535 12.3848 13.5664C12.3848 11.7891 13.8398 10.3438 15.6172 10.3438C17.3945 10.3438 18.8398 11.7891 18.8398 13.5664C18.8398 15.3535 17.3945 16.8086 15.6172 16.8086Z" />
    </SvgIcon>
  );
};

export default MediaIcon;
