import {
  ALL_LANGUAGES,
  DEFAULT_NAMESPACE,
  FORMAL_LANG_SUFFIX,
  I18N_API_URL,
  VALID_LANGUAGES,
  VALID_NAMESPACES,
} from '@amazd/common/utils/i18n/constants';
import type { i18n as I18nType } from 'i18next';

export function getBrowserLanguage(companyUsesInformal: boolean) {
  let browserLanguage: typeof VALID_LANGUAGES[number] = 'en';
  try {
    const userLanguage = window.navigator.language.toLowerCase();
    if (userLanguage.startsWith('de')) {
      browserLanguage = 'de';
    } else if (userLanguage.startsWith('nl')) {
      browserLanguage = 'nl';
    } else if (userLanguage.startsWith('es')) {
      browserLanguage = 'es';
    } else if (userLanguage.startsWith('fr')) {
      browserLanguage = 'fr';
    }
    // eslint-disable-next-line no-empty
  } catch (_) {}

  const i18nLanguage = companyUsesInformal ? browserLanguage : browserLanguage + FORMAL_LANG_SUFFIX;

  return { browserLanguage, i18nLanguage };
}

export async function loadI18nResources(locale: string, companyUsesInformal: boolean, namespace = DEFAULT_NAMESPACE) {
  const i18nLanguage = companyUsesInformal ? locale : locale + FORMAL_LANG_SUFFIX;

  const fetchTranslations = async (language: string) =>
    (await fetch(`${I18N_API_URL}/${namespace}/${language}.json`)
      .then((r) => r.json())
      .catch(console.error)) || null;

  const response = await Promise.all([
    await fetchTranslations(i18nLanguage),
    locale !== i18nLanguage ? await fetchTranslations(locale) : null,
  ]);
  // Don't use destructuring in this file because it's not supported by widget host's compiler
  const i18nData = response[0];
  const userData = response[1];

  const resources: any = {};
  if (i18nData && Object.keys(i18nData).length) {
    resources[i18nLanguage] = { translation: i18nData };
  }

  if (userData && Object.keys(userData).length) {
    resources[locale] = { translation: userData };
  }

  return {
    resources,
  };
}

export const removeFormalSuffix = (language?: typeof ALL_LANGUAGES[number]) => {
  if (!language) return language;
  const infIndex = language.indexOf(FORMAL_LANG_SUFFIX);
  if (infIndex !== -1) {
    return language.substring(0, language.indexOf(FORMAL_LANG_SUFFIX));
  } else {
    return language;
  }
};

export const isLanguageBundlesLoaded = (i18nInstance: I18nType, namespace: typeof VALID_NAMESPACES[number]) => {
  return (
    i18nInstance.isInitialized &&
    i18nInstance.hasResourceBundle(i18nInstance.language, namespace) &&
    i18nInstance.hasResourceBundle(i18nInstance.options.fallbackLng as string, namespace)
  );
};

export const userDefaultLocale = (s?: string) => {
  if (s && ALL_LANGUAGES.includes(s)) return removeFormalSuffix(s) as typeof VALID_LANGUAGES[number];
  return 'en';
};
