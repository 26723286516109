import { UserRoleEnum } from '@amazd/common/types';

// TODO: temporary fix until figure out the graphql.ts types
export const getHumanReadableExpertRole = (role: UserRoleEnum | any) => {
  switch (role) {
    case 'USER':
      return 'user-role-expert';
    case 'OWNER':
      return 'user-role-owner';
    case 'MANAGER':
      return 'user-role-manager';
    default:
      return '-';
  }
};
