import { getAuthStoreState, refreshToken } from '@amazd/common/utils/auth';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { impersonatedToken } from '../hooks/useImpersonation';
import { realtimeEventsActions } from '../redux/actions';
import store from '../redux/store';
import { JwtHelper, waitUntil } from '../utils';
import { withAuthHeaders, withExtraHeaders } from './helpers';

export const createLinks = (backendUrl?: string, appVersion?: string) => {
  if (backendUrl === undefined) {
    throw new Error('BACKEND_URL is not set');
  }

  const wsUri =
    backendUrl.indexOf('http') === 0 ? backendUrl.replace('http', 'ws') : backendUrl.replace('https', 'wss');

  const initializeWsClient = () => {
    const wsClient = new SubscriptionClient(wsUri, {
      lazy: true, // connects only when first subscription created
      reconnect: true,
      minTimeout: 5000, // the minimum amount of time the client should wait for a connection to be made, on Heroku it sometimes takes more then 2s.
      timeout: 54000, // server sends keepAlive message at 50 sec frequency,
      connectionParams: async () => {
        const token = await getTokenRefreshIfExpired(true);
        return withExtraHeaders(
          {
            token,
          },
          appVersion,
        );
      },
    });

    wsClient.onReconnected(() => {
      store.dispatch(realtimeEventsActions.onWssReconnected());
    });

    return wsClient;
  };
  const wsClient = typeof window !== 'undefined' ? initializeWsClient() : { uri: wsUri };

  const wsLink = typeof window !== 'undefined' ? new WebSocketLink(wsClient) : null;
  if (wsLink) {
    const subscriptionMiddleware = {
      applyMiddleware: async (options: { token: string | undefined }, next: () => void) => {
        options.token = await getTokenRefreshIfExpired(true);
        next();
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    wsLink.subscriptionClient.use([subscriptionMiddleware]);
  }

  const httpLink = createUploadLink({
    uri: backendUrl,
  });

  const extraHeadersLink = setContext(async (_, { headers }) => {
    return { headers: withExtraHeaders(headers, appVersion) };
  });

  const authLink = setContext(async (_, { headers }) => {
    if (impersonatedToken) {
      return withAuthHeaders(headers, impersonatedToken);
    }

    if (headers && headers.useRefreshToken) {
      const refreshToken = getAuthStoreState().refreshToken;
      if (refreshToken) return withAuthHeaders(headers, refreshToken);
    }

    const token = await getTokenRefreshIfExpired();

    // if token is not available then do nothing
    if (!token) {
      return { headers };
    }

    return withAuthHeaders(headers, token);
  });

  const tokenIsNotAvailable = () => !getAuthStoreState().token;

  const getTokenRefreshIfExpired = async (waitUntilTokenIsAvailable = false) => {
    if (waitUntilTokenIsAvailable) {
      //try-catch block only for sentry to ignore this.
      try {
        await waitUntil(tokenIsNotAvailable);
      } catch (e) {
        console.log('waitUntil tokenIsNotAvailable timeout');
      }
    }

    const currentToken = getAuthStoreState().token;

    // refresh if expired
    if (currentToken && JwtHelper.isExpired(currentToken)) {
      await refreshToken();

      const newToken = getAuthStoreState().token;

      if (newToken && newToken !== currentToken) {
        // trigger reconnect ws client with new token
        const wss = wsClient as SubscriptionClient;
        if (typeof window !== 'undefined' && wss.status) {
          wss.close(false, false);
        }

        return newToken;
      }
    }

    return currentToken;
  };

  // this interval is set for wss connection to refresh and reconnect if token expires
  // it should be removed once we start using 3rd party notifications provider.
  const startRefreshTokenJob = () => {
    setInterval(async () => {
      await getTokenRefreshIfExpired();
    }, 1000 * 60); // every minute
  };
  startRefreshTokenJob();

  return { authLink, extraHeadersLink, httpLink, wsLink };
};
