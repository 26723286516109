import { REASSIGN_AMAZD, REASSIGN_AMAZD_FAIL, REASSIGN_AMAZD_SUCCESS } from '../../actions/constants';
import { Action } from '../../types';

export function reassignAmazd(amazdId: string, expertUserTypeId: string, description: string): Action {
  return { type: REASSIGN_AMAZD, payload: { amazdId, expertUserTypeId, description } };
}

export function reassignAmazdSuccess(payload: boolean): Action {
  return { type: REASSIGN_AMAZD_SUCCESS, payload };
}

export function reassignAmazdFail(errorMessage: string): Action {
  return { type: REASSIGN_AMAZD_FAIL, payload: errorMessage };
}
