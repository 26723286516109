import { useEffect, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import type {
  LocalParticipant,
  RemoteParticipant,
  VideoTrack,
  AudioTrack,
  LocalTrack,
  RemoteTrack,
  LocalTrackPublication,
  RemoteTrackPublication,
} from 'twilio-video';

import { MediaSourceEnum } from '../contexts/videoCallContext';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;
type Track = LocalTrack | RemoteTrack;

export const useTracks = (participant?: LocalParticipant | RemoteParticipant) => {
  const [videoTrack, setVideoTrack] = useState<Track>();
  const [audioTrack, setAudioTrack] = useState<Track>();
  const [screenTrack, setScreenTrack] = useState<Track>();
  const { ownUser } = useAuthInfo();

  const setTrack = (event: TrackPublication | Track) => {
    const track = (event as Track).name ? (event as Track) : (event as TrackPublication).track;
    if (track) {
      if (track.kind === 'audio') {
        setAudioTrack(track);
      }

      if (track.kind === 'video') {
        if (track.name === MediaSourceEnum.SCREEN) {
          setScreenTrack(track);
        } else {
          if (track.isEnabled && track.isStarted) {
            setVideoTrack(track);
          }
        }
      }
    }
  };

  const removeTrack = (event: TrackPublication | Track) => {
    const track = (event as Track).name ? (event as Track) : (event as TrackPublication).track;
    if (track) {
      if (track.kind === 'audio') {
        setAudioTrack(undefined);
      }

      if (track.kind === 'video') {
        if (track.name === MediaSourceEnum.SCREEN) {
          setScreenTrack(undefined);
        } else {
          setVideoTrack(undefined);
        }
      }
    }
  };

  useEffect(() => {
    if (!participant) return;

    participant.tracks.forEach((x: any) => {
      if (x.track) {
        setTrack(x.track);
      }
    });

    participant.on('trackPublished', setTrack);
    participant.on('trackEnabled', setTrack);
    participant.on('trackStarted', setTrack);

    participant.on('trackUnpublished', removeTrack);
    participant.on('trackDisabled', removeTrack);
    participant.on('trackStopped', removeTrack);

    return () => {
      if (participant.sid === ownUser?.id) {
        participant.off('trackPublished', setTrack);
        participant.off('trackUnpublished', removeTrack);
        participant.off('trackEnabled', setTrack);
        participant.off('trackStarted', setTrack);
        participant.off('trackDisabled', removeTrack);
        participant.off('trackStopped', removeTrack);
      }
      //TODO: with the new patch this seems no longer needed but we need to test it
      setVideoTrack(undefined);
      setAudioTrack(undefined);
      setScreenTrack(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return {
    videoTrack: videoTrack as VideoTrack,
    audioTrack: audioTrack as AudioTrack,
    screenTrack: screenTrack as VideoTrack,
  };
};

export const useAttachedTrack = (elementRef: React.RefObject<any>, track?: AudioTrack | VideoTrack): void => {
  useEffect(() => {
    if (track) {
      const t = track as VideoTrack | AudioTrack;
      // this timeout is needed only for safari, for some it need some time to attach the track (magic)!
      setTimeout(() => {
        t.attach(elementRef.current as any);
      }, 500);
      return () => {
        t.detach();
      };
    }
  }, [track, elementRef]);
};
