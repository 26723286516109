import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AmazdIcon: React.FC<any> = (): React.ReactElement => (
  <SvgIcon>
    <svg width="36" height="36" viewBox="10 8 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.683 10.5279C14.683 11.7223 13.7145 12.6917 12.5192 12.6917C11.3248 12.6917 10.3555 11.7223 10.3555 10.5279C10.3555 9.33261 11.3249 8.36418 12.5192 8.36418C13.7145 8.36418 14.683 9.33263 14.683 10.5279Z"
        fill="#7E05FE"
      />
      <path
        d="M28.401 17.204C27.2638 17.5734 26.0441 16.9518 25.6749 15.8146C25.3055 14.6784 25.928 13.4568 27.0633 13.0875C28.2005 12.7191 29.4211 13.3406 29.7904 14.4769C30.1589 15.6141 29.5373 16.8347 28.401 17.204H28.401Z"
        fill="#EE9443"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18C22 20.2092 20.2091 22 18 22C15.7909 22 14 20.2092 14 18C14 15.7909 15.7909 14 18 14C20.2091 14 22 15.7909 22 18ZM22 13.5278C20.9385 12.5777 19.5367 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24C19.5367 24 20.9385 23.4223 22 22.4722V23C22 23.5523 22.4477 24 23 24C23.5523 24 24 23.5523 24 23L24 18V13C24 12.4477 23.5523 12 23 12C22.4477 12 22 12.4477 22 13V13.5278Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M17.1131 24.236C17.1131 25.4313 16.1437 26.3997 14.9484 26.3997C13.7531 26.3997 12.7847 25.4313 12.7847 24.236C12.7847 23.0407 13.7531 22.0713 14.9484 22.0713C16.1437 22.0713 17.1131 23.0407 17.1131 24.236Z"
        fill="#EA3368"
      />
    </svg>
  </SvgIcon>
);

export default AmazdIcon;
