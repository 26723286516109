import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SmallArrowRightIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.73854 4.95694L0.991889 0.118529C0.577234 -0.178844 0 0.117518 0 0.627782L6.60309e-07 10.3046C1.32062e-07 10.8149 0.577235 11.1112 0.99189 10.8139L7.73854 5.97545C8.08715 5.72544 8.08715 5.20695 7.73854 4.95694Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default SmallArrowRightIcon;
