export { default as AddIcon } from './AddIcon';
export { default as AddIconOutlined } from './AddIconOutlined';
export { default as AddWeeklyScheduleIcon } from './AddWeeklyScheduleIcon';
export { default as AlertIcon } from './AlertIcon';
export { default as AmazdIcon } from './AmazdIcon';
export { default as AmazdIconWidget } from './AmazdIconWidget';
export { default as AnalyticsIcon } from './AnalyticsIcon';
export { default as AppleIcon } from './AppleIcon';
export { default as ArrowBackIcon } from './ArrowBackIcon';
export { default as ArrowDownIcon } from './ArrowDownIcon';
export { default as ArrowIcon } from './ArrowIcon';
export { default as ArrowLeftIcon } from './ArrowLeftIcon';
export { default as ArrowRightIcon } from './ArrowRightIcon';
export { default as BookmarkIcon } from './BookmarkIcon';
export { default as BriefcaseIcon } from './BriefcaseIcon';
export { default as CalendarIcon } from './CalendarIcon';
export { default as CallEndIcon } from './CallEndIcon';
export { default as CallIcon } from './CallIcon';
export { default as CallsCalendarIcon } from './CallsCalendarIcon';
export { default as CallsIconWidget } from './CallsIconWidget';
export { default as CallsMenuIcon } from './CallsMenuIcon';
export { default as Camera2Icon } from './Camera2Icon';
export { default as CameraIcon } from './CameraIcon';
export { default as CameraSwitchIcon } from './CameraSwitchIcon';
export { default as CelebrateIcon } from './CelebrateIcon';
export { default as ChatIcon } from './ChatIcon';
export { default as ChatIconWidget } from './ChatIconWidget';
export { default as ChevronDown } from './ChevronDown';
export { default as ClipIcon } from './ClipIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as ColoredTeamIcon } from './ColoredTeamIcon';
export { default as CopyIcon } from './CopyIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as DiscoverIcon } from './DiscoverIcon';
export { default as FacebookIcon } from './FacebookIcon';
export { default as FullScreenIcon } from './FullScreenIcon';
export { default as GoogleIcon } from './GoogleIcon';
export { default as HeartIcon } from './HeartIcon';
export { default as ImageIcon } from './ImageIcon';
export { default as InformationIcon } from './InformationIcon';
export { default as LockIcon } from './LockIcon';
export { default as LogoutIcon } from './LogoutIcon';
export { default as MailIcon } from './MailIcon';
export { default as MaximizeIcon } from './MaximizeIcon';
export { default as MediaIcon } from './MediaIcon';
export { default as MenuIcon } from './MenuIcon';
export { default as MicIcon } from './MicIcon';
export { default as MicMuteIcon } from './MicMuteIcon';
export { default as MoreIcon } from './MoreIcon';
export { default as MoreIconVertical } from './MoreIconVertical';
export { default as NoActiveAmazdsIcon } from './NoActiveAmazdsIcon';
export { default as NoArchivedAmazds } from './NoArchivedAmazds';
export { default as OnlineIcon } from './OnlineIcon';
export { default as PlayIcon } from './PlayIcon';
export { default as PlusIcon } from './PlusIcon';
export { default as RecordingMicIcon } from './RecordingMicIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as SendIcon } from './SendIcon';
export { default as SettingsIcon } from './SettingsIcon';
export { default as ShareScreenIcon } from './ShareScreenIcon';
export { default as SmallArrowRightIcon } from './SmallArrowRightIcon';
export { default as StarIcon } from './StarIcon';
export { default as StopRecordingIcon } from './StopRecordingIcon';
export { default as TeamIcon } from './TeamIcon';
export { default as ThreeDots } from './ThreeDots';
export { default as VideoIcon } from './VideoIcon';
export { default as VideoMuteIcon } from './VideoMuteIcon';
export { default as VoiceMuteIcon } from './VoiceMuteIcon';
export { default as WidgetConfiguratorIcon } from './WidgetConfiguratorIcon';
export { default as JoinCallIcon } from './JoinCallIcon';
export { default as DeclineCallIcon } from './DeclineCallIcon';
export { default as DoneIcon } from './DoneIcon';
export { default as DownloadIcon } from './DownloadIcon';
export { default as ArrowNextIcon } from './ArrowNextIcon';
export { default as CallAvailableIcon } from './CallAvailableIcon';
export { default as CallUnavailableIcon } from './CallUnavailableIcon';
export { default as CancelIcon } from './CancelIcon';
