import { gql } from '@apollo/client';

import { APPOINTMENT_FIELDS } from './bookingFields';

export const AMAZD_USER_TYPE_FIELDS = gql`
  fragment AmazdUserTypeFields on UserTypeModel {
    id
    avatar
    handle
    user {
      id
      firstName
      lastName
      email
    }
    amazdsCount
    description
    company {
      id
      name
      handle
      description
      expertsCount
      image
    }
  }
`;

export const AMAZD_USER_FIELDS = gql`
  ${AMAZD_USER_TYPE_FIELDS}
  fragment AmazdUserFields on AmazdUser {
    id
    isRole
    userType {
      ...AmazdUserTypeFields
    }
  }
`;

export const AMAZD_FIELDS = gql`
  ${AMAZD_USER_FIELDS}
  ${APPOINTMENT_FIELDS}
  fragment AmazdFields on Amazd {
    id
    createdAt
    amazdUsers {
      ...AmazdUserFields
    }
    owner {
      ...AmazdUserFields
    }
    expert {
      ...AmazdUserFields
    }
    appointments {
      ...AppointmentFields
    }
  }
`;
