import React, { ReactNode, useContext, useState, useEffect, useRef } from 'react';

import { IFrameInteractions, isMobile } from '@amazd/common/utils';
import type { RemoteParticipant, Room, LocalParticipant } from 'twilio-video';

import useRinging from '../../RingingPopup/hooks/useRinging';
import useSendNewCallEvent from '../../RingingPopup/hooks/useSendNewCallEvent';
import { CallActionTypeEnum, CallEvent } from '../../RingingPopup/types';
import useCameraToggle from '../hooks/useCameraToggle';
import useFullscreenToggle from '../hooks/useFullscreenToggle';
import useMicToggle from '../hooks/useMicToggle';
import usePresentingParticipant from '../hooks/usePresentingParticipant';
import useRoom from '../hooks/useRoom';
import useRoomParticipants from '../hooks/useRoomParticipants';

export enum MediaSourceEnum {
  CAMERA = 'camera',
  MIC = 'mic',
  SCREEN = 'screen',
}

export interface Position {
  x: number;
  y: number;
}

export interface IVideoCallContext {
  visible: boolean;
  connecting: boolean;
  connectionError: boolean;
  minimized: boolean;
  minimizedPosition: Position;
  room?: Room;
  devicePermissionDenied?: boolean;
  localParticipant?: LocalParticipant;
  remoteParticipant?: RemoteParticipant;
  presentingParticipant?: RemoteParticipant | LocalParticipant;
  muted: boolean;
  videoOff: boolean;
  noCamera: boolean;
  presenting: boolean;
  isWidgetScreen: boolean;
  fullscreen: boolean;
  isMinimized: boolean;
  isRingingModalVisible: boolean;
  activeRinging: CallEvent | null;
  startCall: (amazdId: string, options?: StartCallOptions) => void;
  endCall: (callEventType?: CallActionTypeEnum) => void;
  minimizeToggle: () => void;
  setMinimizedPosition: (newPosition: Position) => void;
  toggleCamera: () => void;
  toggleMic: () => void;
  toggleScreenSharing: () => void;
  toggleFullscreen: () => void;
  handleDecline: () => void;
  handleAccept: () => void;
  setActiveRinging: (activeRinging: CallEvent) => void;
  handleClosePopup: (event: any, reason: any) => void;
  updateCallQueue: () => void;
  setAmazdIdToFilter: (amazdId: string) => void;
}

/* shopper join call types */
export enum ShopperCallJoinType {
  APPOINTMENT = 'appointment',
  INSTANT_CALL = 'instant_call',
}

export type StartCallOptions = {
  callEventType?: CallActionTypeEnum;
  shopperCallJoinType?: ShopperCallJoinType;
};

export const VideoCallContext = React.createContext<IVideoCallContext>({} as IVideoCallContext);

export const VideoCallProvider = ({
  children,
  isWidgetScreen,
  onCallEnded = () => null,
  onCallStarted = () => null,
}: {
  children: ReactNode;
  isWidgetScreen: boolean;
  onCallEnded?: (amazdId: string) => void;
  onCallStarted?: (amazdId: string) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [minimizedPosition, setMinimizedPosition] = useState<Position>({ x: 0, y: 0 });
  const [amazdId, setAmazdId] = useState<string | undefined>();
  const {
    connect,
    disconnect,
    createNewVideoCamTrack,
    connecting,
    connectionError,
    devicePermissionDenied,
    room,
    noCamera,
  } = useRoom();
  const { localParticipant, remoteParticipant } = useRoomParticipants(room);
  const { presentingParticipant, toggleScreenSharing } = usePresentingParticipant(room, remoteParticipant);
  const { fullscreen, toggleFullscreen } = useFullscreenToggle();
  const { videoOff, toggleCamera } = useCameraToggle(createNewVideoCamTrack, localParticipant);
  const { muted, toggleMic } = useMicToggle(localParticipant);
  const { sendCallEvent } = useSendNewCallEvent();
  const [shopperCallJoinType, setShopperCallJoinType] = useState<string | undefined>();

  const startCall = (amazdId: string, options?: StartCallOptions) => {
    if (options?.callEventType) {
      void connect(amazdId, options?.callEventType, sendCallEvent);
    } else {
      void connect(amazdId);
    }
    setAmazdId(amazdId);
    setVisible(true);

    if (isWidgetScreen && options?.shopperCallJoinType) {
      setShopperCallJoinType(options?.shopperCallJoinType);
    }

    if (!activeRinging) {
      setActiveRinging({ amazdId } as CallEvent);
    }
    onCallStarted(amazdId);
  };

  const timeoutRef = useRef<any>();

  useEffect(() => {
    if (isWidgetScreen && shopperCallJoinType === ShopperCallJoinType.INSTANT_CALL) {
      if (room && !remoteParticipant) {
        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            endCall();
            timeoutRef.current = undefined;
            void sendCallEvent(amazdId as string, CallActionTypeEnum.DID_NOT_PICKUP);
          }, 20 * 1000);
        }
      }

      if (room && remoteParticipant && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, remoteParticipant, shopperCallJoinType]);

  const endCall = (callEventType?: CallActionTypeEnum) => {
    if (fullscreen) {
      toggleFullscreen();
    }

    void disconnect();
    setVisible(false);
    setMinimized(false);

    if (isWidgetScreen) {
      setShopperCallJoinType(undefined);
    }

    if (callEventType) {
      if (callEventType === CallActionTypeEnum.STOPPED_CALLING && !remoteParticipant) {
        void sendCallEvent(amazdId as string, callEventType);
      }
    }
    if (amazdId) onCallEnded(amazdId);
    setAmazdId(undefined);
  };

  useEffect(() => {
    if (isWidgetScreen) {
      IFrameInteractions.postMessage('active-call', { value: visible });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const {
    handleAccept,
    activeRinging,
    setActiveRinging,
    handleDecline,
    isMinimized,
    isRingingModalVisible,
    handleClosePopup,
    updateCallQueue,
    setAmazdIdToFilter,
  } = useRinging(startCall, endCall, visible, remoteParticipant, isWidgetScreen);

  const minimizeToggle = () => {
    setMinimized((minimized) => !minimized);
    setMinimizedPosition({ x: 0, y: 0 });
  };

  const actions = {
    startCall,
    endCall,
    minimizeToggle,
    toggleCamera,
    toggleMic,
    toggleScreenSharing,
    toggleFullscreen,
    setMinimizedPosition,
    handleDecline,
    handleAccept,
    setActiveRinging,
    handleClosePopup,
    updateCallQueue,
    setAmazdIdToFilter,
  };

  const state = {
    visible,
    room,
    connecting,
    connectionError,
    devicePermissionDenied,
    isWidgetScreen: (isWidgetScreen && !fullscreen) || isMobile(),
    localParticipant,
    remoteParticipant,
    presentingParticipant,
    presenting: !!presentingParticipant,
    minimized,
    minimizedPosition,
    fullscreen,
    videoOff,
    noCamera,
    muted,
    activeRinging,
    isMinimized,
    isRingingModalVisible,
  };

  const context = {
    ...state,
    ...actions,
    isWidgetScreen,
  };

  return <VideoCallContext.Provider value={context}>{children}</VideoCallContext.Provider>;
};

export const useVideoCallContext = () => {
  return useContext(VideoCallContext);
};
