import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ThreeDotsIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M17 12C17 12.0989 16.9707 12.1956 16.9157 12.2778C16.8608 12.36 16.7827 12.4241 16.6913 12.4619C16.6 12.4998 16.4994 12.5097 16.4025 12.4904C16.3055 12.4711 16.2164 12.4235 16.1464 12.3536C16.0765 12.2836 16.0289 12.1945 16.0096 12.0975C15.9903 12.0006 16.0002 11.9 16.0381 11.8087C16.0759 11.7173 16.14 11.6392 16.2222 11.5843C16.3044 11.5293 16.4011 11.5 16.5 11.5C16.6326 11.5 16.7598 11.5527 16.8536 11.6464C16.9473 11.7402 17 11.8674 17 12Z"
        fill="#727488"
        stroke="#727488"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12C13 12.0989 12.9707 12.1956 12.9157 12.2778C12.8608 12.36 12.7827 12.4241 12.6913 12.4619C12.6 12.4998 12.4994 12.5097 12.4025 12.4904C12.3055 12.4711 12.2164 12.4235 12.1464 12.3536C12.0765 12.2836 12.0289 12.1945 12.0096 12.0975C11.9903 12.0006 12.0002 11.9 12.0381 11.8087C12.0759 11.7173 12.14 11.6392 12.2222 11.5843C12.3044 11.5293 12.4011 11.5 12.5 11.5C12.6326 11.5 12.7598 11.5527 12.8536 11.6464C12.9473 11.7402 13 11.8674 13 12Z"
        fill="#727488"
        stroke="#727488"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12C9 12.0989 8.97068 12.1956 8.91574 12.2778C8.86079 12.36 8.78271 12.4241 8.69134 12.4619C8.59998 12.4998 8.49945 12.5097 8.40246 12.4904C8.30546 12.4711 8.21637 12.4235 8.14645 12.3536C8.07652 12.2836 8.0289 12.1945 8.00961 12.0975C7.99031 12.0006 8.00022 11.9 8.03806 11.8087C8.0759 11.7173 8.13999 11.6392 8.22221 11.5843C8.30444 11.5293 8.40111 11.5 8.5 11.5C8.63261 11.5 8.75979 11.5527 8.85355 11.6464C8.94732 11.7402 9 11.8674 9 12Z"
        fill="#727488"
        stroke="#727488"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ThreeDotsIcon;
