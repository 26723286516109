import {
  add,
  differenceInCalendarDays,
  format,
  formatDistanceToNow,
  formatRelative,
  isEqual,
  isPast,
  isToday,
  isTomorrow,
  set,
  sub,
} from 'date-fns';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';

import { AvailableTime, OverriddenDate } from '../types';
import { capitalize } from '../utils';

export const LAST_END_TIME = '23:59:59';

export const getCalendarDays = (date: Date): Date[][] => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDayOfMonth = set(date, {
      date: 1,
    }),
    lastDayOfMonth = set(date, {
      date: new Date(year, month + 1, 0).getDate(),
    });

  const prevDays = [],
    nextDays = [];

  // get the last max 6 days from the prev month
  let i = firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;
  while (i !== 0) {
    prevDays.push(
      add(firstDayOfMonth, {
        days: -i,
      }),
    );
    i--;
  }

  // get the first 6 days from the next month
  i = 0;
  const max = lastDayOfMonth.getDay() === 0 ? 0 : 7 - lastDayOfMonth.getDay();
  while (i !== max) {
    nextDays.push(
      add(lastDayOfMonth, {
        days: i + 1,
      }),
    );
    i++;
  }

  let calendarDays = [...prevDays];
  let iteratedDateMonth = new Date(firstDayOfMonth);
  const currentMonth = iteratedDateMonth.getMonth();

  while (currentMonth === iteratedDateMonth.getMonth()) {
    calendarDays.push(iteratedDateMonth);
    iteratedDateMonth = add(iteratedDateMonth, {
      days: 1,
    });
  }
  calendarDays = calendarDays.concat(nextDays);

  const res = [];
  for (let i = 0; i < calendarDays.length; i = i + 7) {
    res.push(calendarDays.slice(i, i + 7));
  }

  return res;
};

export const createSlotOptions = (): string[] => {
  let date = set(new Date(), {
    hours: 1,
    minutes: 0,
  });

  const slots = [format(date, 'kk:mm')];
  while (format(date, 'kk:mm') !== '24:00') {
    date = add(date, { minutes: 30 });
    slots.push(format(date, 'kk:mm'));
  }

  return slots;
};

export const getLatestTime = (times: AvailableTime[]): string => {
  if (!times.length) return '00:00';

  let latestTime = times[0].endTime;
  times.map(({ endTime }) => {
    if (latestTime < endTime) {
      latestTime = endTime;
    }
  });

  return latestTime;
};

/**
 *
 * @param time - e.g. 09:00, next value will be 10:00
 * @returns
 */
export const getNextTime = (time: string): string | null => {
  const options = createSlotOptions();
  const index = options.indexOf(time);
  if (index > -1) {
    return options[index + 1] || null;
  } else {
    return null;
  }
};

export const getDateId = (date: Date): string => {
  return format(date, 'uuuu-MM-d');
};

export const getNextAvailableTime = (times: AvailableTime[]): AvailableTime | undefined => {
  const latestTime = getLatestTime(times);
  const startTime = times.length ? getNextTime(latestTime) : '09:00';
  const endTime = times.length ? getNextTime(startTime as string) : '17:00';

  // It means it exceeded the time range for the given day
  if (!endTime || !startTime) {
    return;
  }

  return {
    id: `local-id-${new Date().getTime()}`,
    startTime,
    endTime,
    availableForCalls: true,
  };
};

export const checkDatesOverlap = (times: AvailableTime[]): boolean => {
  // First sort the time ranges by start time
  const sortedTimes = [...times].sort((a, b) => {
    if (a.startTime > b.startTime) {
      return 1;
    } else {
      return -1;
    }
  });
  const prevTime = sortedTimes[0];
  let hasOverlap = false;
  // Then find the overlap
  sortedTimes.map((time, index) => {
    if (time.startTime < prevTime.endTime && index) {
      hasOverlap = true;
    }
  });

  return hasOverlap;
};

export const removeSecondsPostfix = (times: AvailableTime[]): AvailableTime[] => {
  return times.map(({ startTime, endTime, ...rest }) => {
    return {
      ...rest,
      startTime: startTime.substr(0, 5),
      endTime: endTime === LAST_END_TIME ? '24:00' : endTime.substr(0, 5),
    };
  });
};

export const haveSameSlots = (times1: AvailableTime[], times2: AvailableTime[]): boolean => {
  if (times1.length !== times2.length) return false;
  if (!times1.length) return true;

  let isSame = true;
  times1.map((time, index) => {
    if (time.startTime !== times2[index].startTime || time.endTime !== times2[index].endTime) {
      isSame = false;
    }
  });

  return isSame;
};

export const serializeOverrides = (overrides: OverriddenDate[]): OverriddenDate[] => {
  const groupedOverrides = new Map<string, OverriddenDate>();

  const newOverrides = overrides
    // filter those overrides which are already passed
    .filter(({ date }) => {
      return !isPast(new Date(date)) || isToday(new Date(date));
    })
    .map(({ times, ...rest }) => {
      const override = {
        ...rest,
        // TODO - ask to do this in the backend
        times: removeSecondsPostfix(times),
      };
      groupedOverrides.set(override.date, { ...override });
      return override;
    })
    .sort((a, b) => {
      if (a.date > b.date) return 1;
      return -1;
    });

  let prevOverride = newOverrides[0];
  for (let i = 1; i < newOverrides.length; i++) {
    const override = { ...newOverrides[i] };
    if (
      haveSameSlots(prevOverride.times, override.times) &&
      isEqual(new Date(prevOverride.date), sub(new Date(override.date), { days: 1 }))
    ) {
      const groupedOverride = groupedOverrides.get(prevOverride.date) as OverriddenDate;
      if (!groupedOverride.additionalOverrideIds) {
        groupedOverride.additionalOverrideIds = [];
      }
      groupedOverride.additionalOverrideIds.push(override.id);
      groupedOverride.endDate = override.date;
      groupedOverrides.set(override.date, groupedOverride);
      groupedOverrides.delete(prevOverride.date);
    }
    prevOverride = newOverrides[i];
  }

  return [...groupedOverrides.values()].sort((a, b) => {
    if (a.date > b.date) return 1;
    return -1;
  });
};

export const getDatesFromInterval = (override: OverriddenDate): Date[] => {
  let startDate = new Date(override.date);
  const dates = [startDate];
  if (!override.endDate) return dates;

  while (!isEqual(startDate, new Date(override.endDate))) {
    startDate = add(startDate, { days: 1 });
    dates.push(startDate);
  }

  return dates;
};

export function getLocale(language: string | undefined) {
  if (!language) return;
  if (language.startsWith('de')) return de;
  if (language.startsWith('fr')) return fr;
  if (language.startsWith('es')) return es;
  if (language.startsWith('nl')) return nl;
}

export const getWeekday = (date: Date, locale?: string, shortName?: boolean): string => {
  // If it's today either tomorrow
  if (isToday(date) || isTomorrow(date)) {
    const weekday = formatRelative(date, new Date(), {
      locale: getLocale(locale),
    }).split(' ')[0];

    return capitalize(weekday);
  }

  return format(date, shortName ? 'EEEEEE' : 'EEEE', {
    locale: getLocale(locale),
  });
};

// 09:00AM UTC0 => 10:00AM UTC+1
export const convertUTCTimeToLocal = (date: Date, hoursUTC: string, minutesUTC: string): Date => {
  return sub(
    set(date, {
      hours: parseInt(hoursUTC),
      minutes: parseInt(minutesUTC),
    }),
    { minutes: new Date().getTimezoneOffset() },
  );
};

export const distanceToNow = (lang: string, date: Date) => {
  return formatDistanceToNow(date, { addSuffix: true, locale: getLocale(lang) });
};

/**
 *
 * @param shortSlot - e.g. 12:30:00
 * @param date - JS Date object
 * @returns
 */
export const parseShortSlotDateSlotDate = (shortSlot: string, date: Date = new Date()): string => {
  const [hours, minutes, seconds] = shortSlot.split(':');
  const dateBase = new Date(date.getTime());
  dateBase.setSeconds(parseInt(seconds, 10));
  dateBase.setMinutes(parseInt(minutes, 10));
  dateBase.setHours(parseInt(hours, 10));

  return dateBase.toISOString();
};

export const hasXdaysPassed = (days: number, date: Date) => {
  const now = new Date().getTime();
  return differenceInCalendarDays(now, date) >= days;
};
