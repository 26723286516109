import React from 'react';

import PortraitToLandscapeIcon from '@amazd/common/icons/PortraitToLandscapeIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { AppBar, Button, useMediaQuery, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

import useStyles from './styles';

const VerifySufficientScreenSize: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const isInLandscapeMode = useMediaQuery('@media all and (orientation:landscape)');
  const laptopOrDesktopMode = useMediaQuery(theme.breakpoints.up(1024)); // [1024, infinity)
  const tabletMode = useMediaQuery(theme.breakpoints.between(600, 1024)); // [600, 1024)
  const mobileMode = useMediaQuery(theme.breakpoints.down(600)); // [0, 600)
  //reset-token is now irrelevant. It was used for password reset
  const mobilePaths = ['/reset-token', '/login-redirect'];

  if (laptopOrDesktopMode || (tabletMode && isInLandscapeMode)) {
    return <>{children}</>;
  }

  if ((mobileMode || tabletMode) && mobilePaths.includes(pathname)) {
    /**
     * This path is not handled on mobile app so allow rendering in mobile screen here
     */
    return <>{children}</>;
  }

  return (
    <>
      {((tabletMode && !isInLandscapeMode) || mobileMode) && (
        <>
          <AppBar className={classes.nav} position="relative">
            <img src="/amazd-logo-negative.png" className={classes.logo} />
          </AppBar>
          {tabletMode && (
            <div className={classes.tabletContent}>
              <PortraitToLandscapeIcon />
              <div className={classes.tabletText}>{t('verify-sufficient-screen-size-tablet')}</div>
            </div>
          )}
          {mobileMode && (
            <div className={classes.mobileContent}>
              <div className={classes.mobileText}>{t('verify-sufficient-screen-size-mobile')}</div>
              <Button
                href="https://amazd.page.link/mobile-from-web-app"
                variant="contained"
                color="primary"
                className={classes.mobileButton}
              >
                {t('verify-sufficient-screen-size-mobile-button-text')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default VerifySufficientScreenSize;
