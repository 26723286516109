export const IGNORED_ERROR_MESSAGE = 'IGNORED_ERROR_MESSAGE';
export const PREFLIGHT_REQUEST_FAILED_ERROR_MESSAGE = 'Preflight response is not successful';

export type ErrorContext = {
  userId?: string;
  extraInfo?: any;
};

export const reportError = (error: Error | any, errorContext?: ErrorContext): void => {
  if (error.message === IGNORED_ERROR_MESSAGE || error.message === PREFLIGHT_REQUEST_FAILED_ERROR_MESSAGE) {
    return;
  }

  if (process.env.NODE_ENV === 'development') {
    return console.error(error, errorContext);
  } else {
    console.error('ADD Sentry.captureException');
  }
};
