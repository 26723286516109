import React from 'react';

const TeamIcon = (props?: { style?: React.CSSProperties }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.6542 15.8723C35.9709 17.189 35.9709 19.3238 34.6542 20.6406C33.3374 21.9573 31.2026 21.9573 29.8859 20.6406C28.5692 19.3238 28.5692 17.189 29.8859 15.8723C31.2026 14.5556 33.3374 14.5556 34.6542 15.8723"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.671 9.85361C25.6985 11.8811 25.6985 15.1683 23.671 17.1957C21.6435 19.2232 18.3563 19.2232 16.3289 17.1957C14.3014 15.1683 14.3014 11.8811 16.3289 9.85361C18.3563 7.82614 21.6435 7.82614 23.671 9.85361"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1141 15.8723C11.4308 17.189 11.4308 19.3238 10.1141 20.6406C8.79741 21.9573 6.66259 21.9573 5.34588 20.6406C4.02916 19.3238 4.02916 17.189 5.34588 15.8723C6.66259 14.5556 8.79741 14.5556 10.1141 15.8723"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3333 31.6662V29.8395C38.3333 27.5379 36.4683 25.6729 34.1667 25.6729H32.8317"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 31.6662V29.8395C1.66666 27.5379 3.53166 25.6729 5.83332 25.6729H7.16832"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8983 31.6667V28.9984C28.8983 25.7767 26.2866 23.165 23.065 23.165H16.9333C13.7116 23.165 11.1 25.7767 11.1 28.9984V31.6667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamIcon;
