import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  style?: React.CSSProperties;
};

const ArrowDownIcon: React.FC<Props> = (props): React.ReactElement => {
  return (
    <SvgIcon
      style={{ width: '18', height: '11', fill: 'none', color: 'white', ...props.style }}
      {...props}
      viewBox="0 0 18 11"
    >
      <path
        d="M8.97656 10.1543C9.2207 10.1543 9.46484 10.0566 9.63086 9.87109L17.1895 2.12695C17.3555 1.96094 17.4531 1.74609 17.4531 1.50195C17.4531 0.994141 17.0723 0.603516 16.5645 0.603516C16.3203 0.603516 16.0957 0.701172 15.9297 0.857422L8.97656 7.9668L2.01367 0.857422C1.85742 0.701172 1.63281 0.603516 1.37891 0.603516C0.871094 0.603516 0.490234 0.994141 0.490234 1.50195C0.490234 1.74609 0.587891 1.96094 0.753906 2.13672L8.3125 9.87109C8.49805 10.0566 8.72266 10.1543 8.97656 10.1543Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
