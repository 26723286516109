import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { colors } from '../static';

const DownloadIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      style={{ fill: 'none' }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 12.1667V0.5M14.6663 15.5H1.33301M12.1663 8L7.99884 12.1675L3.83217 8"
        stroke={props?.color || colors.amazdPurple}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
