import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MaximizeIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M10.7608 12.1752C11.1513 12.5657 11.7844 12.5657 12.175 12.1752C12.5655 11.7847 12.5655 11.1515 12.175 10.761L10.7608 12.1752ZM5.3396 5.33984L5.3396 4.33984C5.07438 4.33984 4.82003 4.4452 4.63249 4.63274C4.44496 4.82027 4.3396 5.07463 4.3396 5.33984L5.3396 5.33984ZM9.93579 6.33984C10.4881 6.33984 10.9358 5.89213 10.9358 5.33984C10.9358 4.78756 10.4881 4.33984 9.93579 4.33984L9.93579 6.33984ZM4.3396 9.93604C4.3396 10.4883 4.78731 10.936 5.3396 10.936C5.89188 10.936 6.3396 10.4883 6.3396 9.93604L4.3396 9.93604ZM15.2391 13.8251C14.8486 13.4346 14.2154 13.4346 13.8249 13.8251C13.4344 14.2157 13.4344 14.8488 13.8249 15.2393L15.2391 13.8251ZM20.6602 20.6605V21.6605C21.2125 21.6605 21.6602 21.2128 21.6602 20.6605H20.6602ZM16.0641 19.6605C15.5118 19.6605 15.0641 20.1082 15.0641 20.6605C15.0641 21.2128 15.5118 21.6605 16.0641 21.6605V19.6605ZM21.6602 16.0643C21.6602 15.512 21.2125 15.0643 20.6602 15.0643C20.108 15.0643 19.6602 15.512 19.6602 16.0643H21.6602ZM12.175 10.761L6.04671 4.63274L4.63249 6.04695L10.7608 12.1752L12.175 10.761ZM9.93579 4.33984L5.3396 4.33984L5.3396 6.33984L9.93579 6.33984L9.93579 4.33984ZM4.3396 5.33984L4.3396 9.93604L6.3396 9.93604L6.3396 5.33984L4.3396 5.33984ZM13.8249 15.2393L19.9531 21.3676L21.3674 19.9534L15.2391 13.8251L13.8249 15.2393ZM16.0641 21.6605H20.6602V19.6605H16.0641V21.6605ZM21.6602 20.6605V16.0643H19.6602V20.6605H21.6602Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default MaximizeIcon;
