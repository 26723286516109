import { useState, useEffect } from 'react';

import type { LocalParticipant, RemoteParticipant, Room } from 'twilio-video';

const useRoomParticipants = (room?: Room) => {
  const [localParticipant, setLocalParticipant] = useState<LocalParticipant | undefined>();
  const [remoteParticipant, setRemoteParticipant] = useState<RemoteParticipant | undefined>();

  useEffect(() => {
    if (!room) return;

    setLocalParticipant(room.localParticipant);

    const participants = Array.from(room.participants.values());
    if (participants.length > 0) {
      setRemoteParticipant(participants[0]);
    }

    return () => {
      setLocalParticipant(undefined);
      setRemoteParticipant(undefined);
    };
  }, [room]);

  useEffect(() => {
    if (!room) return;

    room.on('participantConnected', onParticipantConnected);
    room.on('participantDisconnected', onParticipantDisconnected);
    room.on('participantReconnecting', onParticipantDisconnected);

    //only works when other parties connection drops.
    room.on('participantReconnected', onParticipantConnected);

    return () => {
      if (room.off) {
        room.off('participantConnected', onParticipantConnected);
        room.off('participantDisconnected', onParticipantDisconnected);
        room.off('participantReconnecting', onParticipantDisconnected);
        room.off('participantReconnected', onParticipantConnected);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const onParticipantConnected = (participant: RemoteParticipant) => {
    console.log('Participant connected', participant);
    setRemoteParticipant(participant);
  };

  /* Twilio keeps sending event for an old connection even though
  the other participant creates a new one. In case of ungraceful disconnects,
  we keep getting the disconnected event even though the user reconnects.
  i.e (=> connected => disconnected) that will result in remoteParticipant being undefined
  */
  const onParticipantDisconnected = () => {
    if (room) {
      const participants = Array.from(room.participants.values());

      if (participants.length > 0) {
        const remoteParticipant = participants[0];

        if (remoteParticipant.state === 'reconnecting') {
          setRemoteParticipant(undefined);
        }
      } else {
        setRemoteParticipant(undefined);
      }
    }
  };

  return {
    localParticipant,
    remoteParticipant,
  };
};

export default useRoomParticipants;
