import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const VoiceMuteIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-3.2632 -4.0332 25.48 25.48">
      <path
        d="M13.8047 11.4883V1.49805C13.8047 0.863281 13.3359 0.345703 12.6816 0.345703C12.2129 0.345703 11.9199 0.560547 11.4023 1.01953L7.13477 4.80859L8.17969 5.86328L11.9883 2.40625C12.0371 2.35742 12.0957 2.32812 12.1543 2.32812C12.2227 2.32812 12.2812 2.37695 12.2812 2.46484V9.96484L13.8047 11.4883ZM16.9785 18.5977C17.2715 18.8906 17.7598 18.8906 18.043 18.5977C18.3359 18.2949 18.3359 17.8262 18.043 17.5332L1.32422 0.814453C1.03125 0.521484 0.542969 0.521484 0.25 0.814453C-0.0429688 1.09766 -0.0429688 1.5957 0.25 1.87891L16.9785 18.5977ZM3.9707 12.9238H6.76367C6.85156 12.9238 6.92969 12.9531 6.99805 13.0117L11.4023 16.957C11.8809 17.377 12.2324 17.5723 12.7012 17.5723C13.082 17.5723 13.3945 17.3965 13.6191 17.0645L12.4863 15.9414L7.76953 11.7129C7.57422 11.5371 7.45703 11.498 7.20312 11.498H4.03906C3.66797 11.498 3.50195 11.332 3.50195 10.9707V6.94727L2.33008 5.77539C2.10547 6.08789 1.97852 6.52734 1.97852 7.10352V10.8438C1.97852 12.2598 2.64258 12.9238 3.9707 12.9238Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default VoiceMuteIcon;
