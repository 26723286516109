import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ArrowRightIcon: React.FC<any> = (props = {}): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-5.4396 -0.7609 18.95 18.95">
      <path d="M10.5254 8.79297C10.5254 8.54883 10.4277 8.32422 10.2422 8.14844L2.50781 0.570312C2.33203 0.404297 2.11719 0.316406 1.86328 0.316406C1.36523 0.316406 0.974609 0.697266 0.974609 1.20508C0.974609 1.44922 1.07227 1.67383 1.22852 1.83984L8.33789 8.79297L1.22852 15.7461C1.07227 15.9121 0.974609 16.127 0.974609 16.3809C0.974609 16.8887 1.36523 17.2695 1.86328 17.2695C2.11719 17.2695 2.33203 17.1816 2.50781 17.0059L10.2422 9.4375C10.4277 9.25195 10.5254 9.03711 10.5254 8.79297Z" />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
