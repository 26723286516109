import { getAuthStoreState } from '@amazd/common/utils/auth';

import { useAuthStore } from './useAuthStore';

export function useAuthInfo() {
  const ownUser = useAuthStore((state) => state.user);
  const token = useAuthStore((state) => state.token);

  return {
    token,
    ownUser,
    ownUserType: getAuthStoreState().getUserType(),
    isLoggedIn: !!token,
  };
}
