import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    position: 'absolute',
    right: 32,
    bottom: 33,
    background: 'red',
    width: 352,
    height: 232,
    padding: '20px 32px 32px',
    borderRadius: 18,
    zIndex: 1,
    overflow: 'hidden',
  },
  closeIcon: {
    position: 'absolute',
    right: 12,
    width: 40,
    height: 40,
    top: 6,
    color: 'white',
  },
  scheduledCallText: {
    fontSize: 12,
    lineHeight: 1.35,
    fontWeight: 700,
    color: 'white',
    marginBottom: 24,
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    marginBottom: 28,
  },
  avatarWrapper: {
    flexShrink: 0,
    width: 48,
    height: 48,
    borderRadius: 1000,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#fff',
    backgroundColor: '#D8B4FF',
    display: 'grid',
    placeItems: 'center',
  },
  shopperName: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 16,
    color: 'white',
    lineHeight: 1.333,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionButton: {
    width: 288,
    height: 64,
    borderRadius: 125,
    fontWeight: 400,
    backgroundColor: '#56C087',
    color: 'white',
    fontSize: 20,
    '&:hover': {
      backgroundColor: '#56C087',
      filter: 'brightness(0.8)',
    },
  },
}));
