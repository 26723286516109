import { GET_MY_CALLS, GET_MY_CALLS_FAIL, GET_MY_CALLS_SUCCESS } from '../../actions';
import { Action } from '../../types';
import { CallsState } from '../../types/calls.types';

export const initialState: CallsState = {
  data: null,
  errorMessage: '',
  loading: false,
};

export const callsReducer = (state = initialState, action: Action): CallsState => {
  switch (action.type) {
    case GET_MY_CALLS:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_CALLS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        errorMessage: action.payload,
      };
    case GET_MY_CALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload || [],
      };
    default:
      return state;
  }
};
