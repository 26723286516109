import { put, spawn, takeEvery } from 'redux-saga/effects';

import { getClient } from '../../../apollo';
import { getGraphQlError, reportError } from '../../../utils';
import { GET_AVAILABLE_SLOTS } from '../../actions';
import { getAvailableSlotsFail, getAvailableSlotsSuccess } from '../../actions/amazdDetailsActions';
import { getAvailabilitySlots } from '../../graphql/queries/bookingQueries';
import { Action } from '../../types';

function* getAvailableSlots(action: Action): any {
  const { userTypeId } = action.payload;
  try {
    const res = yield getClient().query({
      query: getAvailabilitySlots,
      variables: {
        args: {
          userTypeId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      fetchPolicy: 'no-cache',
    });
    const { data } = res;

    if (data && data.availableSlots_v2) {
      yield put(getAvailableSlotsSuccess(data.availableSlots_v2));
    }
  } catch (err) {
    yield put(getAvailableSlotsFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* getAvailableSlotsSaga() {
  yield takeEvery(GET_AVAILABLE_SLOTS, getAvailableSlots);
}

export default [getAvailableSlotsSaga()];
