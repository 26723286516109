import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: CustomTheme) => ({
  nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: theme.palette.grey[900],
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 56,
  },
  logo: {
    height: 27,
    width: 85,
  },
  tabletContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 56px)',
  },
  tabletText: {
    marginTop: 32,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    maxWidth: '304px',
  },
  mobileContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 56px)',
  },
  mobileText: {
    marginTop: 56,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    maxWidth: '311px',
  },
  mobileButton: {
    marginTop: 48,
    borderRadius: '100px',
    padding: '13px 24px',
  },
}));
