import { CSSProperties } from 'react';

import { Badge } from '@mui/material';
import { styled } from '@mui/system';

import { colors } from '../../../static';

const StyledBadge = styled(Badge)<{ height: number; badgestyle?: CSSProperties }>(({ height, badgestyle }) => ({
  '& .MuiBadge-dot': {
    left: '55%',
    top: '85%',
    border: `2px solid ${colors.white}`,
    height: Math.max(10, height * 0.35),
    width: Math.max(10, height * 0.35),
    borderRadius: '100%',
    backgroundColor: colors.green100,
    ...badgestyle,
  },
}));

export default StyledBadge;
