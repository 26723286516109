import { useEffect, useState } from 'react';

import { isMobile } from '@amazd/common/utils';
import { useMediaQuery } from '@mui/material';
import { RemoteParticipant } from 'twilio-video';

import { StartCallOptions } from '../../VideoChatView/contexts/videoCallContext';
import { CallActionTypeEnum, CallEvent } from '../types';
import useGetActiveCallEvents from './useGetActiveCallEvents';
import useRingingSubscription from './useRingingSubscription';
import useSendNewCallEvent from './useSendNewCallEvent';

const useRinging = (
  startCall: (amazdId: string, options?: StartCallOptions) => void,
  endCall: (callEventType?: CallActionTypeEnum) => void,
  visible: boolean,
  remoteParticipant?: RemoteParticipant,
  isWidget?: boolean,
) => {
  const [isRingingModalVisible, setRingingModalVisible] = useState(false);

  const [activeRinging, setActiveRinging] = useState<CallEvent | null>(null);

  const [callQueue, setCallQueue] = useState<CallEvent[]>([]);

  const [calledInitialEvents, setCalledInitialEvents] = useState(false);

  const [isMinimized, setMinimized] = useState(false);

  const { data: subscriptionData, ownUser } = useRingingSubscription(calledInitialEvents);

  const { refetch, getActiveCallEvents } = useGetActiveCallEvents();

  const [amazdIdToFilter, setAmazdIdToFilter] = useState<string | null>(null);

  const isFromSameAmazd = (data: CallEvent) => data.amazdId === amazdIdToFilter;

  const updateCallQueue = () => {
    if (callQueue.length > 0) {
      setActiveRinging(callQueue[0]);
      setCallQueue(callQueue.slice(1));
      setRingingModalVisible(true);
    } else {
      setActiveRinging(null);
      setRingingModalVisible(false);
    }
    if (isMinimized) {
      setMinimized(false);
    }
  };

  useEffect(() => {
    if (isWidget && isMobile()) {
      const updateExistingEvents = async () => {
        if (calledInitialEvents) {
          const res = await refetch();

          if (res.data && res.data.getActiveCallEvents) {
            const currentCompaniesEvents = res.data.getActiveCallEvents.filter((event: CallEvent) => {
              return isFromSameAmazd(event);
            });
            const dbAmazdIds = currentCompaniesEvents.map((event: CallEvent) => event.amazdId);

            //if there is an active ringing event, check if it is still active
            if (activeRinging) {
              if (!dbAmazdIds.includes(activeRinging.amazdId)) {
                if (!visible) {
                  setActiveRinging(null);
                  setRingingModalVisible(false);
                }
              }
            } else {
              if (currentCompaniesEvents.length > 0) {
                if (!visible) {
                  setActiveRinging(currentCompaniesEvents[0]);
                  setRingingModalVisible(true);
                }
              }
            }
          }
        }
      };

      if (calledInitialEvents && !visible) {
        window.addEventListener('focus', updateExistingEvents, false);
      }

      if (calledInitialEvents && visible) {
        window.removeEventListener('focus', updateExistingEvents, false);
      }

      return () => window.removeEventListener('focus', updateExistingEvents, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledInitialEvents, activeRinging, visible]);

  const { sendCallEvent } = useSendNewCallEvent();

  const isDesktop = useMediaQuery('(min-width:1024px)');

  const autoAccept = async (activeRinging: CallEvent) => {
    await sendCallEvent(activeRinging.amazdId, CallActionTypeEnum.ACCEPTED);
  };

  useEffect(() => {
    if (isWidget && remoteParticipant) {
      if (activeRinging) {
        autoAccept(activeRinging);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteParticipant]);

  useEffect(() => {
    if (!isDesktop && !isWidget) {
      if (activeRinging) {
        setRingingModalVisible(false);
      }
    } else {
      if (activeRinging && !visible) {
        setRingingModalVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleAccept = async () => {
    if (activeRinging) {
      setRingingModalVisible(false);
      startCall(activeRinging.amazdId);

      await sendCallEvent(activeRinging.amazdId, CallActionTypeEnum.ACCEPTED);
    }
  };

  const handleDecline = async () => {
    if (activeRinging) {
      updateCallQueue();
      await sendCallEvent(activeRinging.amazdId, CallActionTypeEnum.DENIED);
    }
  };

  const handleClosePopup = async (event: any, reason: any) => {
    if (reason === 'backdropClick') {
      return false;
    }
    if (!isMinimized && !isWidget) {
      setMinimized(true);
      return;
    }
    setRingingModalVisible(false);
    setMinimized(false);

    if (!isWidget) {
      setMinimized(false);
    }

    await handleDecline();
  };

  const fetchActiveEvents = async () => {
    const { data } = await getActiveCallEvents();

    if (data && data.getActiveCallEvents.length > 0) {
      let currentCompaniesEvents = [];
      if (isWidget) {
        currentCompaniesEvents = data.getActiveCallEvents.filter((event: CallEvent) => {
          return isFromSameAmazd(event);
        });
      } else {
        currentCompaniesEvents = data.getActiveCallEvents.filter((event: CallEvent) => {
          return event.type !== CallActionTypeEnum.CALLING;
        });
      }

      if (currentCompaniesEvents.length > 0) {
        if (!activeRinging) {
          setActiveRinging(currentCompaniesEvents[0]);
          setCallQueue([...callQueue, ...currentCompaniesEvents.slice(1)]);
          setRingingModalVisible(true);
          setCalledInitialEvents(true);
          return;
        }

        setCallQueue([...callQueue, ...currentCompaniesEvents]);
      }
    }
    setCalledInitialEvents(true);
  };

  useEffect(() => {
    if (ownUser && !calledInitialEvents) {
      if (isWidget && amazdIdToFilter) {
        fetchActiveEvents();
      } else if (!isWidget) {
        fetchActiveEvents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownUser, amazdIdToFilter]);

  //actions that triggers the ringing popup
  const alertActions = [CallActionTypeEnum.CALLING, CallActionTypeEnum.APPOINTMENT];

  useEffect(() => {
    if (subscriptionData) {
      if (isWidget && !isFromSameAmazd(subscriptionData)) {
        return;
      }

      const isSameAmazdEvent = subscriptionData.amazdId === activeRinging?.amazdId;

      if (alertActions.includes(subscriptionData.type)) {
        if (!activeRinging && !visible) {
          setActiveRinging(subscriptionData);
          setRingingModalVisible(true);
          return;
        }

        if (!isSameAmazdEvent) {
          setCallQueue((queue) => [...queue, subscriptionData]);
        }
      } else {
        if (isSameAmazdEvent) {
          if (activeRinging?.type === CallActionTypeEnum.APPOINTMENT) {
            if (subscriptionData.type === CallActionTypeEnum.STOPPED_CALLING) {
              updateCallQueue();
              return;
            }
          } else {
            if ([CallActionTypeEnum.DENIED, CallActionTypeEnum.DID_NOT_PICKUP].includes(subscriptionData.type)) {
              endCall();
            }
            if (subscriptionData.type !== CallActionTypeEnum.ACCEPTED) {
              updateCallQueue();
            }
          }
        }
        if (!isSameAmazdEvent) {
          setCallQueue((queue) => queue.filter((call) => call.amazdId !== subscriptionData.amazdId));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  return {
    isRingingModalVisible,
    handleAccept,
    handleDecline,
    handleClosePopup,
    activeRinging,
    isMinimized,
    updateCallQueue,
    setActiveRinging,
    setAmazdIdToFilter,
  };
};

export default useRinging;
