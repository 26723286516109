import React from 'react';

const CallAvailableIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.04167 10.95C8.06667 9.97499 7.325 8.88332 6.84333 7.77499C6.735 7.53332 6.79333 7.26666 6.97666 7.08582L7.65166 6.40249C8.21 5.83582 8.21 5.05249 7.71833 4.56082L6.735 3.57749C6.07666 2.91916 5.02666 2.91916 4.37666 3.57749L3.82666 4.11916C3.20166 4.73582 2.95166 5.62749 3.11833 6.51082C3.52666 8.68582 4.795 11.0725 6.83583 13.1133C8.87666 15.1467 11.2608 16.4133 13.4358 16.83C14.3192 16.9967 15.2025 16.7383 15.8275 16.1217L16.3692 15.5717C17.0192 14.9133 17.0192 13.8633 16.3692 13.2133L15.3858 12.2358C14.8942 11.7442 14.1025 11.7442 13.6108 12.2358L12.8525 12.9858C12.6692 13.1608 12.3942 13.2275 12.1633 13.1192C11.055 12.6275 9.96333 11.8942 8.98833 10.9192L9.04167 10.95Z"
        stroke={props?.color || '#7E05FE'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallAvailableIcon;
