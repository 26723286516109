import React from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { Box, IconButton, Typography, Button, Portal } from '@mui/material';

import { CloseIcon, JoinCallIcon } from '../../../../icons';
import { getNameInitials } from '../../helpers/';
import { CallActionTypeEnum, CallEvent } from '../../types';
import Avatar from '../Avatar';
import BackgroundGradient from './BackgroundGradient';
import useStyles from './styles';

interface Props {
  handleClose: (event?: any, reason?: any) => void;
  call: CallEvent | null;
  handleJoinCall: () => void;
}

const RingingPortal = ({ handleClose, call, handleJoinCall }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  if (!call) return null;

  const { user } = call;

  const fullName = `${user.firstName} ${user.lastName}`;

  const otherPartiesInitials = getNameInitials(user.firstName, user.lastName);

  return (
    <Portal>
      <Box className={classes.root}>
        <BackgroundGradient />
        <IconButton onClick={() => handleClose()} className={classes.closeIcon}>
          <CloseIcon style={{ fontSize: 23 }} />
        </IconButton>
        <Typography className={classes.scheduledCallText}>
          {call.type === CallActionTypeEnum.APPOINTMENT
            ? t('rp-scheduled-call').toUpperCase()
            : t('rp-instant-call').toUpperCase()}
        </Typography>
        <Box className={classes.nameWrapper}>
          <Box className={classes.avatarWrapper}>
            <Avatar initials={otherPartiesInitials} src={user.avatar as string} isMinimized={true} />
          </Box>
          <Typography className={classes.shopperName}>{fullName}</Typography>
        </Box>
        <Button
          onClick={handleJoinCall}
          startIcon={<JoinCallIcon style={{ marginRight: 5.96, marginLeft: 4, fontSize: 24 }} />}
          className={classes.actionButton}
        >
          {t('rm-join-action')}
        </Button>
      </Box>
    </Portal>
  );
};

export default RingingPortal;
