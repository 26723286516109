import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CloseIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-2.7025 -2.9154 18.85 18.85">
      <path
        d="M0.214794 11.9002C-0.0674326 12.1824 -0.0757333 12.6638 0.214794 12.9461C0.505321 13.2283 0.986767 13.2283 1.26899 12.9461L6.58149 7.63356L11.894 12.9461C12.1762 13.2283 12.666 13.2366 12.9482 12.9461C13.2304 12.6555 13.2304 12.1824 12.9482 11.9002L7.63569 6.57936L12.9482 1.26686C13.2304 0.98463 13.2387 0.503185 12.9482 0.220958C12.6577 -0.0695691 12.1762 -0.0695691 11.894 0.220958L6.58149 5.53346L1.26899 0.220958C0.986767 -0.0695691 0.497021 -0.0778699 0.214794 0.220958C-0.0674326 0.511486 -0.0674326 0.98463 0.214794 1.26686L5.52729 6.57936L0.214794 11.9002Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default CloseIcon;
