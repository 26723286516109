import { useEffect, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import { gql, useLazyQuery } from '@apollo/client';

const userPublicInfoQuery = gql`
  query userPublicInfo($id: String!) {
    userPublicInfo(id: $id) {
      id
      firstName
      lastName
      avatar
    }
  }
`;

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  avatar?: string;
}

export const useParticipantUser = (participantId?: string) => {
  const [user, setUser] = useState<UserInfo>();
  const [getUserInfo, { data }] = useLazyQuery(userPublicInfoQuery);
  const { ownUser, ownUserType } = useAuthInfo();

  useEffect(() => {
    if (participantId) {
      if (participantId === ownUser?.id) {
        setUser({
          id: ownUser?.id,
          firstName: ownUser?.firstName,
          lastName: ownUser?.lastName,
          avatar: ownUserType?.avatar,
        });
      } else if (participantId === data?.userPublicInfo?.id) {
        setUser(data.userPublicInfo);
      } else {
        getUserInfo({
          variables: {
            id: participantId,
          },
        });
      }
    } else {
      setUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantId]);

  useEffect(() => {
    if (data?.userPublicInfo) {
      setUser(data?.userPublicInfo);
    }
  }, [data?.userPublicInfo]);

  return user;
};
