import { useRef } from 'react';

import type { LocalParticipant, RemoteParticipant } from 'twilio-video';

import { useParticipant } from '../hooks/useParticipant';
import { useAttachedTrack } from '../hooks/useParticipantTracks';

const ParticipantAudio = ({ participant }: { participant?: RemoteParticipant | LocalParticipant }) => {
  const audioRef = useRef(null);
  const { audioTrack } = useParticipant(participant);
  useAttachedTrack(audioRef, audioTrack);

  return <audio ref={audioRef} autoPlay={true} />;
};

export default ParticipantAudio;
