export const removeEmptyValues = <T>(obj: T): T => {
  if (!obj || typeof obj !== 'object') return obj;
  const isEmptyValue = (v: any) => v === undefined || v === null || v === '__typename' || v === '';
  const deleteKey = (key: string) => delete (obj as any)[key];

  for (const [key, val] of Object.entries<any>(obj)) {
    if (isEmptyValue(val) && !Array.isArray(obj)) deleteKey(key);
    if (val !== null && typeof val === 'object') removeEmptyValues(val);

    if (val !== null && typeof val === 'object' && !Object.keys(val).length) {
      if (!Array.isArray(obj)) deleteKey(key);
    }
    if (Array.isArray(val)) {
      (obj as any)[key] = val.filter(Boolean);
      if (!(obj as any)[key].length) deleteKey(key);
    }
  }
  return obj;
};

export const isServer = () => typeof window === 'undefined';
