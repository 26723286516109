import { logoutUser } from '@amazd/common/utils/auth';
import {
  ApolloClient,
  ApolloProvider as ApolloLibProvider,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';

import { useAppConfig } from '../utils/env';
import { isDev } from './helpers';
import { createLinks } from './links';

const createLink = ({ backendUrl, debug, appVersion }: Options) => {
  const { authLink, extraHeadersLink, httpLink, wsLink } = createLinks(backendUrl, appVersion);
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const { message, locations, path, extensions } of graphQLErrors) {
        if (isDev()) {
          // eslint-disable-next-line no-console
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        }

        if (
          message.includes('invalid token') ||
          message.includes('invalid signature') ||
          extensions?.code === 'UNAUTHENTICATED'
        ) {
          logoutUser();
          return Observable.of();
        }
      }
    }

    if (networkError && debug) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }

    forward(operation);
  });

  // Regular HTTP connections
  const link = errorLink.concat(authLink.concat(extraHeadersLink.concat(httpLink)));

  // Split the link depending on query kind
  return typeof window === 'undefined'
    ? link
    : split(
        // only create the split in the browser
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink as WebSocketLink,
        link,
      );
};

interface Options {
  debug: boolean;
  backendUrl?: string;
  appVersion?: string;
}

const createApolloClient = (options: Options) => {
  return new ApolloClient({
    connectToDevTools: options.debug,
    cache: new InMemoryCache(),
    ssrMode: true,
    link: createLink(options),
    name: 'Amazd WEB',
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only', // We don't need to use Apollo's cache as we have the Redux Store
        errorPolicy: 'none',
      },
    },
  });
};

const getV2Options = (backendUrl: string) => {
  return {
    debug: process.env.NODE_ENV === 'development',
    backendUrl,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  };
};

const clientStore: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
} = {} as any;

export const getClient = () => clientStore.apolloClient;

const createClientIfNotExist = ({ backendUrl, appVersion }: { backendUrl: string; appVersion?: string }) => {
  if (clientStore.apolloClient) return clientStore.apolloClient;

  const client = createApolloClient({
    ...getV2Options(backendUrl),
    appVersion,
  });

  clientStore.apolloClient = client;

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    (window as any).resetApolloCache = () => {
      client.resetStore();
    };
  }

  return client;
};

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const { env } = useAppConfig();
  const client = createClientIfNotExist({
    backendUrl: env.BACKEND_V2_URL as string,
    appVersion: env.APP_VERSION,
  });

  return <ApolloLibProvider client={client}>{children}</ApolloLibProvider>;
};
