import { useEffect, useRef } from 'react';

import { sleep } from '@amazd/common/utils';

let isSet = false;

export type UseAmplitudeEventsProps = {
  apiKey: string;
  source: string;
  client_url?: string;
  company_handle?: string;
};

export const useAmplitudeEvents = ({ apiKey, source, client_url, company_handle }: UseAmplitudeEventsProps) => {
  // const widgetConfig = useWidgetConfig();
  const amplitudeRef = useRef<typeof import('@amazd/common/utils/analytics/amplitude')['tracking']>();
  const messagesQueue = useRef<any[]>([]);

  // Listen Amplitude events from parent window
  useEffect(() => {
    // we only want to listen (and consequently trigger) once.
    if (isSet) {
      return;
    }
    isSet = true;

    if (!amplitudeRef.current) {
      (async () => {
        // Amplitude is around 78kb. Defer it to let more important requests complete faster.
        // After delay all pending events in the queue going to be send in one batched request.
        await sleep(3);
        const { tracking } = await import('@amazd/common/utils/analytics/amplitude');
        tracking.initAmplitude(apiKey);

        amplitudeRef.current = tracking;

        if (messagesQueue.current.length) {
          for (const msg of messagesQueue.current) {
            amplitudeRef.current.amplitudeEventListener(msg, {
              source,
              client_url,
              company_handle,
            });
          }
          messagesQueue.current = [];
        }
      })();
    }

    function listenIframeMessages(msg: any) {
      if (!amplitudeRef.current?.amplitudeEventListener) {
        messagesQueue.current.push(msg);
      } else {
        return amplitudeRef.current.amplitudeEventListener(msg, {
          source,
          client_url,
          company_handle,
        });
      }
    }

    window.addEventListener('message', listenIframeMessages, false);

    return function () {
      isSet = false;
      window.removeEventListener('message', listenIframeMessages, false);
    };
  }, [apiKey, source, client_url, company_handle]);
};
