import { Company } from '../redux/types';

export enum AvatarType {
  File = 'FILE',
  Unknown = 'UNKNOWN',
  Url = 'URL',
}

export enum UserRoleEnum {
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  USER = 'USER',
  ROOT = 'ROOT',
  ADMIN = 'ADMIN',
}

export enum LocaleEnum {
  ENGLISH = 'en',
  GERMAN = 'de',
  DUTCH = 'nl',
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  role: UserRoleEnum;
  internal: boolean;
  spokenLanguages: string[];
  userTypes?: UserType[];
  phone?: string;
  availableForCalls?: boolean;
};

export type Expert = {
  id: string;
  avatar: string;
  companyDescription: string;
  companyHandle: string;
  companyName: string;
  handle: string;
  description: string;
  firstName: string;
  lastName: string;
  isAvailable: boolean;
  nextAvailability: number;
};

// export type UserTypeShort = {
//   id: string;
//   companyId: string;
//   isExpert: boolean;
//   amazdsCount: number;
//   user: User;
//   avatar: string;
//   description?: string;
//   locale: string;
//   handle: string;
//   company?: Company;
// };

export type UserType = {
  amazdsCount?: number;
  avatar?: string;
  avatarType?: AvatarType;
  code?: string;
  company?: Company;
  companyId?: string;
  description?: string;
  facebook?: string;
  handle: string;
  id: string;
  instagram?: string;
  isExpert: boolean;
  locale?: LocaleEnum;
  phone?: string;
  profileUrl?: string;
  status?: string;
  twitter?: string;
  updatedAt?: Date;
  createdAt?: Date;
  user?: User;
  userId?: string;
};

export type UserUpdateDto = {
  firstName?: string;
  lastName?: string;
  email?: string;
  avatar?: string;
  role?: UserRoleEnum;
  handle?: string;
  locale?: LocaleEnum;
  timezone?: string;
  description?: string;
  phone?: string;
  companyId?: string | null;
  internal?: boolean;
  spokenLanguages?: string[];
  isExpert?: boolean;
  tags?: string[];
  disabled?: boolean;
};
