export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PaginationDto = {
  page: number;
  count: number;
  order?: OrderEnum;
};
