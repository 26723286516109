import { useTranslation } from '@amazd/common/utils/i18n';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import Loader from '../../Loader';
import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    position: 'absolute',
    top: 'calc(50% - 150)',
    '&.isWidgetScreen': {
      top: '25%',
    },
  },
  text: {
    color: 'white',
    fontSize: '22px',
    lineHeight: 1.5,
    textAlign: 'center',
    maxWidth: '90%',
    '&.minimized': {
      fontSize: '14px',
    },
    '&.isWidgetScreen': {
      fontSize: '14px',
    },
  },
}));

const StatusMessage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    room,
    minimized,
    connecting,
    remoteParticipant,
    presenting,
    isWidgetScreen,
    devicePermissionDenied,
    connectionError,
  } = useVideoCallContext();

  const containerClasses = clsx(classes.container, { isWidgetScreen });
  const textClasses = clsx(classes.text, { isWidgetScreen, minimized });
  const loaderProps = {
    width: 270,
    height: 150,
    ...(isWidgetScreen && {
      width: 180,
      height: 90,
    }),
    ...(minimized && {
      width: 100,
      height: 60,
    }),
  };

  if (remoteParticipant || presenting) {
    return <></>;
  }

  let statusMessage = '';
  let loading = true;

  if (connecting) {
    statusMessage = t('video-call-connecting');
    loading = true;
  } else if (devicePermissionDenied) {
    statusMessage = t('video-call-check-camera-and-mic-permission');
    loading = false;
  } else if (connectionError) {
    statusMessage = t('video-call-connection-error');
    loading = false;
  } else if (room && !connecting && !remoteParticipant) {
    statusMessage = t('call-window-waiting-for-user');
    loading = true;
  }

  return (
    <div className={containerClasses}>
      {loading && <Loader {...loaderProps} />}
      <span className={textClasses}>{statusMessage}</span>
    </div>
  );
};

export default StatusMessage;
