import { eventChannel } from 'redux-saga';
import { all, put, spawn, take, takeLatest } from 'redux-saga/effects';

import { reportError } from '../../../utils';
import { notificationsActions, realtimeEventsActions, ON_WSS_RECONNECTED } from '../../actions';

function* onWssConnectionReconnected() {
  yield put(notificationsActions.subscribeToNotifications());
}

function* onWssConnectionReconnectedSaga() {
  yield takeLatest(ON_WSS_RECONNECTED, onWssConnectionReconnected);
}

function* networkStatusSaga(): any {
  // This basically suspends the saga until the store gets it's first action
  yield take();
  if (typeof window !== 'undefined') {
    if (process.env.NODE_ENV === 'development') {
      console.log('Listens to network changes');
    }
    const channel = eventChannel((emit) => {
      const wentOffline = () => {
        if (process.env.NODE_ENV === 'development') {
          console.log('User went offline');
        }
        emit(false);
      };
      const wentOnline = () => {
        if (process.env.NODE_ENV === 'development') {
          console.log('User went online');
        }
        emit(true);
      };
      window.addEventListener('offline', wentOffline);
      window.addEventListener('online', wentOnline);

      return () => {
        window.removeEventListener('offline', wentOffline);
        window.removeEventListener('online', wentOnline);
      };
    });

    while (true) {
      try {
        const isOnline = yield take(channel);
        // If it's got online then it means it's reconnected
        if (isOnline) {
          yield put(realtimeEventsActions.onNetworkReconnected());
        }
        yield put(realtimeEventsActions.updateOnlineStatus(isOnline));
      } catch (err) {
        channel.close();
        yield spawn(reportError, err);
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([networkStatusSaga(), onWssConnectionReconnectedSaga()]);
}
