import type { RemoteParticipant, LocalParticipant, VideoTrack } from 'twilio-video';

import { getPersonalizedUserColor } from '../../../utils/color';
import { useVideoCallContext } from '../contexts/videoCallContext';
import { useTracks } from '../hooks/useParticipantTracks';
import { useParticipantUser } from '../hooks/useParticipantUser';

export const useParticipant = (participant?: RemoteParticipant | LocalParticipant) => {
  const { localParticipant, remoteParticipant, presentingParticipant } = useVideoCallContext();
  const { videoTrack, audioTrack, screenTrack } = useTracks(participant);

  const participantUser = useParticipantUser(participant?.identity);
  const { colorBg: participantColor } = getPersonalizedUserColor(participantUser);

  const presenting = presentingParticipant && participant?.identity === presentingParticipant.identity;
  const localPresenting = presentingParticipant && localParticipant?.identity === presentingParticipant.identity;
  const isLocal = localParticipant && participant?.identity === localParticipant?.identity;
  const isRemote = remoteParticipant && participant?.identity === remoteParticipant?.identity;

  const hasVideo = videoTrack && videoTrack.isEnabled && videoTrack.isStarted;
  const { width, height } = (videoTrack as VideoTrack)?.dimensions || {};
  const videoAcceptRatio = width && height ? width / height : 0;
  const portraitMode = videoTrack && (width || 0) - (height || 0) < 0;
  const landscapeMode = !portraitMode;

  const muted = !audioTrack || !audioTrack?.isStarted || !audioTrack.isEnabled;

  return {
    participantUser,
    participantColor,
    videoTrack,
    audioTrack,
    screenTrack,
    hasVideo,
    videoAcceptRatio,
    portraitMode,
    landscapeMode,
    muted,
    presenting,
    localPresenting,
    isLocal,
    isRemote,
  };
};
