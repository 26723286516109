import React, { createContext, useContext, useEffect, useRef, useReducer } from 'react';

import Head from 'next/head';

export const HeadContext = createContext<{
  title: string;
  changeTitle: (title: string) => void;
}>({ changeTitle: () => null, title: '' });

export function HeadProvider(props: { children: React.ReactNode }) {
  const [, forceRerender] = useReducer((x) => x + 1, 0);
  const titleRef = useRef('');

  useEffect(() => {
    // https://github.com/vercel/next.js/discussions/17717 | https://github.com/vercel/next.js/issues/28671
    const keepTitle = () => {
      setTimeout(() => (document.title = titleRef.current), 10);
    };
    window.addEventListener('focus', keepTitle);
    window.addEventListener('blur', keepTitle);

    return () => {
      window.removeEventListener('focus', keepTitle);
      window.removeEventListener('blur', keepTitle);
    };
  }, []);

  const changeTitle = (title: string) => {
    if (title == titleRef.current) return;
    titleRef.current = title;
    forceRerender();
    setTimeout(() => (document.title = titleRef.current), 10);
  };

  return (
    <HeadContext.Provider
      value={{
        title: titleRef.current,
        changeTitle,
      }}
    >
      <Head>
        <title>{titleRef.current}</title>
        <link rel="icon" href="/amazd-logo.ico" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      {props.children}
    </HeadContext.Provider>
  );
}

export function useHead() {
  return useContext(HeadContext);
}
