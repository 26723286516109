import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import type { LocalParticipant, RemoteParticipant } from 'twilio-video';

import { MicMuteIcon, RecordingMicIcon } from '../../../icons';
import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';

const useStyles = makeStyles(() => ({
  badge: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(60px)',
    borderRadius: '8px',
    color: 'white',
    padding: '4px 8px 4px 4px',
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    '&.minimized': {
      padding: '8px',
      bottom: '8px',
    },
    '&.fullScreenMode': {
      padding: '8px 12px 8px 16px',
      bottom: '40px',
      left: '24px',
    },
    '&.minimized.fullScreenMode': {
      bottom: '16px',
      left: '16px',
      padding: '8px',
    },
    '&.isWidgetScreen.fullScreenMode': {
      top: '32px',
      bottom: 'unset',
      left: 'unset',
    },
  },
  userName: {
    fontSize: '14px',
    marginLeft: '8px',
    '&.fullScreenMode': {
      fontSize: '18px',
    },
    '&.isWidgetScreen.fullScreenMode': {
      fontSize: '14px',
    },
    '&.minimized': {
      marginLeft: 'unset',
    },
  },
  micIcon: {
    width: '18px',
    height: '18px',
    '&.minimized': {
      width: '14px',
      height: '14px',
    },
    '&.fullScreenMode': {
      width: '24px',
      height: '24px',
    },
    '&.isWidgetScreen.fullScreenMode': {
      width: '18px',
      height: '18px',
    },
  },
}));

export interface PropsType {
  participant?: RemoteParticipant | LocalParticipant;
  fullScreenMode?: boolean;
}

const ParticipantBadge = ({ participant, fullScreenMode }: PropsType) => {
  const { minimized, isWidgetScreen } = useVideoCallContext();
  const { muted, participantUser } = useParticipant(participant);

  const classes = useStyles();
  const badgeClassName = clsx(classes.badge, { fullScreenMode, minimized, isWidgetScreen });
  const userNameClassName = clsx(classes.userName, { fullScreenMode, minimized, isWidgetScreen });
  const micIconClasses = clsx(classes.micIcon, { fullScreenMode, minimized, isWidgetScreen });

  return (
    <div className={badgeClassName}>
      {muted ? <MicMuteIcon className={micIconClasses} /> : <RecordingMicIcon className={micIconClasses} />}
      {!minimized && participantUser && (
        <span className={userNameClassName}>{`${participantUser.firstName} ${participantUser.lastName}`}</span>
      )}
    </div>
  );
};

export default ParticipantBadge;
