import React from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    paddingLeft: '24px',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '112px',
    '&.presenting': {
      position: 'unset',
      paddingRight: '24px',
      paddingBottom: '96px',
      paddingTop: 0,
      '& > div:first-child': {
        marginBottom: '16px',
      },
    },
    '&.presenting.minimized': {
      paddingRight: '16px',
      paddingTop: '0px',
      '& > div:first-child': {
        marginBottom: '8px',
      },
    },
    '&.minimized': {
      padding: '16px',
    },
    '&.isWidgetScreen': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      paddingRight: '16px',
    },
    '&.presenting.isWidgetScreen': {
      paddingTop: '16px',
      paddingBottom: '80px',
      paddingLeft: '16px',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    '&.presenting.isWidgetScreen.remoteParticipant': {
      justifyContent: 'space-between',
    },
  },
}));

const SecondaryLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { root } = useStyles();
  const { minimized, presenting, isWidgetScreen, remoteParticipant } = useVideoCallContext();

  const classes = clsx(root, { minimized, isWidgetScreen, presenting, remoteParticipant: !!remoteParticipant });

  return <div className={classes}>{children}</div>;
};

export default SecondaryLayout;
