import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MoreIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon
      className={props?.className ? props.className : ''}
      style={props?.style ? { ...props.style, color: undefined } : {}}
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props ? props.color || 'inherit' : 'inherit'}
        d="M3.86494 1.96832C3.86494 1.12522 3.18134 0.449219 2.32305 0.449219C1.48755 0.449219 0.803955 1.12522 0.803955 1.96832C0.803955 2.81142 1.48755 3.48741 2.32305 3.48741C3.18134 3.48741 3.86494 2.81142 3.86494 1.96832ZM9.4856 1.96832C9.4856 1.12522 8.8096 0.449219 7.9665 0.449219C7.131 0.449219 6.455 1.12522 6.455 1.96832C6.455 2.81142 7.131 3.48741 7.9665 3.48741C8.8096 3.48741 9.4856 2.81142 9.4856 1.96832ZM15.129 1.96832C15.129 1.12522 14.453 0.449219 13.6099 0.449219C12.7517 0.449219 12.0757 1.12522 12.0757 1.96832C12.0757 2.81142 12.7517 3.48741 13.6099 3.48741C14.453 3.48741 15.129 2.81142 15.129 1.96832Z"
      />
    </SvgIcon>
  );
};

export default MoreIcon;
