import { makeStyles } from '@mui/styles';

import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';
import BackgroundGradient from './BackgroundGradient';
import ParticipantAudio from './ParticipantAudio';
import ParticipantAvatar from './ParticipantAvatar';
import ParticipantBadge from './ParticipantBadge';
import ParticipantVideo from './ParticipantVideo';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    minHeight: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.4)',
    height: '100%',
    width: '100%',
  },
}));

const RemoteParticipant = (): JSX.Element => {
  const { minimized, presenting, remoteParticipant } = useVideoCallContext();
  const { participantColor, hasVideo } = useParticipant(remoteParticipant);

  const classes = useStyles();

  if (!remoteParticipant || presenting) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <BackgroundGradient color={participantColor} />
      {hasVideo ? (
        <ParticipantVideo participant={remoteParticipant} />
      ) : (
        <ParticipantAvatar participant={remoteParticipant} fullScreenMode={true} />
      )}
      {!minimized && <ParticipantBadge participant={remoteParticipant} fullScreenMode={true} />}
      <ParticipantAudio participant={remoteParticipant} />
    </div>
  );
};

export default RemoteParticipant;
