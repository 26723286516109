import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FullScreenIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M16.3333 5.66666H20.3333V3.66666H16.3333V5.66666ZM22.3333 7.66666V11.6667H24.3333V7.66666H22.3333ZM20.3333 5.66666C21.4379 5.66666 22.3333 6.56209 22.3333 7.66666H24.3333C24.3333 5.45752 22.5425 3.66666 20.3333 3.66666V5.66666Z"
        stroke={props.color || 'inherit'}
      />
      <path
        d="M11.6667 22.3333L7.66666 22.3333L7.66666 24.3333L11.6667 24.3333L11.6667 22.3333ZM5.66666 20.3333L5.66666 16.3333L3.66666 16.3333L3.66666 20.3333L5.66666 20.3333ZM7.66666 22.3333C6.56209 22.3333 5.66666 21.4379 5.66666 20.3333L3.66666 20.3333C3.66666 22.5425 5.45752 24.3333 7.66666 24.3333L7.66666 22.3333Z"
        stroke={props.color || 'inherit'}
      />
      <path
        d="M5.66666 11.6667L5.66666 7.66666L3.66666 7.66666L3.66666 11.6667L5.66666 11.6667ZM7.66666 5.66666L11.6667 5.66666L11.6667 3.66666L7.66666 3.66666L7.66666 5.66666ZM5.66666 7.66666C5.66666 6.56209 6.56209 5.66666 7.66666 5.66666L7.66666 3.66666C5.45752 3.66666 3.66666 5.45752 3.66666 7.66666L5.66666 7.66666Z"
        stroke={props.color || 'inherit'}
      />
      <path
        d="M22.3333 16.3333L22.3333 20.3333L24.3333 20.3333L24.3333 16.3333L22.3333 16.3333ZM20.3333 22.3333L16.3333 22.3333L16.3333 24.3333L20.3333 24.3333L20.3333 22.3333ZM22.3333 20.3333C22.3333 21.4379 21.4379 22.3333 20.3333 22.3333L20.3333 24.3333C22.5425 24.3333 24.3333 22.5425 24.3333 20.3333L22.3333 20.3333Z"
        stroke={props.color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default FullScreenIcon;
