import { UPDATE_ONLINE_STATUS, ON_NETWORK_RECONNECTED, ON_WSS_RECONNECTED } from '../../actions/constants';
import { Action } from '../../types';

export function updateOnlineStatus(isOnline: boolean): Action {
  return { type: UPDATE_ONLINE_STATUS, payload: isOnline };
}

export function onNetworkReconnected(): Action {
  return { type: ON_NETWORK_RECONNECTED };
}

export function onWssReconnected(): Action {
  return { type: ON_WSS_RECONNECTED };
}
