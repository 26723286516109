import React, { useEffect, useRef, useState } from 'react';

import { Avatar, AvatarProps, Typography } from '@mui/material';

import { getOptimizedImgUrl, getPersonalizedUserColor } from '../../utils';
import StyledBadge from './components/StyledBadge';

export interface UserAvatarProps extends AvatarProps {
  size: number;
  firstName?: string;
  lastName?: string;
  user?: { firstName: string; lastName: string; avatar?: string };
  userType?: { avatar?: string | null; user?: { firstName: string; lastName: string } };
  online?: boolean;
  badgestyle?: React.CSSProperties;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  style,
  src,
  userType,
  user,
  firstName: firstNameArg,
  lastName: lastNameArg,
  size,
  online,
  badgestyle,
  ...rest
}: UserAvatarProps) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const imgSrc = user?.avatar || userType?.avatar || src || '';
  const firstName = user?.firstName || userType?.user?.firstName || firstNameArg || null;
  const lastName = user?.lastName || userType?.user?.lastName || lastNameArg || null;
  const initials = [firstName, lastName].map((s) => (s && s.length > 0 ? s[0].toUpperCase() : '')).join('') || null;
  const { colorBg, colorInitial } = getPersonalizedUserColor({ firstName, lastName });

  useEffect(() => {
    setHeight((ref.current as any)?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(ref.current as any)?.clientHeight]);

  let optimalSize;

  switch (true) {
    case size <= 32:
      optimalSize = 64;
      break;

    case size <= 64:
      optimalSize = 128;
      break;

    case size <= 128:
      optimalSize = 256;
      break;

    default:
      optimalSize = 512;
  }

  const avatarProps = {
    src: getOptimizedImgUrl({
      url: imgSrc,
      width: optimalSize,
      height: optimalSize,
    }),
    style: {
      color: initials ? colorInitial : undefined,
      fontFamily: 'FilsonPro',
      fontWeight: 500,
      fontSize: height ? (height < 40 ? height * 0.33 : height * 0.3) : 16,
      backgroundColor: initials ? colorBg : undefined,
      ...style,
      width: size,
      height: size,
    },
    ...rest,
  };

  return (
    <StyledBadge
      variant="dot"
      overlap="circular"
      badgestyle={badgestyle && { ...badgestyle }}
      invisible={!online}
      height={height}
    >
      <Avatar ref={ref} {...avatarProps}>
        <Typography
          component="span"
          style={{
            paddingTop: height ? height * 0.04 : 1,
          }}
        >
          {initials}
        </Typography>
      </Avatar>
    </StyledBadge>
  );
};

export default UserAvatar;
