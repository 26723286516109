import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const InformationIcon = (props: any) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <circle cx="10" cy="10" r="10" fill="#D7D9E7" />
      <g clipPath="url(#clip0_2958_176395)">
        <path
          d="M9.86707 14.9888C9.7017 14.9734 9.5388 14.9389 9.38178 14.886C8.71867 14.6691 8.39744 14.1382 8.46398 13.4328C8.51905 12.8818 8.69343 12.362 8.84372 11.8332C8.9768 11.3481 9.11332 10.8641 9.23952 10.3801C9.3039 10.1495 9.31527 9.90787 9.27279 9.6725C9.25586 9.58603 9.21341 9.50624 9.15065 9.44291C9.08789 9.37957 9.00754 9.33544 8.91944 9.31592C8.64104 9.25969 8.35228 9.27706 8.0831 9.36622L7.77563 9.46347C7.82267 9.27568 7.86512 9.10353 7.91101 8.93362C7.91643 8.92083 7.92438 8.90922 7.93442 8.89943C7.94447 8.88965 7.9564 8.8819 7.96952 8.87662C8.42842 8.70671 8.87585 8.5178 9.35884 8.42949C9.84183 8.34118 10.3076 8.35683 10.7527 8.57033C10.9759 8.68447 11.1599 8.85966 11.2822 9.07434C11.4045 9.28901 11.4597 9.53384 11.4411 9.7787C11.4124 10.4617 11.1692 11.091 10.9983 11.7393C10.8835 12.1664 10.7688 12.5911 10.6541 13.0192C10.6239 13.135 10.6051 13.2533 10.5979 13.3725C10.593 13.4662 10.6079 13.5599 10.6418 13.6477C10.6757 13.7355 10.7278 13.8156 10.7949 13.8829C10.8619 13.9503 10.9425 14.0034 11.0316 14.039C11.1207 14.0747 11.2163 14.0921 11.3126 14.0901C11.5602 14.0829 11.8048 14.0357 12.0365 13.9504L12.1444 13.9124C12.0973 14.0991 12.0549 14.2746 12.009 14.4489C11.997 14.4712 11.977 14.4883 11.9528 14.497C11.5925 14.6278 11.2334 14.7653 10.8675 14.8838C10.6959 14.9333 10.52 14.9673 10.342 14.9855C10.1842 15.0014 10.0251 15.0026 9.86707 14.9888V14.9888Z"
          fill="white"
        />
        <path
          d="M11.0176 7.34742C11.6829 7.34742 12.2222 6.82193 12.2222 6.17371C12.2222 5.52549 11.6829 5 11.0176 5C10.3523 5 9.81299 5.52549 9.81299 6.17371C9.81299 6.82193 10.3523 7.34742 11.0176 7.34742Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2958_176395">
          <rect width="4.44444" height="10" fill="white" transform="translate(7.77783 5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default InformationIcon;
