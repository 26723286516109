import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PortraitToLandscapeIcon: React.FC<any> = (): React.ReactElement => (
  <SvgIcon style={{ width: '56', height: '56', fill: 'none' }} viewBox="0 0 56 56">
    <path
      d="M16.3334 37.3333L18.6667 37.3333"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 35.0002C28 36.2882 26.9547 37.3335 25.6667 37.3335"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M28 28L28 25.6667" stroke="#7E05FE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28 18.6665L28 16.3332" stroke="#7E05FE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.6667 6.99984L16.3334 6.99984"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99998 9.3335C6.99998 8.04549 8.04531 7.00016 9.33331 7.00016"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 9.3335C28 8.04549 26.9547 7.00016 25.6667 7.00016"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99998 35.0002C6.99998 36.2882 8.04531 37.3335 9.33331 37.3335"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00002 16.3332L7.00002 18.6665"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00002 28L7.00002 25.6667"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6667 46.6667L46.6667 30.3333C46.6667 29.0453 45.6214 28 44.3334 28L18.6667 28C17.3787 28 16.3334 29.0453 16.3334 30.3333L16.3334 46.6667C16.3334 47.9547 17.3787 49 18.6667 49L44.3334 49C45.6214 49 46.6667 47.9547 46.6667 46.6667Z"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3334 7.00016L42 7.00016C44.5784 7.00016 46.6667 9.08849 46.6667 11.6668L46.6667 16.3335"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1667 15.1665L46.6667 18.6665L50.1667 15.1665"
      stroke="#7E05FE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PortraitToLandscapeIcon;
