import React from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    paddingLeft: 'unset',
    '&.presenting': {
      position: 'unset',
      top: 'unset',
      paddingLeft: '16px',
    },
    '&.isWidgetScreen': {
      // paddingRight: '24px',
    },
    '&.presenting.isWidgetScreen': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
}));

const MainLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { root } = useStyles();
  const { minimized, presenting, isWidgetScreen } = useVideoCallContext();

  const classes = clsx(root, { minimized, isWidgetScreen, presenting });

  return <div className={classes}>{children}</div>;
};

export default MainLayout;
