import { UserType } from '@amazd/common/types';
import { EventChannel } from 'redux-saga';

export enum NotificationEvent {
  APPOINTMENT_CANCELED = 'APPOINTMENT_CANCELED',
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  INCOMING_VIDEO_CALL = 'INCOMING_VIDEO_CALL',
  JOIN_VIDEO_CALL_NOW = 'JOIN_VIDEO_CALL_NOW',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_PROPOSAL = 'NEW_PROPOSAL',
  REMIND_BEFORE_APPOINTMENT = 'REMIND_BEFORE_APPOINTMENT',
  AMAZD_REASSIGNMENT = 'AMAZD_REASSIGNMENT',
  UNKNOWN = 'UNKNOWN',
  SURVEY_ANSWERED = 'SURVEY_ANSWERED',
}
export enum NotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
}

enum NotificationCategory {
  AMAZD,
  APPOINTMENT,
  MESSAGE,
  PRODUCT,
  VIDEO_CALL,
}

export enum Locale {
  ENGLISH = 'ENGLISH',
  GERMAN = 'GERMAN',
}

export enum LocalKeys {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en = Locale.ENGLISH,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  de = Locale.GERMAN,
}

export interface NotificationMessages {
  content: string;
  createdAt: string;
  id: string;
  locale: Locale;
  notificationId: string;
  title: string;
  updatedAt: string;
}

export interface Notification {
  readonly id: string;
  readonly category: NotificationCategory;
  readonly creatorAsUserType: UserType;
  readonly receiverAsUserType: UserType;
  readonly createdAt: string;
  readonly data: string;
  readonly event: NotificationEvent;
  readonly status: NotificationStatus;
  readonly messages: NotificationMessages[];
}

export interface NotificationList {
  readonly data: Notification[];
  readonly page: number;
  readonly errorMessage?: string;
  readonly loading: boolean;
}

export interface NotificationsState {
  readonly allNotifications: NotificationList;
  readonly newNotificationsCount: number;
  readonly errorMessage: string | null;
  readonly notificationsChannel: EventChannel<any> | null;
}
