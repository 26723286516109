import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const BookmarkIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="4.7944 5.9808 25.22 25.22">
      <path
        d="M11.773 28.908c.479 0 .762-.273 1.641-1.123l3.818-3.76c.05-.048.137-.048.176 0l3.819 3.76c.878.85 1.162 1.123 1.64 1.123.654 0 1.035-.43 1.035-1.191V10.764c0-1.856-.927-2.803-2.763-2.803h-7.637c-1.836 0-2.764.947-2.764 2.803v16.953c0 .762.381 1.191 1.035 1.191zm.801-2.637c-.136.137-.293.098-.293-.097v-15.39c0-.84.44-1.28 1.3-1.28h7.49c.849 0 1.288.44 1.288 1.28v15.39c0 .195-.146.234-.293.098l-4.228-4.102c-.313-.303-.723-.303-1.035 0l-4.229 4.101z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default BookmarkIcon;
