import { getClient } from '@amazd/common/apollo';
import { trackIdentify } from '@amazd/common/utils/analytics';
import { IFrameInteractions } from '@amazd/common/utils/browser';

import { refreshTokenMutation } from '../graphql';
import { getAuthStoreState } from '../store';
import { SignUpDto } from '../types';

export async function refreshToken() {
  try {
    console.log('Refreshing token... ');

    const refreshRetryCount = getAuthStoreState().refreshRetryCount;
    if (refreshRetryCount > 3 * 60) {
      // 3 hours * 60 times per hour, the interval set to run every 1 minute
      console.log('Refresh token retry limit is exceeded, triggering user logout...');
      logoutUser();
      return;
    }

    const res = await getClient().mutate({
      mutation: refreshTokenMutation,
      context: {
        headers: { useRefreshToken: true },
      },
    });

    const newToken = res?.data?.tokenRefresh.accessToken;
    if (newToken) {
      getAuthStoreState().updateStore({
        token: newToken,
        refreshRetryCount: 0,
      });
      console.log('Token successfully refreshed.');
    } else {
      throw new Error('Refresh token mutations unable to provide new token.');
    }
  } catch (error) {
    console.error('Error on refresh token', error);
  }
}

export function onLoginFail(message: string) {
  const state = getAuthStoreState();
  state.updateStore({
    errorMessage: message,
  });
}

export function onLoginSuccess(args: SignUpDto) {
  getAuthStoreState().updateStore({
    user: {
      ...args.user,
      userTypes: [...args.userTypes],
    },
    refreshToken: args.refreshToken,
    token: args.accessToken,
  });

  trackIdentify(args.user?.id, {
    role: args.user?.role,
  });
}

export function logoutUser() {
  localStorage.removeItem('auth-storage');
  sessionStorage.removeItem('tempAuth');
  getAuthStoreState().resetStore();

  // unregister service workers
  try {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  } catch (error) {
    console.error('Error on unregister service worker', error);
  }

  IFrameInteractions.postMessage('logout');
  getClient().cache.reset();
}
