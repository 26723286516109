import React from 'react';

import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: 'black',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    '&.isWidgetScreen': {
      justifyContent: 'flex-end',
    },
  },
}));

const WidgetLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { root } = useStyles();
  return <div className={root}>{children}</div>;
};

export default WidgetLayout;
