import React from 'react';

import { SvgIcon } from '@mui/material';

const DeclineCallIcon = (props: any) => {
  return (
    <SvgIcon width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1227_5092)">
        <path
          d="M11.9144 11.3751C13.569 11.3751 15.1247 11.672 16.4738 12.2038C16.7708 12.3169 16.9476 12.5927 16.9455 12.9017L16.9525 14.0543C16.9596 15.0089 17.6243 15.6736 18.4587 15.6736L20.1274 15.6736C21.2447 15.6736 22.1356 14.7826 22.1286 13.6724L22.1356 12.7461C22.1427 11.6925 21.5982 10.7238 20.7073 10.1157C18.5152 8.61663 15.4139 7.66769 11.9505 7.66769C8.49341 7.67405 5.39558 8.62228 3.19648 10.1143C2.30552 10.7224 1.77519 11.6911 1.76812 12.7447L1.77519 13.671C1.78226 14.7812 2.67322 15.6721 3.7763 15.6721L5.44013 15.6672C6.27451 15.6672 6.94626 14.9954 6.94626 14.1611L6.95334 12.8812C6.96041 12.5771 7.13718 12.2872 7.42498 12.1833C8.78262 11.66 10.3312 11.356 11.9858 11.356L11.9144 11.3751Z"
          stroke="white"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1227_5092">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DeclineCallIcon;
