import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AmazdIconWidget: React.FC<any> = (): React.ReactElement => {
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8334 5.09998C9.02258 5.09998 5.93335 8.18921 5.93335 12C5.93335 15.8107 9.02258 18.9 12.8333 18.9C16.6441 18.9 19.7333 15.8107 19.7334 12C19.7334 8.18921 16.6441 5.09998 12.8334 5.09998ZM12.8333 7.49997C10.3481 7.49997 8.33335 9.51469 8.33335 12C8.33335 14.4853 10.3481 16.5 12.8333 16.5C15.3186 16.5 17.3333 14.4853 17.3333 12C17.3333 9.51469 15.3186 7.49997 12.8333 7.49997Z"
        fill="url(#paint0_angular)"
      />
      <rect x="17.3333" y="5.39996" width="2.4" height="13.2" fill="black" />
      <circle cx="12.8333" cy="21.9" r="1.5" fill="#979797" />
      <circle cx="22.4333" cy="8.40002" r="1.5" fill="#979797" />
      <ellipse cx="5.63333" cy="5.09998" rx="1.5" ry="1.5" fill="#979797" />
      <defs>
        <radialGradient
          id="paint0_angular"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8333 12) scale(6.9)"
        >
          <stop />
          <stop offset="0.130208" />
          <stop offset="0.755208" stopColor="#979797" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default AmazdIconWidget;
