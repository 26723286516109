import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CopyIcon: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon {...props} viewBox="-0.6174 0.1473 20.5 20.5">
    <path
      d="M0.992188 17.7266C0.992188 19.3594 1.79688 20.1797 3.41406 20.1797H10.7578C12.375 20.1797 13.1797 19.3516 13.1797 17.7266V16.2812H14.5469C16.1562 16.2812 16.9688 15.4531 16.9688 13.8281V7.48438C16.9688 6.52344 16.7734 5.91406 16.1875 5.3125L12.2422 1.30469C11.6875 0.734375 11.0312 0.523438 10.1953 0.523438H7.20312C5.59375 0.523438 4.78125 1.35156 4.78125 2.97656V4.42188H3.41406C1.80469 4.42188 0.992188 5.24219 0.992188 6.875V17.7266ZM12.4375 9.54688L8.13281 5.16406C7.53906 4.55469 7.04688 4.42188 6.17188 4.42188H6.03906V3C6.03906 2.22656 6.45312 1.78125 7.26562 1.78125H10.7188V5.78906C10.7188 6.69531 11.1562 7.125 12.0547 7.125H15.7109V13.8047C15.7109 14.5859 15.2891 15.0234 14.4766 15.0234H13.1797V11.625C13.1797 10.625 13.0625 10.1875 12.4375 9.54688ZM11.8516 5.60156V2.39844L15.3828 5.99219H12.2344C11.9609 5.99219 11.8516 5.875 11.8516 5.60156ZM2.25 17.7031V6.89062C2.25 6.125 2.66406 5.67969 3.47656 5.67969H6.03906V10.2109C6.03906 11.1953 6.53906 11.6875 7.50781 11.6875H11.9219V17.7031C11.9219 18.4844 11.5 18.9219 10.6953 18.9219H3.46875C2.66406 18.9219 2.25 18.4844 2.25 17.7031ZM7.65625 10.5078C7.34375 10.5078 7.21875 10.3828 7.21875 10.0703V5.96875L11.6797 10.5078H7.65625Z"
      fill={props ? props.color || 'inherit' : 'inherit'}
    />
  </SvgIcon>
);

export default CopyIcon;
