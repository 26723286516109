import React from 'react';

import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Draggable from 'react-draggable';

import { useVideoCallContext } from '../contexts/videoCallContext';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: 'black',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    bottom: 0,
    right: 0,
    '&.minimized': {
      width: 450,
      height: 280,
      bottom: 15,
      right: 15,
      borderRadius: 24,
      transition: 'width .5s ease, height .5s ease',
      cursor: 'grabbing',
    },
  },
}));

const DesktopLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { root } = useStyles();
  const { minimized, minimizedPosition, setMinimizedPosition } = useVideoCallContext();
  const classes = clsx(root, { minimized });

  return (
    <Draggable
      disabled={!minimized}
      position={minimized ? minimizedPosition : { x: 0, y: 0 }}
      onStop={(e, data) => setMinimizedPosition({ x: data.x, y: data.y })}
    >
      <div className={classes}>{children}</div>
    </Draggable>
  );
};

export default DesktopLayout;
