export const isDev = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const withAuthHeaders = (headers: Record<any, any>, token: string): any => {
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : '',
    },
  };
};

export const withExtraHeaders = (headers: Record<any, any>, appVersion?: string): any => {
  const extraHeaders = {
    'amazd-client': 'web',
    'amazd-client-version': appVersion || (process.env.AMAZD_APP_VERSION as string),
  };

  return {
    ...headers,
    ...extraHeaders,
  };
};
