// defaults to localStorage for web
import { combineReducers } from 'redux';

import { Action } from '../types';
import { RootState } from '../types/root.types';
import { amazdDetailsReducer, initialState as amazdDetailsState } from './amazdDetailsReducer';
import { callRoomReducer, initialState as callRoomInitialState } from './callRoomReducer';
import { callsReducer, initialState as callsInitialState } from './callsReducer';
import { initialState as notificationsInitialState, notificationsReducer } from './notificationsReducer';

// Initial Root level application state
export const initialRootState: RootState = {
  amazdDetails: { ...amazdDetailsState },
  callRoom: { ...callRoomInitialState },
  notifications: { ...notificationsInitialState },
  calls: { ...callsInitialState },
};

const rootReducer = combineReducers({
  amazdDetails: amazdDetailsReducer,
  callRoom: callRoomReducer,
  notifications: notificationsReducer,
  calls: callsReducer,
});

const rootReducerWrapper = (state: any, action: Action) => {
  return rootReducer(state, action);
};

export default rootReducerWrapper;
