export const DEFAULT_NAMESPACE = 'general' as const;

export const VALID_NAMESPACES = [DEFAULT_NAMESPACE] as const;

export const VALID_LANGUAGES = ['en', 'de', 'nl', 'fr', 'es'] as const;

export const FORMAL_LANG_SUFFIX = '_formal';

export const ALL_LANGUAGES = [
  ...VALID_LANGUAGES,
  ...VALID_LANGUAGES.map((lang) => lang + FORMAL_LANG_SUFFIX),
] as ReadonlyArray<string>;

export const I18N_API_URL = 'https://storage.googleapis.com/amazd-public/i18n';
