export const isServer = () => typeof window === 'undefined';

export const waitUntil = (condition: () => boolean): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!condition()) {
      resolve();
    }

    const interval = setInterval(() => {
      if (!condition()) {
        clearInterval(interval);
        resolve();
      }
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      clearTimeout(timeout);
      reject('waitUntil helper timeouts.');
    }, 30000);
  });

export const iOSversion = (): boolean => {
  if (isServer()) {
    return false;
  }
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isSafari = (): boolean => {
  if (isServer()) return false;
  // Excludes Chrome and Android browsers that includes Safari name
  return (
    window.navigator.userAgent.indexOf('Safari') !== -1 &&
    window.navigator.userAgent.indexOf('Chrome') === -1 &&
    window.navigator.userAgent.indexOf('CriOS') === -1
  );
};

export const isMobile = (): boolean => {
  if (isServer()) {
    return false;
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const cleanQueryData = <T>(obj: T): T => {
  if (!obj || typeof obj !== 'object') return obj;
  for (const [key, val] of Object.entries(obj)) {
    if (val === undefined || val === null || key === '__typename') {
      if (!Array.isArray(obj)) delete (obj as any)[key];
    }
    if (val !== null && typeof val === 'object') cleanQueryData(val);
  }
  return obj;
};

export const getUniques = <T extends { id: string }>(array: T[]): T[] =>
  Object.values(
    array.reduce((obj: any, item) => {
      if (item?.id) obj[item.id] = item;
      return obj;
    }, {} as any),
  );
