import React from 'react';

import { Backdrop } from '@mui/material';
import { useModal } from 'contexts/modalContext';
import ModalLayout from 'layouts/ModalLayout';

import useStyles from './styles';

const ModalView = (): JSX.Element => {
  const classes = useStyles();
  const { context, closeModal } = useModal();

  return (
    <>
      {context.modals.map((modal) => {
        return (
          <Backdrop key={modal.modalId} className={classes.backdrop} open={true}>
            <ModalLayout
              onClose={() => {
                closeModal(modal.modalId || '');
              }}
              title={modal.title || ''}
            >
              {modal.component}
            </ModalLayout>
          </Backdrop>
        );
      })}
    </>
  );
};

export default ModalView;
