import React from 'react';

import { useTheme } from '@mui/material';

const StarIcon: React.FC<any> = (props: any) => {
  const theme = useTheme();

  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.43479 4.53503C8.01933 4.32014 7.51061 4.49921 7.30712 4.94689L6.76448 5.75271C6.6373 6.01237 6.39989 6.2004 6.12857 6.24516L5.17048 6.39737C4.98394 6.43319 4.81437 6.52273 4.68719 6.66598C4.35652 7.02413 4.365 7.5882 4.70414 7.93739L5.40788 8.66263C5.60289 8.86857 5.69616 9.16404 5.65376 9.45951L5.49267 10.4802C5.46723 10.6772 5.49267 10.8742 5.58593 11.0532C5.80638 11.492 6.32358 11.6621 6.73904 11.4383L7.60387 10.9637C7.85823 10.8205 8.14651 10.8205 8.40087 10.9637L9.2657 11.4472C9.43528 11.5457 9.62181 11.5726 9.80834 11.5457C10.2662 11.4651 10.5799 10.9995 10.5036 10.516L10.3425 9.50427C10.2916 9.21776 10.3849 8.91334 10.5884 8.71636L11.2921 8.00007C11.4278 7.85681 11.5126 7.67774 11.5465 7.48971C11.6143 6.99727 11.2921 6.54063 10.8343 6.47796V6.48691L9.87617 6.34365C9.59637 6.29889 9.35897 6.11981 9.24027 5.86016L8.81633 4.93794C8.73154 4.75887 8.59588 4.61561 8.43479 4.53503Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default StarIcon;
