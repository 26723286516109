import { makeStyles } from '@mui/styles';

import { useVideoCallContext } from '../contexts/videoCallContext';
import ParticipantScreen from './ParticipantScreen';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderRadius: '16px',
    minHeight: 'inherit',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const PresentationContainer = (): JSX.Element => {
  const { presenting, presentingParticipant } = useVideoCallContext();
  const classes = useStyles();

  if (!presenting) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <ParticipantScreen participant={presentingParticipant} />
    </div>
  );
};

export default PresentationContainer;
