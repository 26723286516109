import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const StopRecordingIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <rect width="26" height="26" rx="6" fill={props ? props.color || 'inherit' : 'inherit'} />
    </SvgIcon>
  );
};

export default StopRecordingIcon;
