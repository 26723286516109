import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AddIconOutlined: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon {...props} viewBox="-1.3324 -1.4701 15.76 15.76">
    <path
      d="M0.771973 7.27979H5.74414V12.2603C5.74414 12.6753 6.09277 13.0322 6.51611 13.0322C6.93945 13.0322 7.27979 12.6753 7.27979 12.2603V7.27979H12.2603C12.6753 7.27979 13.0239 6.93945 13.0239 6.51611C13.0239 6.09277 12.6753 5.75244 12.2603 5.75244H7.27979V0.771973C7.27979 0.356934 6.93945 0 6.51611 0C6.09277 0 5.74414 0.356934 5.74414 0.771973V5.75244H0.771973C0.356934 5.75244 0 6.09277 0 6.51611C0 6.93945 0.356934 7.27979 0.771973 7.27979Z"
      fill={props ? props.color || 'inherit' : 'inherit'}
    />
  </SvgIcon>
);

export default AddIconOutlined;
