import { GET_AVAILABLE_SLOTS, GET_AVAILABLE_SLOTS_FAIL, GET_AVAILABLE_SLOTS_SUCCESS } from '../../actions/constants';
import { Action, SlotsWithDurationsByDates } from '../../types';

export function getAvailableSlots(userTypeId: string): Action {
  return { type: GET_AVAILABLE_SLOTS, payload: { userTypeId } };
}

export function getAvailableSlotsFail(errorMessage: string): Action {
  return { type: GET_AVAILABLE_SLOTS_FAIL, payload: errorMessage };
}

export function getAvailableSlotsSuccess(slotsWithDurationsByDates: SlotsWithDurationsByDates[]): Action {
  return { type: GET_AVAILABLE_SLOTS_SUCCESS, payload: { slotsWithDurationsByDates } };
}
