import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import type { LocalParticipant, RemoteParticipant } from 'twilio-video';

import UserAvatar from '../../UserAvatar';
import { useVideoCallContext } from '../contexts/videoCallContext';
import { useParticipant } from '../hooks/useParticipant';

const useStyles = makeStyles<{ participantColor?: string }>()((theme, { participantColor }) => ({
  avatarWrapper: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'inherit',
  },
  userAvatar: {
    backgroundClip: 'padding-box',
    '&.noAvatarImage': {
      border: `10px solid ${participantColor}29`,
      boxSizing: 'content-box',
      opacity: 0.5,
    },
    '&.noAvatarImage.fullScreenMode': {
      border: `16px solid ${participantColor}33`,
    },
  },
}));

export interface PropsType {
  participant?: RemoteParticipant | LocalParticipant;
  fullScreenMode?: boolean;
}

const ParticipantAvatar = ({ participant, fullScreenMode }: PropsType) => {
  const { minimized, isWidgetScreen } = useVideoCallContext();

  const { participantUser, participantColor } = useParticipant(participant);

  const { classes } = useStyles({ participantColor });
  const userAvatarClasses = clsx(classes.userAvatar, { noAvatarImage: !participantUser?.avatar, fullScreenMode });

  if (!participantUser || !participantColor) {
    return <></>;
  }

  let size = 80;
  if (fullScreenMode) {
    size = 200;
  } else if (minimized || isWidgetScreen) {
    size = 40;
  }

  return (
    <div className={classes.avatarWrapper}>
      <UserAvatar
        className={userAvatarClasses}
        firstName={participantUser.firstName}
        lastName={participantUser.lastName}
        src={participantUser.avatar}
        size={size}
      />
    </div>
  );
};

export default ParticipantAvatar;
