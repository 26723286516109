export type JwtTokenPayload = {
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: boolean;
  firebase: {
    identities: {
      email: string[];
    };
    sign_in_provider: string;
  };
};

export class JwtHelper {
  public static isExpired(token: string): boolean {
    const decoded = this.decode(token);
    const utcEpochNow = Math.floor(new Date().getTime() / 1000);
    const expiresAt = decoded.exp;
    const timeToTravelBuffer = 60 * 3; // 3 minutes;

    return utcEpochNow >= expiresAt - timeToTravelBuffer;
  }

  public static decode(token: string): JwtTokenPayload {
    return this.parseJwt(token) as JwtTokenPayload;
  }

  private static parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
