import { IFrameInteractions } from '@amazd/common/utils';

const analyticsIdentify = (userId: string, userTraits: Record<string, string>) => {
  IFrameInteractions.postMessage('analytics-event', {
    eventType: 'identify',
    userId,
    userTraits,
  });
};

const analyticsPage = (pageName: string) => {
  IFrameInteractions.postMessage('analytics-event', {
    eventType: 'page',
    pageName,
    location: JSON.parse(JSON.stringify(window.location)),
    documentTitle: document.title,
  });
};

const analyticsTrack = (eventName: string, eventProperties?: Record<string, string>) => {
  IFrameInteractions.postMessage('analytics-event', {
    eventType: 'track',
    eventName,
    eventProperties,
  });
};

export const trackSkipToLogin = (): void => {
  analyticsTrack('skip to login');
};

export const trackSelectExpert = ({ expertId, isRecommended }: { expertId: string; isRecommended: boolean }): void => {
  analyticsTrack('expert selected', { expert_recommended: isRecommended ? 'yes' : 'no', expert_id: expertId });
};

export const trackLogin = (
  provider: 'EMAIL' | 'APPLE' | 'FACEBOOK' | 'GOOGLE',
  registration: 'yes' | 'no' = 'no',
): void => {
  analyticsTrack('login submitted', {
    login_type: provider.toLowerCase(),
    registration,
  });
};

export const trackChannelCreated = (params: {
  company_handle: string;
  channel_id: string;
  expert_id: string;
  shopper_id: string;
}): void => {
  analyticsTrack('channel created', params);
};

export const trackGoToCheckout = (params: {
  company_handle: string;
  channel_id: string;
  expert_id: string;
  shopper_id: string;
}): void => {
  analyticsTrack('checkout clicked', params);
};

export const trackCallSend = (params: { channel_id: string; expert_id: string; shopper_id: string }): void => {
  analyticsTrack('call send', params);
};

export const trackCallScheduled = (params: {
  call_id: string;
  channel_id: string;
  duration: string;
  expert_id: string;
  shopper_id: string;
  scheduled_call_time: string;
}): void => {
  analyticsTrack('call scheduled', params);
};

export const trackGreetingMessageScheduleClick = (): void => {
  analyticsTrack('click call greeting message');
};

export const trackExpertProfileClick = () => {
  analyticsTrack('clicked expert login');
};

export const trackCallCancelled = (params: {
  call_id: string;
  channel_id: string;
  duration: string;
  expert_id: string;
  shopper_id: string;
  scheduled_call_time: string;
}): void => {
  analyticsTrack('call cancelled', params);
};

export const trackWishbagShared = (params: {
  channel_id: string;
  expert_id: string;
  shopper_id: string;
  sent_by: 'shopper' | 'expert';
}): void => {
  analyticsTrack('wishbag shared', params);
};

// Widget in-app page views
export const trackChatPageView = (): void => {
  analyticsPage('chat');
};

export const trackCallsPageView = (): void => {
  analyticsPage('calls');
};

export const trackWishbagPageView = (): void => {
  analyticsPage('wishbag');
};

// Widget inflow page views
export const trackDeflectionPageView = (): void => {
  analyticsPage('deflection');
};

export const trackSchedulingPageView = (): void => {
  analyticsPage('company scheduling');
};

export const trackExpertSchedulingPageView = (): void => {
  analyticsPage('expert scheduling');
};

export const trackExpertListPageView = (): void => {
  analyticsPage('expert selection list');
};

export const trackRegisterPageView = (): void => {
  // this event tracks the fact that the login/register page is displayed and
  // that's what we're tracking on analytics as a login page view.
  analyticsPage('login');
};

export const trackSelectedExpertPageView = (): void => {
  analyticsPage('expert profile');
};

export const trackSupportTopicsPageView = (): void => {
  analyticsPage('customer support topics');
};

export const trackCallJoined = (params: { channel_id: string; expert_id: string; shopper_id: string }): void => {
  analyticsTrack('call joined', params);
};

export const trackCallEnded = (params: {
  channel_id: string;
  expert_id: string;
  shopper_id: string;
  duration: string;
}): void => {
  analyticsTrack('call completed', params);
};

export const trackShopperInstantCall = (): void => {
  analyticsTrack('shopper instant call');
};

export const trackSupportContactPageView = (): void => {
  analyticsPage('customer support contact');
};

export const trackIdentify = (id: string, params: { role: string }): void => {
  analyticsIdentify(id, params);
};
