import { useState, useEffect } from 'react';

import { LocalParticipant, LocalVideoTrack, RemoteParticipant, Room, TrackPublication } from 'twilio-video';

import { MediaSourceEnum } from '../contexts/videoCallContext';

const usePresentingParticipant = (room?: Room, remoteParticipant?: RemoteParticipant) => {
  const [presentingParticipant, setPresentingParticipant] = useState<
    RemoteParticipant | LocalParticipant | undefined
  >();

  useEffect(() => {
    if (remoteParticipant) {
      const tracks = Array.from(remoteParticipant.videoTracks.values());
      if (tracks.some((x) => x.trackName === MediaSourceEnum.SCREEN)) {
        setPresentingParticipant(remoteParticipant);
      }
    } else {
      if (presentingParticipant !== room?.localParticipant) {
        setPresentingParticipant(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteParticipant]);

  useEffect(() => {
    if (!room) return;

    room.on('trackPublished', onTrackPublished);
    room.on('trackUnpublished', onTrackUnpublished);

    return () => {
      setPresentingParticipant(undefined);
      if (room.off) {
        room.off('trackPublished', onTrackPublished);
        room.off('trackUnpublished', onTrackUnpublished);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const onTrackPublished = (trackPublication: TrackPublication, participant?: RemoteParticipant | LocalParticipant) => {
    if (trackPublication.trackName === MediaSourceEnum.SCREEN) {
      setPresentingParticipant(participant || remoteParticipant);
    }
  };

  const onTrackUnpublished = (trackPublication: TrackPublication) => {
    if (trackPublication.trackName === MediaSourceEnum.SCREEN) {
      setPresentingParticipant(undefined);
    }
  };

  const toggleScreenSharing = async () => {
    if (!room) return;
    const { localParticipant } = room;

    if (!presentingParticipant) {
      const screenStream = await (navigator.mediaDevices as any).getDisplayMedia({ video: true });
      const screenTrack = new LocalVideoTrack(screenStream.getVideoTracks()[0], {
        logLevel: 'error',
        name: MediaSourceEnum.SCREEN,
      });

      screenTrack.once('stopped', (_t) => {
        endScreenSharing(false);
      });

      localParticipant.publishTrack(screenTrack);

      setPresentingParticipant(localParticipant);
    } else {
      endScreenSharing(true);
    }
  };

  const endScreenSharing = (stop: boolean) => {
    if (!room) return;
    const { localParticipant } = room;

    localParticipant.tracks.forEach((x) => {
      if (x.track.name === MediaSourceEnum.SCREEN) {
        stop && (x.track as LocalVideoTrack).stop();

        localParticipant.unpublishTrack(x.track);
        x.unpublish();

        setPresentingParticipant(undefined);
      }
    });
  };

  return {
    presentingParticipant,
    toggleScreenSharing,
  };
};

export default usePresentingParticipant;
