import { all } from 'redux-saga/effects';

import amazdDetailsSaga from './amazdDetailsSaga';
import callRoomSagas from './callRoomSaga';
import callsSaga from './callsSaga';
import notificationsSaga from './notificationsSaga';
import realtimeEventsSaga from './realtimeEventsSaga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([amazdDetailsSaga(), callRoomSagas(), notificationsSaga(), realtimeEventsSaga(), callsSaga()]);
}
