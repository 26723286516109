import { Cloudinary } from '@cloudinary/url-gen';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';

const cl = new Cloudinary({
  cloud: {
    cloudName: 'amazd',
  },
  url: {
    secure: true, // force https, set to false to force http
  },
});

interface GetOptimizedImgUrlOptions {
  url: string;
  width: number;
  height: number;
}

/**
 * Cloudinary url transformation are costly. Too many transformation also increases the storage and
 * previously unused transformed assets are never being deleted automatically. So make sure you considered
 * this aspects before using transformation.
 * https://res.cloudinary.com/amazd/image/upload/v1638788455/widget/amazd/avatar_h3zrru.png => https://res.cloudinary.com/amazd/image/upload/c_thumb,h_300,w_300/v1638788455/widget/amazd/avatar_h3zrru.png
 * https://example.com/image.jpg => https://example.com/image.jpg
 */
export const getOptimizedImgUrl = ({ url, width, height }: GetOptimizedImgUrlOptions): string => {
  //   If doesn't exist or non cloudinary url
  if (!url || url.indexOf('cloudinary') === -1) return url;

  const imageIdentifier = url.slice(url.indexOf('upload/') + 'upload/'.length);
  const image = cl.image(imageIdentifier);
  const optimizedImg = thumbnail().width(width).height(height);
  image.resize(optimizedImg).delivery(format('auto')).delivery(quality('auto'));
  const optimizedUrl = image.toURL();

  return optimizedUrl;
};
