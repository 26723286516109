var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const initSentry = () => {
  // if you are curious why we have this here check out;
  // - https://app.shortcut.com/amazd/story/6618/production-blank-widget-on-https-unuetzer-com
  // - https://github.com/vercel/next.js/issues/7713

  Sentry.init({
    dsn: 'https://7c9100f976c042319ecc9d86bf4cf429@o769554.ingest.sentry.io/5795088',
    environment: process.env.APP_ENV,
    release: process.env.APP_VERSION,
  });
};

initSentry();
