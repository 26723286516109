import { gql } from '@apollo/client';

import { AMAZD_FIELDS, AMAZD_USER_FIELDS } from '../../graphql/fragments/amazdFields';
import { NOTIFICATION_FIELDS } from '../../graphql/fragments/notificationFields';

export const onNotification = gql`
  ${NOTIFICATION_FIELDS}
  subscription ($data: OnNotificationDto!) {
    onNotification(data: $data) {
      ...NotificationFields
    }
  }
`;

export const onUserInvite = gql`
  ${AMAZD_USER_FIELDS}
  ${AMAZD_FIELDS}
  subscription {
    onUserInvite {
      ...AmazdUserFields
      amazd {
        ...AmazdFields
      }
    }
  }
`;
