import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DiscoverIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <SvgIcon>
      <svg width="28" height="18" viewBox="0 -2 33 19" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.73633 8.54883H11.7207C13.2148 8.54883 13.9668 7.82617 13.9668 6.32227V2.26953C13.9668 0.775391 13.2148 0.0527344 11.7207 0.0527344H2.73633C1.24219 0.0527344 0.490234 0.775391 0.490234 2.26953V6.32227C0.490234 7.82617 1.24219 8.54883 2.73633 8.54883ZM19.3672 16.6641H25.7441C27.2383 16.6641 27.9902 15.9414 27.9902 14.4375V3.58789C27.9902 2.09375 27.2383 1.37109 25.7441 1.37109H19.3672C17.873 1.37109 17.1211 2.09375 17.1211 3.58789V14.4375C17.1211 15.9414 17.873 16.6641 19.3672 16.6641ZM2.85352 7.07422C2.27734 7.07422 1.96484 6.77148 1.96484 6.16602V2.43555C1.96484 1.82031 2.27734 1.52734 2.85352 1.52734H11.6035C12.1797 1.52734 12.4922 1.82031 12.4922 2.43555V6.16602C12.4922 6.77148 12.1797 7.07422 11.6035 7.07422H2.85352ZM19.4746 15.1895C18.9082 15.1895 18.5957 14.8867 18.5957 14.2812V3.75391C18.5957 3.13867 18.9082 2.8457 19.4746 2.8457H25.6172C26.2031 2.8457 26.5156 3.13867 26.5156 3.75391V14.2812C26.5156 14.8867 26.2031 15.1895 25.6172 15.1895H19.4746ZM5.22656 17.8066H12.9316C14.4258 17.8066 15.1777 17.0742 15.1777 15.5801V12.8262C15.1777 11.332 14.4258 10.6094 12.9316 10.6094H5.22656C3.73242 10.6094 2.98047 11.332 2.98047 12.8262V15.5801C2.98047 17.0742 3.73242 17.8066 5.22656 17.8066ZM5.34375 16.332C4.76758 16.332 4.45508 16.0293 4.45508 15.4238V12.9824C4.45508 12.377 4.76758 12.084 5.34375 12.084H12.8047C13.3906 12.084 13.7031 12.377 13.7031 12.9824V15.4238C13.7031 16.0293 13.3906 16.332 12.8047 16.332H5.34375Z" />
      </svg>
    </SvgIcon>
  );
};

export default DiscoverIcon;
