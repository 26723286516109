import { useRef } from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import type { RemoteParticipant, LocalParticipant } from 'twilio-video';

import { useParticipant } from '../hooks/useParticipant';
import { useAttachedTrack } from '../hooks/useParticipantTracks';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 'inherit',
    '&.localPresenting': {
      filter: 'brightness(0.5)',
    },
  },
}));

const ParticipantScreen = ({ participant }: { participant?: RemoteParticipant | LocalParticipant }) => {
  const videoRef = useRef(null);
  const { screenTrack, localPresenting } = useParticipant(participant);
  useAttachedTrack(videoRef, screenTrack);

  const classes = useStyles();
  const className = clsx(classes.root, { localPresenting });

  return <video className={className} ref={videoRef} autoPlay={true} />;
};

export default ParticipantScreen;
