import { User, UserType } from '@amazd/common/types';
import { isServer } from '@amazd/common/utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type AuthStoreType = {
  user?: User;
  errorMessage?: string | null;
  token?: string;
  refreshToken?: string;
  refreshRetryCount: number;
  updateStore: (args: Partial<AuthStoreType>) => void;
  getUserType: () => UserType | undefined;
  resetStore: () => void;
};

/**
 * We used redux and redux-persist, now after refactor to zustand persisting object is changed.
 * Migrate the store to keep users logged in.
 */
const migrateOldStore = (): Partial<AuthStoreType> | undefined => {
  if (isServer()) return;
  try {
    const oldStore = JSON.parse(localStorage.getItem('persist:auth') || '{}');
    if (oldStore?.token || oldStore?.refreshToken) {
      localStorage.removeItem('persist:auth');

      return {
        refreshRetryCount: Number(oldStore.refreshRetryCount) || 0,
        refreshToken: oldStore.refreshToken.replace(/"/g, ''),
        token: oldStore.token.replace(/"/g, ''),
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const authStore = create<AuthStoreType>()(
  persist(
    (set, get) => {
      const defaultState: AuthStoreType = {
        refreshRetryCount: 0,
        resetStore: () => set({ ...defaultState, updateStore: set }, true),
        getUserType: () => get().user?.userTypes?.[0],
        updateStore: set,
      };

      return {
        ...defaultState,
        ...migrateOldStore(),
      };
    },
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => window.localStorage),
      partialize: (state) => ({
        refreshRetryCount: state.refreshRetryCount,
        refreshToken: state.refreshToken,
        token: state.token,
      }),
    },
  ),
);

export const getAuthStoreState = () => authStore.getState();
