import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DeleteIcon: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon {...props} viewBox="-1.8691 -1.2318 21 21" {...props}>
    <path
      fill={props ? props.color || 'inherit' : 'inherit'}
      d="M16.1546 2.92308H12.1159V2.19231C12.1159 0.981971 11.1292 0 9.91304 0H6.97584C5.75966 0 4.77295 0.981971 4.77295 2.19231V2.92308H0.734299C0.328415 2.92308 0 3.24992 0 3.65385C0 4.05777 0.328426 4.38462 0.734299 4.38462H1.83575V16.8077C1.83575 18.018 2.82246 19 4.03865 19H12.8502C14.0664 19 15.0531 18.018 15.0531 16.8077V4.38462H16.1546C16.5605 4.38462 16.8889 4.05777 16.8889 3.65385C16.8889 3.24992 16.5605 2.92308 16.1546 2.92308ZM6.24155 2.19231C6.24155 1.78838 6.56997 1.46154 6.97584 1.46154H9.91304C10.1081 1.46154 10.2945 1.53861 10.4322 1.67563C10.5699 1.81265 10.6474 1.9982 10.6474 2.19232V2.92309H6.24156L6.24155 2.19231ZM13.5845 16.8077C13.5845 17.0018 13.5071 17.1874 13.3694 17.3244C13.2317 17.4614 13.0453 17.5385 12.8502 17.5385H4.03863C3.63275 17.5385 3.30433 17.2116 3.30433 16.8077V4.38463H13.5845L13.5845 16.8077Z"
    />
    <path
      fill={props ? props.color || 'inherit' : 'inherit'}
      d="M5.50723 15.7114C5.70228 15.7114 5.88874 15.6344 6.02642 15.4973C6.1641 15.3603 6.24155 15.1748 6.24155 14.9807V6.94219C6.24155 6.53826 5.91312 6.21143 5.50725 6.21143C5.10138 6.21143 4.77295 6.53827 4.77295 6.94219V14.9807C4.77295 15.1748 4.8504 15.3603 4.98808 15.4973C5.12576 15.6344 5.3122 15.7114 5.50726 15.7114H5.50723Z"
    />
    <path
      fill={props ? props.color || 'inherit' : 'inherit'}
      d="M11.3817 15.7114C11.5768 15.7114 11.7633 15.6344 11.9009 15.4973C12.0386 15.3603 12.1161 15.1748 12.1161 14.9807V6.94219C12.1161 6.53826 11.7876 6.21143 11.3818 6.21143C10.9759 6.21143 10.6475 6.53827 10.6475 6.94219V14.9807C10.6475 15.1748 10.7249 15.3603 10.8626 15.4973C11.0003 15.6344 11.1867 15.7114 11.3818 15.7114H11.3817Z"
    />
    <path
      fill={props ? props.color || 'inherit' : 'inherit'}
      d="M8.44425 15.7114C8.63929 15.7114 8.82575 15.6344 8.96343 15.4973C9.10111 15.3603 9.17856 15.1748 9.17856 14.9807V6.94219C9.17856 6.53826 8.85013 6.21143 8.44426 6.21143C8.03839 6.21143 7.70996 6.53827 7.70996 6.94219V14.9807C7.70996 15.1748 7.78741 15.3603 7.92509 15.4973C8.06277 15.6344 8.24921 15.7114 8.44428 15.7114H8.44425Z"
    />
  </SvgIcon>
);

export default DeleteIcon;
