import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CameraSwitchIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="-2.2244 -5.2149 29.57 29.57">
      <path
        d="M3.55664 18.9531H21.4082C23.4395 18.9531 24.4746 17.9375 24.4746 15.9258V5.70117C24.4746 3.68945 23.4395 2.68359 21.4082 2.68359H18.6152C17.8438 2.68359 17.6094 2.52734 17.1699 2.03906L16.3887 1.16016C15.9004 0.623047 15.4023 0.330078 14.3965 0.330078H10.5C9.49414 0.330078 8.99609 0.623047 8.50781 1.16016L7.72656 2.03906C7.28711 2.51758 7.04297 2.68359 6.28125 2.68359H3.55664C1.51562 2.68359 0.490234 3.68945 0.490234 5.70117V15.9258C0.490234 17.9375 1.51562 18.9531 3.55664 18.9531ZM3.57617 17.3809C2.61914 17.3809 2.0625 16.8633 2.0625 15.8477V5.78906C2.0625 4.77344 2.61914 4.25586 3.57617 4.25586H6.67188C7.55078 4.25586 8.0293 4.08984 8.51758 3.54297L9.2793 2.68359C9.83594 2.05859 10.1289 1.90234 10.9883 1.90234H13.9082C14.7676 1.90234 15.0605 2.05859 15.6172 2.68359L16.3789 3.54297C16.8672 4.08984 17.3457 4.25586 18.2246 4.25586H21.3789C22.3457 4.25586 22.9023 4.77344 22.9023 5.78906V15.8477C22.9023 16.8633 22.3457 17.3809 21.3789 17.3809H3.57617ZM12.4824 4.92969C11.3105 4.92969 10.1094 5.34961 9.28906 6.0332C8.84961 6.38477 8.73242 6.87305 9.06445 7.22461C9.39648 7.57617 9.82617 7.52734 10.1875 7.24414C10.8711 6.69727 11.5645 6.42383 12.4824 6.42383C14.377 6.42383 15.9492 7.70312 16.3691 9.37305H15.3047C14.8652 9.37305 14.7578 9.79297 15.002 10.125L16.5449 12.2637C16.7891 12.6152 17.2383 12.6445 17.502 12.2637L19.0156 10.125C19.2598 9.7832 19.1621 9.37305 18.7227 9.37305H17.7754C17.3164 6.74609 15.1875 4.92969 12.4824 4.92969ZM6.20312 11.0918H7.20898C7.66797 13.7188 9.80664 15.5352 12.502 15.5352C13.6836 15.5352 14.8652 15.125 15.6953 14.4414C16.1445 14.0898 16.2617 13.5918 15.9199 13.2402C15.5977 12.8887 15.168 12.9473 14.7871 13.2207C14.1035 13.7578 13.4199 14.0508 12.502 14.0508C10.6172 14.0508 9.03516 12.7715 8.625 11.0918H9.63086C10.0605 11.0918 10.1777 10.6816 9.92383 10.3398L8.39062 8.21094C8.13672 7.85938 7.69727 7.83008 7.43359 8.21094L5.91992 10.3398C5.67578 10.6816 5.77344 11.0918 6.20312 11.0918Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default CameraSwitchIcon;
