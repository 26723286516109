import { GET_MY_CALLS, GET_MY_CALLS_FAIL, GET_MY_CALLS_SUCCESS } from '../../actions/constants';
import { Action, Appointment } from '../../types';

export function getMyCalls(): Action {
  return { type: GET_MY_CALLS };
}

export function getMyCallsSuccess(calls: Appointment[]): Action {
  return { type: GET_MY_CALLS_SUCCESS, payload: calls };
}

export function getMyCallsFail(errorMessage: string): Action {
  return { type: GET_MY_CALLS_FAIL, payload: errorMessage };
}
