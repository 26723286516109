import { AppEnv, useAppConfig } from '@amazd/common/utils/env';
import 'firebase/messaging';
import firebase from 'firebase/app';

export const getFirebaseCloudMessaging = (appEnv: AppEnv) => {
  const firebaseCloudMessaging = {
    init: () => {
      if (!firebase?.apps?.length) {
        // Initialize the Firebase app with the credentials
        try {
          firebase?.initializeApp({
            apiKey: appEnv.FIREBASE_API_KEY,
            authDomain: appEnv.FIREBASE_AUTH_DOMAIN,
            projectId: appEnv.FIREBASE_PROJECT_ID,
            messagingSenderId: appEnv.FIREBASE_MESSAGING_SENDER_ID,
            appId: appEnv.FIREBASE_APP_ID,
            measurementId: appEnv.FIREBASE_MEASUREMENT_ID,
          });
        } catch (error) {
          console.error('Error on init Firebase', error);
        }
      }
    },
    getFCMToken: async () => {
      try {
        const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

        if (!messaging) return;

        const fcmToken = await messaging.getToken({
          vapidKey: appEnv.FIREBASE_WEB_PUSH_CERT_KEY,
        });

        return fcmToken;
      } catch (error) {
        console.error('Error on get FCMToken request', error);
      }
    },
    requestNotificationPermission: async () => {
      try {
        await Notification.requestPermission();
      } catch (error) {
        console.error('Error on notification permission request', error);
      }
    },
    unsubscribe: async () => {
      const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
      if (messaging) {
        try {
          await messaging.deleteToken();
        } catch (error) {
          console.error('Error on unsubscribe Firebase', error);
        }
      }
    },
  };

  return firebaseCloudMessaging;
};

export const useFirebaseCloudMessaging = () => {
  const { env } = useAppConfig();
  return getFirebaseCloudMessaging(env);
};
