import { Middleware } from 'redux';

import { reportError } from '../../utils';

export const errorReporterMiddleware: Middleware = (api) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    const state = api.getState();
    const userId = state?.user?.user?.id;
    reportError(err, { userId });
    throw err;
  }
};
