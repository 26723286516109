import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const VideoIcon: React.FC<any> = ({ color, ...rest }: any): React.ReactElement => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M25.9713 21.4063L25.4466 22.2576L25.4466 22.2576L25.9713 21.4063ZM21.4024 18.59H20.4024C20.4024 18.937 20.5822 19.2591 20.8776 19.4412L21.4024 18.59ZM21.4024 13.4099L20.8776 12.5586C20.5822 12.7407 20.4024 13.0629 20.4024 13.4099H21.4024ZM25.9713 10.5935L25.4466 9.74226L25.4466 9.74226L25.9713 10.5935ZM27.3215 10.5644L27.8089 9.69123L27.3215 10.5644ZM27.3215 21.4355L26.834 20.5623L27.3215 21.4355ZM26.496 20.555L21.9271 17.7387L20.8776 19.4412L25.4466 22.2576L26.496 20.555ZM22.4024 18.59V13.4099H20.4024V18.59H22.4024ZM21.9271 14.2611L26.496 11.4448L25.4466 9.74226L20.8776 12.5586L21.9271 14.2611ZM26.496 11.4448C26.599 11.3813 26.7283 11.3785 26.834 11.4375L27.8089 9.69123C27.0704 9.2789 26.1666 9.29843 25.4466 9.74226L26.496 11.4448ZM26.834 11.4375C26.9396 11.4965 27.0051 11.608 27.0051 11.729H29.0051C29.0051 10.8832 28.5475 10.1036 27.8089 9.69123L26.834 11.4375ZM27.0051 11.729V20.2708H29.0051V11.729H27.0051ZM27.0051 20.2708C27.0051 20.3918 26.9396 20.5033 26.834 20.5623L27.8089 22.3086C28.5475 21.8963 29.0051 21.1166 29.0051 20.2708H27.0051ZM26.834 20.5623C26.7283 20.6213 26.599 20.6185 26.496 20.555L25.4466 22.2576C26.1666 22.7014 27.0704 22.7209 27.8089 22.3086L26.834 20.5623ZM7.99512 8.99658H17.4024V6.99658H7.99512V8.99658ZM20.4024 11.9966V20.0033H22.4024V11.9966H20.4024ZM17.4024 23.0033H7.99511V25.0033H17.4024V23.0033ZM4.99512 20.0033V11.9966H2.99512V20.0033H4.99512ZM7.99511 23.0033C6.33826 23.0033 4.99512 21.6601 4.99512 20.0033H2.99512C2.99512 22.7647 5.23369 25.0033 7.99511 25.0033V23.0033ZM20.4024 20.0033C20.4024 21.6601 19.0592 23.0033 17.4024 23.0033V25.0033C20.1638 25.0033 22.4024 22.7647 22.4024 20.0033H20.4024ZM17.4024 8.99658C19.0592 8.99658 20.4024 10.3397 20.4024 11.9966H22.4024C22.4024 9.23516 20.1638 6.99658 17.4024 6.99658V8.99658ZM7.99512 6.99658C5.23369 6.99658 2.99512 9.23516 2.99512 11.9966H4.99512C4.99512 10.3397 6.33826 8.99658 7.99512 8.99658V6.99658Z"
        fill={color || 'inherit'}
      />
    </SvgIcon>
  );
};

export default VideoIcon;
