import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PlusIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C9.5578 0 9.2 0.357812 9.2 0.8V9.2H0.8C0.3578 9.2 0 9.55781 0 10C0 10.4422 0.357812 10.8 0.8 10.8H9.2V19.2C9.2 19.6422 9.55781 20 10 20C10.4422 20 10.8 19.6422 10.8 19.2V10.8H19.2C19.6422 10.8 20 10.4422 20 10C20 9.55781 19.6422 9.2 19.2 9.2H10.8V0.8C10.8 0.3578 10.4422 0 10 0Z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default PlusIcon;
