import { EventChannel } from 'redux-saga';
import { all, call, put, spawn, take, takeLatest } from 'redux-saga/effects';

import { getClient } from '../../../apollo';
import { reportError, getGraphQlError, createChannel } from '../../../utils';
import { getAuthStoreState } from '../../../utils/auth';
import {
  GET_NOTIFICATIONS,
  notificationsActions,
  SUBSCRIBE_TO_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from '../../actions';
import { createCallRoomFail } from '../../actions/callRoomActions';
import {
  getNotificationsFail,
  getNotificationsSuccess,
  updateNotificationsSuccess,
} from '../../actions/notificationsActions';
import { updateNotificationsMutation } from '../../graphql/mutations/notificationsMutation';
import { getNotificationsQuery } from '../../graphql/queries/notifcationsQueries';
import { onNotification } from '../../graphql/subscriptions/subscriptions';
import { Action, NotificationStatus } from '../../types';

function createNotificationsChannel(userTypeId: string): EventChannel<any> {
  return createChannel(
    getClient().subscribe({
      query: onNotification,
      variables: {
        data: { receiverAsUserTypeId: userTypeId },
      },
    }),
  );
}

function* subscribeToNotifications(): any {
  const currentUserType = getAuthStoreState().getUserType();
  const channel: EventChannel<any> = yield call(createNotificationsChannel, currentUserType?.id as string);

  yield put(notificationsActions.initNotificationsSubscription(channel));
  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const payload = yield take(channel);
      if (payload?.data?.onNotification) {
        yield put(notificationsActions.onNotificationsReceived(payload.data.onNotification));
      }
    } catch (err) {
      console.error('socket error:', err);
      // closing the socket channel
      yield put(notificationsActions.unsubscribeFromNotifications());
      yield spawn(reportError, err);
    }
  }
}

function* getNotifications(action: Action): any {
  try {
    const currentUserType = getAuthStoreState().getUserType();
    const { page } = action.payload;
    const res = yield getClient().query({
      query: getNotificationsQuery,
      variables: {
        data: {
          receiverAsUserTypeId: currentUserType?.id,
        },
        pagination: {
          count: 10,
          page: page || 1,
        },
        fetchPolicy: 'no-cache',
      },
      fetchPolicy: action.payload?.page === 1 ? 'no-cache' : undefined,
    });
    const { data } = res;
    if (data && data.notifications.data) {
      yield put(getNotificationsSuccess(res.data.notifications.data));
    }
  } catch (err) {
    yield put(getNotificationsFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* updateNotifications(action: Action): any {
  try {
    const { ids } = action.payload;
    const res = yield getClient().mutate({
      mutation: updateNotificationsMutation,
      variables: {
        data: {
          ids,
          status: NotificationStatus.READ,
        },
      },
    });
    if (res && res.data) {
      const { updateNotifications } = res.data;
      yield put(updateNotificationsSuccess(updateNotifications.data));
    }
  } catch (err) {
    yield put(createCallRoomFail(getGraphQlError(err).message));
    yield spawn(reportError, err);
  }
}

function* getNotificationsSaga() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
}
function* updateNotificationsSaga() {
  yield takeLatest(UPDATE_NOTIFICATIONS, updateNotifications);
}

function* subscribeToNotificationsSaga() {
  yield takeLatest(SUBSCRIBE_TO_NOTIFICATIONS, subscribeToNotifications);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([subscribeToNotificationsSaga(), getNotificationsSaga(), updateNotificationsSaga()]);
}
