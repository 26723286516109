import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const HeartIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} viewBox="7.8122 7.4797 15.81 15.81">
      <path
        d="M7.93 13.148c0 3.329 2.789 6.602 7.195 9.415.164.101.398.21.563.21.164 0 .398-.109.57-.21 4.398-2.813 7.187-6.086 7.187-9.415 0-2.765-1.898-4.718-4.43-4.718-1.445 0-2.617.687-3.328 1.742-.695-1.047-1.882-1.742-3.328-1.742-2.53 0-4.43 1.953-4.43 4.718zm1.258 0c0-2.078 1.343-3.46 3.156-3.46 1.469 0 2.312.914 2.812 1.695.211.312.344.398.531.398.188 0 .305-.094.532-.398.539-.766 1.351-1.695 2.812-1.695 1.813 0 3.157 1.382 3.157 3.46 0 2.907-3.07 6.04-6.336 8.211-.079.055-.133.094-.165.094-.03 0-.085-.039-.156-.094-3.273-2.172-6.344-5.304-6.344-8.21z"
        fill={props ? props.color || 'inherit' : 'inherit'}
      />
    </SvgIcon>
  );
};

export default HeartIcon;
