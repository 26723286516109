import React, { useContext } from 'react';

export type ModalContextValue = {
  modals: Modal[];
};

export type Modal = {
  readonly component: JSX.Element | null;
  readonly title?: string;
  readonly modalId?: string;
};

export const initialContext: ModalContextValue = {
  modals: [],
};

export const ModalContext = React.createContext([
  initialContext,
  () => {
    return {};
  },
] as [ModalContextValue, (value: ModalContextValue) => void]);

export const ModalProvider = ModalContext.Provider;

export const useModal = (): {
  context: ModalContextValue;
  openModal: (value: Modal) => string;
  closeModal: (modalId: string) => void;
} => {
  const [context, setContext] = useContext(ModalContext);

  const closeModal = (modalId: string) => {
    setContext({
      ...context,
      modals: context.modals.filter((modal) => {
        return modal.modalId !== modalId;
      }),
    });
  };

  const openModal = (modal: Modal) => {
    const modalId = Date.now() + '';
    setContext({
      ...context,
      modals: context.modals.concat({
        ...modal,
        modalId,
      }),
    });
    return modalId;
  };

  return {
    context,
    openModal,
    closeModal,
  };
};
