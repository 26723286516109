import React from 'react';

const NoArchivedAmazds: React.FC<any> = (): React.ReactElement => {
  return (
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.7248 46.6329C38.9978 50.7006 32.6748 50.9799 28.604 47.2501C24.5362 43.5232 24.2598 37.1969 27.9867 33.1293C31.7166 29.0584 38.0399 28.7851 42.1075 32.512C46.1784 36.2419 46.4546 42.562 42.7248 46.6329Z"
        fill="#7E05FE"
      />
      <path
        d="M89.5347 163.201C86.5243 161.829 85.198 158.281 86.5702 155.271C87.9405 152.263 91.4936 150.934 94.5 152.303C97.5086 153.677 98.8369 157.226 97.4665 160.235C96.0925 163.243 92.543 164.572 89.5347 163.201L89.5347 163.201Z"
        fill="#FFB13F"
      />
      <path
        d="M174.967 79.935C172.728 82.3784 168.933 82.5424 166.489 80.3037C164.046 78.0651 163.88 74.2717 166.119 71.8284C168.357 69.3851 172.153 69.2174 174.596 71.456C177.039 73.6946 177.205 77.4917 174.967 79.935Z"
        fill="#FF0066"
      />
      <path d="M91 90.665V57.3774" stroke="#D4D6E8" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91 90.665L104.005 77.6595L91 90.665Z"
        stroke="#D4D6E8"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.005 55.9838H117.011C124.194 55.9838 130.016 61.8065 130.016 68.9892V121.011C130.016 128.194 124.194 134.016 117.011 134.016H64.9892C57.8065 134.016 51.9838 128.194 51.9838 121.011V68.9892C51.9838 61.8065 57.8065 55.9838 64.9892 55.9838H77.9946"
        stroke="#D4D6E8"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9946 77.6595L91 90.665L77.9946 77.6595Z"
        stroke="#D4D6E8"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoArchivedAmazds;
