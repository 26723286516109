import { gql } from '@apollo/client';

export const getAvailabilitySlots = gql`
  query ($args: AvailableSlotsDto!) {
    availableSlots_v2(args: $args) {
      durations
      slotsWithDurationsByDates {
        date
        slotsByDurations {
          duration
          slots {
            start
            end
          }
        }
      }
    }
  }
`;
