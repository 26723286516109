import { gql } from '@apollo/client';

export const getMyCompanyCallsQuery = gql`
  query {
    companyAppointmentsV2 {
      id
      startingAt
      duration
      amazd {
        id
        expert {
          userType {
            id
            avatar
            user {
              id
              firstName
              lastName
            }
            company {
              id
              handle
            }
          }
        }
        owner {
          userType {
            id
            avatar
            user {
              id
              firstName
              lastName
            }
            company {
              id
              handle
            }
          }
        }
      }
    }
  }
`;

export const getMyCallsQuery = gql`
  query {
    myAppointmentsV2 {
      id
      startingAt
      duration
      amazd {
        id
        expert {
          userType {
            id
            avatar
            user {
              id
              firstName
              lastName
            }
            company {
              id
              handle
            }
          }
        }
        owner {
          userType {
            id
            avatar
            user {
              id
              firstName
              lastName
            }
            company {
              id
              handle
            }
          }
        }
      }
    }
  }
`;
