import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MenuIcon: React.FC<any> = (): React.ReactElement => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 24 24">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
      </svg>
    </SvgIcon>
  );
};

export default MenuIcon;
