import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on NotificationModel {
    category
    createdAt
    data
    id
    status
    event
    messages {
      content
      createdAt
      id
      locale
      notificationId
      title
      updatedAt
    }
    creatorAsUserType {
      id
      avatar
      handle
      user {
        id
        firstName
        lastName
        email
      }
      description
      company {
        id
        name
        handle
        description
        image
      }
    }
    receiverAsUserType {
      id
      avatar
      handle
      user {
        id
        firstName
        lastName
        email
      }
      description
      company {
        id
        name
        handle
        description
        image
      }
    }
  }
`;
