import React, { useState, useEffect } from 'react';

import type { LottieProps } from 'react-lottie';

export default function Lottie(props: LottieProps) {
  const [LottieComp, SetLottieComp] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const data = await import('./LottieLib');
      SetLottieComp(() => data.Lottie);
    })();
  }, []);

  if (!LottieComp) return null;
  return <LottieComp {...props} />;
}
