import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const AddIcon: React.FC<any> = (): React.ReactElement => (
  <SvgIcon viewBox="-3.9384 -3.0621 27.57 27.57">
    <path d="M9.96094 19.9219C15.4102 19.9219 19.9219 15.4004 19.9219 9.96094C19.9219 4.51172 15.4004 0 9.95117 0C4.51172 0 0 4.51172 0 9.96094C0 15.4004 4.52148 19.9219 9.96094 19.9219ZM9.96094 18.2617C5.35156 18.2617 1.66992 14.5703 1.66992 9.96094C1.66992 5.35156 5.3418 1.66016 9.95117 1.66016C14.5605 1.66016 18.252 5.35156 18.2617 9.96094C18.2715 14.5703 14.5703 18.2617 9.96094 18.2617ZM6.24023 10.7812H9.13086V13.6914C9.13086 14.1797 9.46289 14.5117 9.94141 14.5117C10.4297 14.5117 10.7715 14.1797 10.7715 13.6914V10.7812H13.6816C14.1699 10.7812 14.502 10.4492 14.502 9.9707C14.502 9.48242 14.1699 9.14062 13.6816 9.14062H10.7715V6.25C10.7715 5.74219 10.4297 5.41016 9.94141 5.41016C9.46289 5.41016 9.13086 5.75195 9.13086 6.25V9.14062H6.24023C5.73242 9.14062 5.40039 9.48242 5.40039 9.9707C5.40039 10.4492 5.74219 10.7812 6.24023 10.7812Z" />
  </SvgIcon>
);

export default AddIcon;
