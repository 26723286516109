import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const BriefcaseIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'none' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.002 21.0039H6.99788C4.78782 21.0039 2.99622 19.2123 2.99622 17.0022V8.99888C2.99622 7.89385 3.89202 6.99805 4.99705 6.99805H19.0029C20.1079 6.99805 21.0037 7.89385 21.0037 8.99888V17.0022C21.0037 19.2123 19.2121 21.0039 17.002 21.0039Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99829 6.99776V4.99693C7.99829 3.8919 8.89409 2.99609 9.99912 2.99609H14.0008C15.1058 2.99609 16.0016 3.8919 16.0016 4.99693V6.99776"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0009 12.5005H17.5023C19.4361 12.5005 21.0038 10.9328 21.0038 8.99902V8.99902"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9991 12.5005H6.49767C4.56387 12.5005 2.99622 10.9328 2.99622 8.99902"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default BriefcaseIcon;
