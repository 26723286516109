import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CallIcon: React.FC<any> = (props: any): React.ReactElement => {
  return (
    <SvgIcon viewBox="-1.6005 -2.8516 24.25 24.25" style={{ fill: 'none' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63102 11.869C7.46102 10.699 6.57902 9.38897 5.99302 8.05997C5.87002 7.77897 5.94202 7.44997 6.15902 7.23297L6.97802 6.41397C7.64902 5.74297 7.64902 4.79397 7.06302 4.20797L5.89002 3.03497C5.10902 2.25397 3.84302 2.25397 3.06202 3.03497L2.41002 3.68597C1.66902 4.42697 1.36002 5.49597 1.56002 6.55597C2.05402 9.16897 3.57202 12.03 6.02102 14.479C8.47002 16.928 11.331 18.446 13.944 18.94C15.004 19.14 16.073 18.831 16.814 18.09L17.465 17.439C18.246 16.658 18.246 15.392 17.465 14.611L16.292 13.438C15.706 12.852 14.756 12.852 14.171 13.438L13.268 14.342C13.051 14.559 12.722 14.632 12.441 14.508C11.112 13.921 9.80102 13.038 8.63102 11.869Z"
        stroke={props ? props.color || '#7E05FE' : '#7E05FE'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.619 5H15.119V3.5"
        stroke={props ? props.color || '#7E05FE' : '#7E05FE'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3284 2.17157C19.8905 3.73368 19.8905 6.26633 18.3284 7.82842C16.7663 9.39052 14.2337 9.39052 12.6716 7.82842C11.1095 6.26632 11.1095 3.73366 12.6716 2.17157C14.2337 0.609475 16.7663 0.609475 18.3284 2.17157"
        stroke={props ? props.color || '#7E05FE' : '#7E05FE'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CallIcon;
