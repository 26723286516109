import { colors } from '../static';

const COLOR_HEX_REGEX = /^([0-9a-f]{3}){1,2}$/i;

/**
 * Prepends a hex color with '#' if needed
 */
export const prependHexColor = (color: string) => {
  return COLOR_HEX_REGEX.test(color) ? `#${color}` : color;
};

export const getPersonalizedUserColor = (
  props: { firstName?: string | null; lastName?: string | null } | undefined,
) => {
  const colorsBg = new Map<number, string>([
    [0, colors.blue10],
    [1, colors.grey30],
  ]);
  const colorsInitial = new Map<number, string>([
    [0, colors.blue120],
    [1, colors.grey80],
  ]);
  const initials =
    [props?.firstName, props?.lastName].map((s) => (s && s.length > 0 ? s[0].toUpperCase() : '')).join('') || null;
  const colorBg =
    initials && initials.length > 0 ? colorsBg.get(initials.charCodeAt(0) % colorsBg.size) : colorsBg.get(0);
  const colorInitial =
    initials && initials.length > 0
      ? colorsInitial.get(initials.charCodeAt(0) % colorsInitial.size)
      : colorsInitial.get(0);
  return { colorBg, colorInitial };
};

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
