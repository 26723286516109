import { grey, green } from '@mui/material/colors';
import { createTheme as createMuiTheme, PaletteOptions, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';

import { WidgetConfig } from '../redux/types/widget-config';
import { colors } from '../static';
import { hexToRgba } from '../utils';

export const layoutHighlightingBorderColor = '1px solid #e0e0e0';
export const toolbarHeight = 54;
export const chatSideBarWidth = 375;
export const boxShadow = '0px 0px 20px 0px rgb(114, 120, 136, 0.3)';
export const listItemBorder = '0.5px solid #C9C9C9';
export const gradientBackground =
  'linear-gradient(24deg, rgba(255,238,199,1) 0%, rgba(255,252,242,1) 20%, rgba(255,255,255,1) 39%)';

export const buttonProgress: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading1: React.CSSProperties;
    heading2: React.CSSProperties;
    heading3: React.CSSProperties;
    heading4: React.CSSProperties;
    heading5: React.CSSProperties;
    heading6: React.CSSProperties;
    bodyLargeRegular: React.CSSProperties;
    bodyLargeMedium: React.CSSProperties;
    bodyDefaultRegular: React.CSSProperties;
    bodyDefaultMedium: React.CSSProperties;
    bodySmallRegular: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heading1?: React.CSSProperties;
    heading2?: React.CSSProperties;
    heading3?: React.CSSProperties;
    heading4?: React.CSSProperties;
    heading5?: React.CSSProperties;
    heading6?: React.CSSProperties;
    bodyLargeRegular?: React.CSSProperties;
    bodyLargeMedium?: React.CSSProperties;
    bodyDefaultRegular?: React.CSSProperties;
    bodyDefaultMedium?: React.CSSProperties;
    bodySmallRegular?: React.CSSProperties;
    bodySmallMedium?: React.CSSProperties;
    label?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    heading6: true;
    bodyLargeRegular: true;
    bodyLargeMedium: true;
    bodyDefaultRegular: true;
    bodyDefaultMedium: true;
    bodySmallRegular: true;
    bodySmallMedium: true;
    label: true;
  }
}

export interface ThemePreferences {
  noBorderRadius?: boolean;
  customFonts?: Font[];
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
}

export interface Font {
  src: string;
  fontFamily: string;
  fontDisplay: string;
  fontStyle: string;
  fontWeight: string | number;
}

const getFontOptions = (options: Font[]) => {
  const result: any = {};

  result['@font-face'] = [options[0]];

  result['fallbacks'] = options.map((font, index) => {
    if (index > 0) {
      return { '@font-face': font };
    }
  });

  return result;
};

export const defaultFonts = [
  {
    fontFamily: 'FilsonPro',
    src: `url('https://storage.googleapis.com/amazd-public/assets/FilsonProRegular.ttf')`,
    fontWeight: 400,
    fontDisplay: 'swap',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'FilsonPro',
    src: `url('https://storage.googleapis.com/amazd-public/assets/FilsonProMedium.ttf')`,
    fontWeight: 500,
    fontDisplay: 'swap',
    fontStyle: 'medium',
  },
  {
    fontFamily: 'FilsonPro',
    src: `url('https://storage.googleapis.com/amazd-public/assets/FilsonProBold.ttf')`,
    fontWeight: 900,
    fontDisplay: 'swap',
    fontStyle: 'bold',
  },
  {
    fontFamily: 'FilsonPro',
    src: `url('https://storage.googleapis.com/amazd-public/assets/FilsonProLight.ttf')`,
    fontWeight: 300,
    fontDisplay: 'swap',
    fontStyle: 'thin',
  },
];

const getDefaultTheme = (
  options?: ThemePreferences,
  widgetPaletteOptions?: Record<string, string>,
): Theme | CustomTheme => {
  return createMuiTheme({
    typography: {
      fontFamily: options?.customFonts?.[0].fontFamily || defaultFonts[0].fontFamily,
      allVariants: {
        textTransform: options?.textTransform ?? 'initial',
      },
      h6: {
        fontSize: 14,
        fontWeight: 500,
      },
      h5: {
        fontSize: 22,
        fontWeight: 400,
      },
      h4: {
        fontSize: 18,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
      },
      caption: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        color: colors.amazdGrey0,
      },
      subtitle1: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: 10,
        lineHeight: '14px',
        fontWeight: 400,
      },
      // design tokens
      heading1: {
        fontWeight: 700,
        fontSize: 48,
        lineHeight: '56px',
      },
      heading2: {
        fontWeight: 700,
        fontSize: 40,
        lineHeight: '48px',
      },
      heading3: {
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '40px',
      },
      heading4: {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '32px',
      },
      heading5: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '28px',
      },
      heading6: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
      },
      bodyLargeRegular: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '22px',
      },
      bodyLargeMedium: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '22px',
      },
      bodyDefaultRegular: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
      },
      bodyDefaultMedium: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
      },
      bodySmallRegular: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
      },
      bodySmallMedium: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '16px',
      },
      label: {
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '12px',
      },
    },
    palette: {
      grey: {
        900: 'black',
        500: colors.amazdGrey,
        300: colors.amazdGrey3,
      },
      primary: {
        main: widgetPaletteOptions?.primaryColor ?? colors.amazdPurple,
        light: colors.amazdPurple,
        dark: colors.amazdPurple,
      },
      /**
       * Default secondary color from MUI4. Not sure was this color intended or used accidentally
       */
      secondary: {
        main: '#f50057',
        light: '#f50057',
        dark: '#f50057',
      },
      success: {
        main: green[500],
      },
      loaderColor: widgetPaletteOptions?.primaryColor ?? colors.amazdPurple,
    } as PaletteOptions,
    components: {
      // Style sheet name
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '12px',
            lineHeight: '16px',
          },
          ...getFontOptions(options?.customFonts || defaultFonts),
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiCard: {
        // Name of the rule
        styleOverrides: {
          root: {
            borderRadius: 20,
            boxShadow,
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiCardContent: {
        styleOverrides: {
          root: {
            borderTop: `1px solid ${grey[100]}`,
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiCardActions: {
        styleOverrides: {
          root: {
            borderTop: `1px solid ${grey[100]}`,
            padding: 16,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 20,
            boxShadow,
          },
        },
      },
      ...(options?.noBorderRadius && {
        MuiGrid: {
          styleOverrides: {
            root: {
              borderRadius: '0 !important',
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              borderRadius: '0 !important',
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              borderRadius: '0 !important',
            },
          },
        },
      }),
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-outlined': {
              transform: 'translate(14px, 17px) scale(1)',
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '& .MuiFormHelperText-root': {
              marginLeft: '12px',
              marginRight: '12px',
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-root': {
              lineHeight: 1,
            },
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '14px 12px !important',
          },
          root: {
            borderRadius: options?.noBorderRadius ? '0 !important' : '8px !important',
            height: '48px',
            '&.MuiAutocomplete-inputRoot': {
              height: 'auto',
            },
          },
          multiline: {
            height: 'auto',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: options?.noBorderRadius ? '0 !important' : '8px !important',
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: colors.grey40,
          },
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.amazdPurple,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f44336',
            },
          },
        },
      },
      // Deleted theme override for MuiSelect. Use components/Select
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: options?.noBorderRadius ? 0 : 120,
            padding: 15,
            ...(options?.noBorderRadius && {
              borderRadius: '0 !important',
              borderTopRightRadius: '0 !important',
              borderBottomRightRadius: '0 !important',
              borderTopLeftRadius: '0 !important',
              borderBottomLeftRadius: '0 !important',
            }),
            '&:hover': {
              backgroundColor: hexToRgba(widgetPaletteOptions?.primaryColor ?? colors.amazdPurple, 0.75),
            },
          },
          text: {
            padding: '6px 8px',
          },
          containedPrimary: {
            boxShadow: 'none !important',
          },
          sizeSmall: {
            fontSize: 12,
            padding: '3px 9px',
          },
          containedSizeSmall: {
            padding: 4,
            paddingLeft: 10,
            paddingRight: 10,
          },
          outlined: {
            padding: 12,
            paddingLeft: 24,
            paddingRight: 24,
            fontWeight: 400,
          },
          contained: {
            paddingLeft: 80,
            paddingRight: 80,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            height: 40,
            width: 40,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: options?.textTransform ? `${options.textTransform} !important` : ('initial' as any),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'black',
            fontSize: 14,
            padding: '11px 13px',
            borderRadius: 8,
          },
          arrow: {
            color: 'black',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            boxShadow: 'none',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          spacing: {
            '& > :not(:first-child)': {
              marginLeft: '16px',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: 15,
            fontSize: 22,
            fontWeight: 500,
            lineHeight: '36px',
            '& .MuiTypography-root': {
              fontSize: 'inherit !important',
              lineHeight: 'inherit !important',
              fontWeight: 'inherit !important',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: colors.amazdGrey5,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '1.5rem',
          },
        },
      },
    },
  });
};

export interface CustomTheme extends Theme {
  readonly isFullscreen: boolean;
  readonly widgetBackgroundCover: Record<string, string | number>;
  readonly widgetChatTabsHeight: number;
  readonly widgetChatExpertHeaderHeight: number;
}

export const createTheme = (widgetConfig?: WidgetConfig): CustomTheme | Theme => {
  const defaultTheme = getDefaultTheme();

  if (!widgetConfig) return defaultTheme;

  const mainColor = widgetConfig?.companyConfig?.primaryColor || defaultTheme.palette.primary.main;

  const widgetPaletteOptions = {
    primaryColor: mainColor,
    success: green[500],
    loaderColor: mainColor,
  };

  const widgetDefaultStyles = {
    isFullscreen: widgetConfig.hostConfig.isFullscreen || false,
    widgetBackgroundCover: {
      background: `url(${widgetConfig?.companyConfig?.chatBackgroundUrl || 'static/background-pattern.png'})`,
      backgroundPosition: 'center',
      backgroundColor: '#f3f4fb',
      backgroundRepeat: 'repeat',
      backgroundSize: 800,
    },
    widgetChatTabsHeight: 46,
    widgetChatExpertHeaderHeight: 48,
  };

  return {
    ...getDefaultTheme(widgetConfig.companyConfig?.themePreferences, widgetPaletteOptions),
    ...(widgetDefaultStyles as unknown as CustomTheme),
  };
};
