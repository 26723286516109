import { CREATE_CALL_ROOM, CREATE_CALL_ROOM_FAIL, CREATE_CALL_ROOM_SUCCESS } from '../../actions';
import { Action, CallRoomState } from '../../types';

export const initialState: CallRoomState = {
  loading: false,
};

export const callRoomReducer = (state: CallRoomState = initialState, action: Action): CallRoomState => {
  switch (action.type) {
    case CREATE_CALL_ROOM:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CALL_ROOM_SUCCESS:
    case CREATE_CALL_ROOM_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
