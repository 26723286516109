import React from 'react';

import { useVideoCallContext } from '../VideoChatView/contexts/videoCallContext';
import RingingPortal from './components/RingingPortal/RingingPortal';
import RingingModal from './RingingModal';

interface Props {
  isWidget?: boolean;
}

const RingingPopup = ({ isWidget }: Props) => {
  const { isMinimized, isRingingModalVisible, handleClosePopup, handleDecline, handleAccept, activeRinging } =
    useVideoCallContext();

  if (isMinimized) {
    if (isWidget) {
      return <></>;
    }
    return <RingingPortal handleClose={handleClosePopup} call={activeRinging} handleJoinCall={handleAccept} />;
  }

  return (
    <RingingModal
      isOpen={isRingingModalVisible}
      handleClose={handleClosePopup}
      handleAccept={handleAccept}
      call={activeRinging}
      isWidget={isWidget}
      handleDecline={handleDecline}
    />
  );
};

export default RingingPopup;
